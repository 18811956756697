!(function(t, e) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = e())
    : "function" == typeof define && define.amd
    ? define([], e)
    : "object" == typeof exports
    ? (exports.DirectLine = e())
    : (t.DirectLine = e());
})(window, function() {
  return (function(t) {
    var e = {};
    function r(n) {
      if (e[n]) return e[n].exports;
      var i = (e[n] = { i: n, l: !1, exports: {} });
      return t[n].call(i.exports, i, i.exports, r), (i.l = !0), i.exports;
    }
    return (
      (r.m = t),
      (r.c = e),
      (r.d = function(t, e, n) {
        r.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: n });
      }),
      (r.r = function(t) {
        "undefined" != typeof Symbol &&
          Symbol.toStringTag &&
          Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }),
          Object.defineProperty(t, "__esModule", { value: !0 });
      }),
      (r.t = function(t, e) {
        if ((1 & e && (t = r(t)), 8 & e)) return t;
        if (4 & e && "object" == typeof t && t && t.__esModule) return t;
        var n = Object.create(null);
        if (
          (r.r(n),
          Object.defineProperty(n, "default", { enumerable: !0, value: t }),
          2 & e && "string" != typeof t)
        )
          for (var i in t)
            r.d(
              n,
              i,
              function(e) {
                return t[e];
              }.bind(null, i)
            );
        return n;
      }),
      (r.n = function(t) {
        var e =
          t && t.__esModule
            ? function() {
                return t.default;
              }
            : function() {
                return t;
              };
        return r.d(e, "a", e), e;
      }),
      (r.o = function(t, e) {
        return Object.prototype.hasOwnProperty.call(t, e);
      }),
      (r.p = ""),
      r((r.s = 71))
    );
  })([
    function(t, e, r) {
      "use strict";
      var n = r(7),
        i = r(170),
        o = r(59),
        s = r(172),
        a = (function() {
          function t(t) {
            (this._isScalar = !1), t && (this._subscribe = t);
          }
          return (
            (t.prototype.lift = function(e) {
              var r = new t();
              return (r.source = this), (r.operator = e), r;
            }),
            (t.prototype.subscribe = function(t, e, r) {
              var n = this.operator,
                o = i.toSubscriber(t, e, r);
              if (
                (n
                  ? n.call(o, this.source)
                  : o.add(
                      this.source || !o.syncErrorThrowable
                        ? this._subscribe(o)
                        : this._trySubscribe(o)
                    ),
                o.syncErrorThrowable &&
                  ((o.syncErrorThrowable = !1), o.syncErrorThrown))
              )
                throw o.syncErrorValue;
              return o;
            }),
            (t.prototype._trySubscribe = function(t) {
              try {
                return this._subscribe(t);
              } catch (e) {
                (t.syncErrorThrown = !0), (t.syncErrorValue = e), t.error(e);
              }
            }),
            (t.prototype.forEach = function(t, e) {
              var r = this;
              if (
                (e ||
                  (n.root.Rx && n.root.Rx.config && n.root.Rx.config.Promise
                    ? (e = n.root.Rx.config.Promise)
                    : n.root.Promise && (e = n.root.Promise)),
                !e)
              )
                throw new Error("no Promise impl found");
              return new e(function(e, n) {
                var i;
                i = r.subscribe(
                  function(e) {
                    if (i)
                      try {
                        t(e);
                      } catch (t) {
                        n(t), i.unsubscribe();
                      }
                    else t(e);
                  },
                  n,
                  e
                );
              });
            }),
            (t.prototype._subscribe = function(t) {
              return this.source.subscribe(t);
            }),
            (t.prototype[o.observable] = function() {
              return this;
            }),
            (t.prototype.pipe = function() {
              for (var t = [], e = 0; e < arguments.length; e++)
                t[e - 0] = arguments[e];
              return 0 === t.length ? this : s.pipeFromArray(t)(this);
            }),
            (t.prototype.toPromise = function(t) {
              var e = this;
              if (
                (t ||
                  (n.root.Rx && n.root.Rx.config && n.root.Rx.config.Promise
                    ? (t = n.root.Rx.config.Promise)
                    : n.root.Promise && (t = n.root.Promise)),
                !t)
              )
                throw new Error("no Promise impl found");
              return new t(function(t, r) {
                var n;
                e.subscribe(
                  function(t) {
                    return (n = t);
                  },
                  function(t) {
                    return r(t);
                  },
                  function() {
                    return t(n);
                  }
                );
              });
            }),
            (t.create = function(e) {
              return new t(e);
            }),
            t
          );
        })();
      e.Observable = a;
    },
    function(t, e, r) {
      (function(e) {
        var r = function(t) {
          return t && t.Math == Math && t;
        };
        t.exports =
          r("object" == typeof globalThis && globalThis) ||
          r("object" == typeof window && window) ||
          r("object" == typeof self && self) ||
          r("object" == typeof e && e) ||
          Function("return this")();
      }.call(this, r(4)));
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(100),
        o = r(26),
        s = r(102),
        a = r(58),
        c = (function(t) {
          function e(e, r, n) {
            switch (
              (t.call(this),
              (this.syncErrorValue = null),
              (this.syncErrorThrown = !1),
              (this.syncErrorThrowable = !1),
              (this.isStopped = !1),
              arguments.length)
            ) {
              case 0:
                this.destination = s.empty;
                break;
              case 1:
                if (!e) {
                  this.destination = s.empty;
                  break;
                }
                if ("object" == typeof e) {
                  if (f(e)) {
                    var i = e[a.rxSubscriber]();
                    (this.syncErrorThrowable = i.syncErrorThrowable),
                      (this.destination = i),
                      i.add(this);
                  } else
                    (this.syncErrorThrowable = !0),
                      (this.destination = new u(this, e));
                  break;
                }
              default:
                (this.syncErrorThrowable = !0),
                  (this.destination = new u(this, e, r, n));
            }
          }
          return (
            n(e, t),
            (e.prototype[a.rxSubscriber] = function() {
              return this;
            }),
            (e.create = function(t, r, n) {
              var i = new e(t, r, n);
              return (i.syncErrorThrowable = !1), i;
            }),
            (e.prototype.next = function(t) {
              this.isStopped || this._next(t);
            }),
            (e.prototype.error = function(t) {
              this.isStopped || ((this.isStopped = !0), this._error(t));
            }),
            (e.prototype.complete = function() {
              this.isStopped || ((this.isStopped = !0), this._complete());
            }),
            (e.prototype.unsubscribe = function() {
              this.closed ||
                ((this.isStopped = !0), t.prototype.unsubscribe.call(this));
            }),
            (e.prototype._next = function(t) {
              this.destination.next(t);
            }),
            (e.prototype._error = function(t) {
              this.destination.error(t), this.unsubscribe();
            }),
            (e.prototype._complete = function() {
              this.destination.complete(), this.unsubscribe();
            }),
            (e.prototype._unsubscribeAndRecycle = function() {
              var t = this._parent,
                e = this._parents;
              return (
                (this._parent = null),
                (this._parents = null),
                this.unsubscribe(),
                (this.closed = !1),
                (this.isStopped = !1),
                (this._parent = t),
                (this._parents = e),
                this
              );
            }),
            e
          );
        })(o.Subscription);
      e.Subscriber = c;
      var u = (function(t) {
        function e(e, r, n, o) {
          var a;
          t.call(this), (this._parentSubscriber = e);
          var c = this;
          i.isFunction(r)
            ? (a = r)
            : r &&
              ((a = r.next),
              (n = r.error),
              (o = r.complete),
              r !== s.empty &&
                ((c = Object.create(r)),
                i.isFunction(c.unsubscribe) && this.add(c.unsubscribe.bind(c)),
                (c.unsubscribe = this.unsubscribe.bind(this)))),
            (this._context = c),
            (this._next = a),
            (this._error = n),
            (this._complete = o);
        }
        return (
          n(e, t),
          (e.prototype.next = function(t) {
            if (!this.isStopped && this._next) {
              var e = this._parentSubscriber;
              e.syncErrorThrowable
                ? this.__tryOrSetError(e, this._next, t) && this.unsubscribe()
                : this.__tryOrUnsub(this._next, t);
            }
          }),
          (e.prototype.error = function(t) {
            if (!this.isStopped) {
              var e = this._parentSubscriber;
              if (this._error)
                e.syncErrorThrowable
                  ? (this.__tryOrSetError(e, this._error, t),
                    this.unsubscribe())
                  : (this.__tryOrUnsub(this._error, t), this.unsubscribe());
              else {
                if (!e.syncErrorThrowable) throw (this.unsubscribe(), t);
                (e.syncErrorValue = t),
                  (e.syncErrorThrown = !0),
                  this.unsubscribe();
              }
            }
          }),
          (e.prototype.complete = function() {
            var t = this;
            if (!this.isStopped) {
              var e = this._parentSubscriber;
              if (this._complete) {
                var r = function() {
                  return t._complete.call(t._context);
                };
                e.syncErrorThrowable
                  ? (this.__tryOrSetError(e, r), this.unsubscribe())
                  : (this.__tryOrUnsub(r), this.unsubscribe());
              } else this.unsubscribe();
            }
          }),
          (e.prototype.__tryOrUnsub = function(t, e) {
            try {
              t.call(this._context, e);
            } catch (t) {
              throw (this.unsubscribe(), t);
            }
          }),
          (e.prototype.__tryOrSetError = function(t, e, r) {
            try {
              e.call(this._context, r);
            } catch (e) {
              return (t.syncErrorValue = e), (t.syncErrorThrown = !0), !0;
            }
            return !1;
          }),
          (e.prototype._unsubscribe = function() {
            var t = this._parentSubscriber;
            (this._context = null),
              (this._parentSubscriber = null),
              t.unsubscribe();
          }),
          e
        );
      })(c);
      function f(t) {
        return (
          t instanceof c || ("syncErrorThrowable" in t && t[a.rxSubscriber])
        );
      }
    },
    function(t, e, r) {
      var n = r(1),
        i = r(76),
        o = r(6),
        s = r(80),
        a = r(81),
        c = r(130),
        u = i("wks"),
        f = n.Symbol,
        l = c ? f : (f && f.withoutSetter) || s;
      t.exports = function(t) {
        return (
          o(u, t) || (a && o(f, t) ? (u[t] = f[t]) : (u[t] = l("Symbol." + t))),
          u[t]
        );
      };
    },
    function(t, e) {
      var r;
      r = (function() {
        return this;
      })();
      try {
        r = r || new Function("return this")();
      } catch (t) {
        "object" == typeof window && (r = window);
      }
      t.exports = r;
    },
    function(t, e, r) {
      var n = r(12);
      t.exports = function(t) {
        if (!n(t)) throw TypeError(String(t) + " is not an object");
        return t;
      };
    },
    function(t, e) {
      var r = {}.hasOwnProperty;
      t.exports = function(t, e) {
        return r.call(t, e);
      };
    },
    function(t, e, r) {
      "use strict";
      (function(t) {
        var r = "undefined" != typeof window && window,
          n =
            "undefined" != typeof self &&
            "undefined" != typeof WorkerGlobalScope &&
            self instanceof WorkerGlobalScope &&
            self,
          i = r || (void 0 !== t && t) || n;
        (e.root = i),
          (function() {
            if (!i)
              throw new Error(
                "RxJS could not find any global context (window, self, global)"
              );
          })();
      }.call(this, r(4)));
    },
    function(t, e, r) {
      var n = r(10),
        i = r(11),
        o = r(30);
      t.exports = n
        ? function(t, e, r) {
            return i.f(t, e, o(1, r));
          }
        : function(t, e, r) {
            return (t[e] = r), t;
          };
    },
    function(t, e) {
      t.exports = function(t) {
        try {
          return !!t();
        } catch (t) {
          return !0;
        }
      };
    },
    function(t, e, r) {
      var n = r(9);
      t.exports = !n(function() {
        return (
          7 !=
          Object.defineProperty({}, 1, {
            get: function() {
              return 7;
            }
          })[1]
        );
      });
    },
    function(t, e, r) {
      var n = r(10),
        i = r(78),
        o = r(5),
        s = r(79),
        a = Object.defineProperty;
      e.f = n
        ? a
        : function(t, e, r) {
            if ((o(t), (e = s(e, !0)), o(r), i))
              try {
                return a(t, e, r);
              } catch (t) {}
            if ("get" in r || "set" in r)
              throw TypeError("Accessors not supported");
            return "value" in r && (t[e] = r.value), t;
          };
    },
    function(t, e) {
      t.exports = function(t) {
        return "object" == typeof t ? null !== t : "function" == typeof t;
      };
    },
    function(t, e, r) {
      var n = r(1),
        i = r(52).f,
        o = r(8),
        s = r(17),
        a = r(45),
        c = r(137),
        u = r(87);
      t.exports = function(t, e) {
        var r,
          f,
          l,
          h,
          p,
          d = t.target,
          y = t.global,
          v = t.stat;
        if ((r = y ? n : v ? n[d] || a(d, {}) : (n[d] || {}).prototype))
          for (f in e) {
            if (
              ((h = e[f]),
              (l = t.noTargetGet ? (p = i(r, f)) && p.value : r[f]),
              !u(y ? f : d + (v ? "." : "#") + f, t.forced) && void 0 !== l)
            ) {
              if (typeof h == typeof l) continue;
              c(h, l);
            }
            (t.sham || (l && l.sham)) && o(h, "sham", !0), s(r, f, h, t);
          }
      };
    },
    function(t, e, r) {
      var n = r(84),
        i = r(1),
        o = function(t) {
          return "function" == typeof t ? t : void 0;
        };
      t.exports = function(t, e) {
        return arguments.length < 2
          ? o(n[t]) || o(i[t])
          : (n[t] && n[t][e]) || (i[t] && i[t][e]);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(42),
        i =
          Object.keys ||
          function(t) {
            var e = [];
            for (var r in t) e.push(r);
            return e;
          };
      t.exports = l;
      var o = r(28);
      o.inherits = r(20);
      var s = r(115),
        a = r(65);
      o.inherits(l, s);
      for (var c = i(a.prototype), u = 0; u < c.length; u++) {
        var f = c[u];
        l.prototype[f] || (l.prototype[f] = a.prototype[f]);
      }
      function l(t) {
        if (!(this instanceof l)) return new l(t);
        s.call(this, t),
          a.call(this, t),
          t && !1 === t.readable && (this.readable = !1),
          t && !1 === t.writable && (this.writable = !1),
          (this.allowHalfOpen = !0),
          t && !1 === t.allowHalfOpen && (this.allowHalfOpen = !1),
          this.once("end", h);
      }
      function h() {
        this.allowHalfOpen || this._writableState.ended || n.nextTick(p, this);
      }
      function p(t) {
        t.end();
      }
      Object.defineProperty(l.prototype, "writableHighWaterMark", {
        enumerable: !1,
        get: function() {
          return this._writableState.highWaterMark;
        }
      }),
        Object.defineProperty(l.prototype, "destroyed", {
          get: function() {
            return (
              void 0 !== this._readableState &&
              void 0 !== this._writableState &&
              this._readableState.destroyed && this._writableState.destroyed
            );
          },
          set: function(t) {
            void 0 !== this._readableState &&
              void 0 !== this._writableState &&
              ((this._readableState.destroyed = t),
              (this._writableState.destroyed = t));
          }
        }),
        (l.prototype._destroy = function(t, e) {
          this.push(null), this.end(), n.nextTick(e, t);
        });
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 }),
        (function(t) {
          (t.request = "A"),
            (t.response = "B"),
            (t.stream = "S"),
            (t.cancelAll = "X"),
            (t.cancelStream = "C");
        })(e.PayloadTypes || (e.PayloadTypes = {}));
    },
    function(t, e, r) {
      var n = r(1),
        i = r(8),
        o = r(6),
        s = r(45),
        a = r(47),
        c = r(22),
        u = c.get,
        f = c.enforce,
        l = String(String).split("String");
      (t.exports = function(t, e, r, a) {
        var c = !!a && !!a.unsafe,
          u = !!a && !!a.enumerable,
          h = !!a && !!a.noTargetGet;
        "function" == typeof r &&
          ("string" != typeof e || o(r, "name") || i(r, "name", e),
          (f(r).source = l.join("string" == typeof e ? e : ""))),
          t !== n
            ? (c ? !h && t[e] && (u = !0) : delete t[e],
              u ? (t[e] = r) : i(t, e, r))
            : u
            ? (t[e] = r)
            : s(e, r);
      })(Function.prototype, "toString", function() {
        return ("function" == typeof this && u(this).source) || a(this);
      });
    },
    function(t, e) {
      t.exports = function(t) {
        if ("function" != typeof t)
          throw TypeError(String(t) + " is not a function");
        return t;
      };
    },
    function(t, e, r) {
      "use strict";
      (function(t) {
        /*!
         * The buffer module from node.js, for the browser.
         *
         * @author   Feross Aboukhadijeh <http://feross.org>
         * @license  MIT
         */
        var n = r(246),
          i = r(247),
          o = r(112);
        function s() {
          return c.TYPED_ARRAY_SUPPORT ? 2147483647 : 1073741823;
        }
        function a(t, e) {
          if (s() < e) throw new RangeError("Invalid typed array length");
          return (
            c.TYPED_ARRAY_SUPPORT
              ? ((t = new Uint8Array(e)).__proto__ = c.prototype)
              : (null === t && (t = new c(e)), (t.length = e)),
            t
          );
        }
        function c(t, e, r) {
          if (!(c.TYPED_ARRAY_SUPPORT || this instanceof c))
            return new c(t, e, r);
          if ("number" == typeof t) {
            if ("string" == typeof e)
              throw new Error(
                "If encoding is specified then the first argument must be a string"
              );
            return l(this, t);
          }
          return u(this, t, e, r);
        }
        function u(t, e, r, n) {
          if ("number" == typeof e)
            throw new TypeError('"value" argument must not be a number');
          return "undefined" != typeof ArrayBuffer && e instanceof ArrayBuffer
            ? (function(t, e, r, n) {
                if ((e.byteLength, r < 0 || e.byteLength < r))
                  throw new RangeError("'offset' is out of bounds");
                if (e.byteLength < r + (n || 0))
                  throw new RangeError("'length' is out of bounds");
                e =
                  void 0 === r && void 0 === n
                    ? new Uint8Array(e)
                    : void 0 === n
                    ? new Uint8Array(e, r)
                    : new Uint8Array(e, r, n);
                c.TYPED_ARRAY_SUPPORT
                  ? ((t = e).__proto__ = c.prototype)
                  : (t = h(t, e));
                return t;
              })(t, e, r, n)
            : "string" == typeof e
            ? (function(t, e, r) {
                ("string" == typeof r && "" !== r) || (r = "utf8");
                if (!c.isEncoding(r))
                  throw new TypeError(
                    '"encoding" must be a valid string encoding'
                  );
                var n = 0 | d(e, r),
                  i = (t = a(t, n)).write(e, r);
                i !== n && (t = t.slice(0, i));
                return t;
              })(t, e, r)
            : (function(t, e) {
                if (c.isBuffer(e)) {
                  var r = 0 | p(e.length);
                  return 0 === (t = a(t, r)).length
                    ? t
                    : (e.copy(t, 0, 0, r), t);
                }
                if (e) {
                  if (
                    ("undefined" != typeof ArrayBuffer &&
                      e.buffer instanceof ArrayBuffer) ||
                    "length" in e
                  )
                    return "number" != typeof e.length || (n = e.length) != n
                      ? a(t, 0)
                      : h(t, e);
                  if ("Buffer" === e.type && o(e.data)) return h(t, e.data);
                }
                var n;
                throw new TypeError(
                  "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object."
                );
              })(t, e);
        }
        function f(t) {
          if ("number" != typeof t)
            throw new TypeError('"size" argument must be a number');
          if (t < 0)
            throw new RangeError('"size" argument must not be negative');
        }
        function l(t, e) {
          if ((f(e), (t = a(t, e < 0 ? 0 : 0 | p(e))), !c.TYPED_ARRAY_SUPPORT))
            for (var r = 0; r < e; ++r) t[r] = 0;
          return t;
        }
        function h(t, e) {
          var r = e.length < 0 ? 0 : 0 | p(e.length);
          t = a(t, r);
          for (var n = 0; n < r; n += 1) t[n] = 255 & e[n];
          return t;
        }
        function p(t) {
          if (t >= s())
            throw new RangeError(
              "Attempt to allocate Buffer larger than maximum size: 0x" +
                s().toString(16) +
                " bytes"
            );
          return 0 | t;
        }
        function d(t, e) {
          if (c.isBuffer(t)) return t.length;
          if (
            "undefined" != typeof ArrayBuffer &&
            "function" == typeof ArrayBuffer.isView &&
            (ArrayBuffer.isView(t) || t instanceof ArrayBuffer)
          )
            return t.byteLength;
          "string" != typeof t && (t = "" + t);
          var r = t.length;
          if (0 === r) return 0;
          for (var n = !1; ; )
            switch (e) {
              case "ascii":
              case "latin1":
              case "binary":
                return r;
              case "utf8":
              case "utf-8":
              case void 0:
                return q(t).length;
              case "ucs2":
              case "ucs-2":
              case "utf16le":
              case "utf-16le":
                return 2 * r;
              case "hex":
                return r >>> 1;
              case "base64":
                return F(t).length;
              default:
                if (n) return q(t).length;
                (e = ("" + e).toLowerCase()), (n = !0);
            }
        }
        function y(t, e, r) {
          var n = !1;
          if (((void 0 === e || e < 0) && (e = 0), e > this.length)) return "";
          if (((void 0 === r || r > this.length) && (r = this.length), r <= 0))
            return "";
          if ((r >>>= 0) <= (e >>>= 0)) return "";
          for (t || (t = "utf8"); ; )
            switch (t) {
              case "hex":
                return j(this, e, r);
              case "utf8":
              case "utf-8":
                return k(this, e, r);
              case "ascii":
                return P(this, e, r);
              case "latin1":
              case "binary":
                return T(this, e, r);
              case "base64":
                return E(this, e, r);
              case "ucs2":
              case "ucs-2":
              case "utf16le":
              case "utf-16le":
                return A(this, e, r);
              default:
                if (n) throw new TypeError("Unknown encoding: " + t);
                (t = (t + "").toLowerCase()), (n = !0);
            }
        }
        function v(t, e, r) {
          var n = t[e];
          (t[e] = t[r]), (t[r] = n);
        }
        function b(t, e, r, n, i) {
          if (0 === t.length) return -1;
          if (
            ("string" == typeof r
              ? ((n = r), (r = 0))
              : r > 2147483647
              ? (r = 2147483647)
              : r < -2147483648 && (r = -2147483648),
            (r = +r),
            isNaN(r) && (r = i ? 0 : t.length - 1),
            r < 0 && (r = t.length + r),
            r >= t.length)
          ) {
            if (i) return -1;
            r = t.length - 1;
          } else if (r < 0) {
            if (!i) return -1;
            r = 0;
          }
          if (("string" == typeof e && (e = c.from(e, n)), c.isBuffer(e)))
            return 0 === e.length ? -1 : g(t, e, r, n, i);
          if ("number" == typeof e)
            return (
              (e &= 255),
              c.TYPED_ARRAY_SUPPORT &&
              "function" == typeof Uint8Array.prototype.indexOf
                ? i
                  ? Uint8Array.prototype.indexOf.call(t, e, r)
                  : Uint8Array.prototype.lastIndexOf.call(t, e, r)
                : g(t, [e], r, n, i)
            );
          throw new TypeError("val must be string, number or Buffer");
        }
        function g(t, e, r, n, i) {
          var o,
            s = 1,
            a = t.length,
            c = e.length;
          if (
            void 0 !== n &&
            ("ucs2" === (n = String(n).toLowerCase()) ||
              "ucs-2" === n ||
              "utf16le" === n ||
              "utf-16le" === n)
          ) {
            if (t.length < 2 || e.length < 2) return -1;
            (s = 2), (a /= 2), (c /= 2), (r /= 2);
          }
          function u(t, e) {
            return 1 === s ? t[e] : t.readUInt16BE(e * s);
          }
          if (i) {
            var f = -1;
            for (o = r; o < a; o++)
              if (u(t, o) === u(e, -1 === f ? 0 : o - f)) {
                if ((-1 === f && (f = o), o - f + 1 === c)) return f * s;
              } else -1 !== f && (o -= o - f), (f = -1);
          } else
            for (r + c > a && (r = a - c), o = r; o >= 0; o--) {
              for (var l = !0, h = 0; h < c; h++)
                if (u(t, o + h) !== u(e, h)) {
                  l = !1;
                  break;
                }
              if (l) return o;
            }
          return -1;
        }
        function m(t, e, r, n) {
          r = Number(r) || 0;
          var i = t.length - r;
          n ? (n = Number(n)) > i && (n = i) : (n = i);
          var o = e.length;
          if (o % 2 != 0) throw new TypeError("Invalid hex string");
          n > o / 2 && (n = o / 2);
          for (var s = 0; s < n; ++s) {
            var a = parseInt(e.substr(2 * s, 2), 16);
            if (isNaN(a)) return s;
            t[r + s] = a;
          }
          return s;
        }
        function w(t, e, r, n) {
          return H(q(e, t.length - r), t, r, n);
        }
        function _(t, e, r, n) {
          return H(
            (function(t) {
              for (var e = [], r = 0; r < t.length; ++r)
                e.push(255 & t.charCodeAt(r));
              return e;
            })(e),
            t,
            r,
            n
          );
        }
        function S(t, e, r, n) {
          return _(t, e, r, n);
        }
        function x(t, e, r, n) {
          return H(F(e), t, r, n);
        }
        function O(t, e, r, n) {
          return H(
            (function(t, e) {
              for (
                var r, n, i, o = [], s = 0;
                s < t.length && !((e -= 2) < 0);
                ++s
              )
                (r = t.charCodeAt(s)),
                  (n = r >> 8),
                  (i = r % 256),
                  o.push(i),
                  o.push(n);
              return o;
            })(e, t.length - r),
            t,
            r,
            n
          );
        }
        function E(t, e, r) {
          return 0 === e && r === t.length
            ? n.fromByteArray(t)
            : n.fromByteArray(t.slice(e, r));
        }
        function k(t, e, r) {
          r = Math.min(t.length, r);
          for (var n = [], i = e; i < r; ) {
            var o,
              s,
              a,
              c,
              u = t[i],
              f = null,
              l = u > 239 ? 4 : u > 223 ? 3 : u > 191 ? 2 : 1;
            if (i + l <= r)
              switch (l) {
                case 1:
                  u < 128 && (f = u);
                  break;
                case 2:
                  128 == (192 & (o = t[i + 1])) &&
                    (c = ((31 & u) << 6) | (63 & o)) > 127 &&
                    (f = c);
                  break;
                case 3:
                  (o = t[i + 1]),
                    (s = t[i + 2]),
                    128 == (192 & o) &&
                      128 == (192 & s) &&
                      (c = ((15 & u) << 12) | ((63 & o) << 6) | (63 & s)) >
                        2047 &&
                      (c < 55296 || c > 57343) &&
                      (f = c);
                  break;
                case 4:
                  (o = t[i + 1]),
                    (s = t[i + 2]),
                    (a = t[i + 3]),
                    128 == (192 & o) &&
                      128 == (192 & s) &&
                      128 == (192 & a) &&
                      (c =
                        ((15 & u) << 18) |
                        ((63 & o) << 12) |
                        ((63 & s) << 6) |
                        (63 & a)) > 65535 &&
                      c < 1114112 &&
                      (f = c);
              }
            null === f
              ? ((f = 65533), (l = 1))
              : f > 65535 &&
                ((f -= 65536),
                n.push(((f >>> 10) & 1023) | 55296),
                (f = 56320 | (1023 & f))),
              n.push(f),
              (i += l);
          }
          return (function(t) {
            var e = t.length;
            if (e <= 4096) return String.fromCharCode.apply(String, t);
            var r = "",
              n = 0;
            for (; n < e; )
              r += String.fromCharCode.apply(String, t.slice(n, (n += 4096)));
            return r;
          })(n);
        }
        (e.Buffer = c),
          (e.SlowBuffer = function(t) {
            +t != t && (t = 0);
            return c.alloc(+t);
          }),
          (e.INSPECT_MAX_BYTES = 50),
          (c.TYPED_ARRAY_SUPPORT =
            void 0 !== t.TYPED_ARRAY_SUPPORT
              ? t.TYPED_ARRAY_SUPPORT
              : (function() {
                  try {
                    var t = new Uint8Array(1);
                    return (
                      (t.__proto__ = {
                        __proto__: Uint8Array.prototype,
                        foo: function() {
                          return 42;
                        }
                      }),
                      42 === t.foo() &&
                        "function" == typeof t.subarray &&
                        0 === t.subarray(1, 1).byteLength
                    );
                  } catch (t) {
                    return !1;
                  }
                })()),
          (e.kMaxLength = s()),
          (c.poolSize = 8192),
          (c._augment = function(t) {
            return (t.__proto__ = c.prototype), t;
          }),
          (c.from = function(t, e, r) {
            return u(null, t, e, r);
          }),
          c.TYPED_ARRAY_SUPPORT &&
            ((c.prototype.__proto__ = Uint8Array.prototype),
            (c.__proto__ = Uint8Array),
            "undefined" != typeof Symbol &&
              Symbol.species &&
              c[Symbol.species] === c &&
              Object.defineProperty(c, Symbol.species, {
                value: null,
                configurable: !0
              })),
          (c.alloc = function(t, e, r) {
            return (function(t, e, r, n) {
              return (
                f(e),
                e <= 0
                  ? a(t, e)
                  : void 0 !== r
                  ? "string" == typeof n
                    ? a(t, e).fill(r, n)
                    : a(t, e).fill(r)
                  : a(t, e)
              );
            })(null, t, e, r);
          }),
          (c.allocUnsafe = function(t) {
            return l(null, t);
          }),
          (c.allocUnsafeSlow = function(t) {
            return l(null, t);
          }),
          (c.isBuffer = function(t) {
            return !(null == t || !t._isBuffer);
          }),
          (c.compare = function(t, e) {
            if (!c.isBuffer(t) || !c.isBuffer(e))
              throw new TypeError("Arguments must be Buffers");
            if (t === e) return 0;
            for (
              var r = t.length, n = e.length, i = 0, o = Math.min(r, n);
              i < o;
              ++i
            )
              if (t[i] !== e[i]) {
                (r = t[i]), (n = e[i]);
                break;
              }
            return r < n ? -1 : n < r ? 1 : 0;
          }),
          (c.isEncoding = function(t) {
            switch (String(t).toLowerCase()) {
              case "hex":
              case "utf8":
              case "utf-8":
              case "ascii":
              case "latin1":
              case "binary":
              case "base64":
              case "ucs2":
              case "ucs-2":
              case "utf16le":
              case "utf-16le":
                return !0;
              default:
                return !1;
            }
          }),
          (c.concat = function(t, e) {
            if (!o(t))
              throw new TypeError(
                '"list" argument must be an Array of Buffers'
              );
            if (0 === t.length) return c.alloc(0);
            var r;
            if (void 0 === e)
              for (e = 0, r = 0; r < t.length; ++r) e += t[r].length;
            var n = c.allocUnsafe(e),
              i = 0;
            for (r = 0; r < t.length; ++r) {
              var s = t[r];
              if (!c.isBuffer(s))
                throw new TypeError(
                  '"list" argument must be an Array of Buffers'
                );
              s.copy(n, i), (i += s.length);
            }
            return n;
          }),
          (c.byteLength = d),
          (c.prototype._isBuffer = !0),
          (c.prototype.swap16 = function() {
            var t = this.length;
            if (t % 2 != 0)
              throw new RangeError("Buffer size must be a multiple of 16-bits");
            for (var e = 0; e < t; e += 2) v(this, e, e + 1);
            return this;
          }),
          (c.prototype.swap32 = function() {
            var t = this.length;
            if (t % 4 != 0)
              throw new RangeError("Buffer size must be a multiple of 32-bits");
            for (var e = 0; e < t; e += 4)
              v(this, e, e + 3), v(this, e + 1, e + 2);
            return this;
          }),
          (c.prototype.swap64 = function() {
            var t = this.length;
            if (t % 8 != 0)
              throw new RangeError("Buffer size must be a multiple of 64-bits");
            for (var e = 0; e < t; e += 8)
              v(this, e, e + 7),
                v(this, e + 1, e + 6),
                v(this, e + 2, e + 5),
                v(this, e + 3, e + 4);
            return this;
          }),
          (c.prototype.toString = function() {
            var t = 0 | this.length;
            return 0 === t
              ? ""
              : 0 === arguments.length
              ? k(this, 0, t)
              : y.apply(this, arguments);
          }),
          (c.prototype.equals = function(t) {
            if (!c.isBuffer(t))
              throw new TypeError("Argument must be a Buffer");
            return this === t || 0 === c.compare(this, t);
          }),
          (c.prototype.inspect = function() {
            var t = "",
              r = e.INSPECT_MAX_BYTES;
            return (
              this.length > 0 &&
                ((t = this.toString("hex", 0, r)
                  .match(/.{2}/g)
                  .join(" ")),
                this.length > r && (t += " ... ")),
              "<Buffer " + t + ">"
            );
          }),
          (c.prototype.compare = function(t, e, r, n, i) {
            if (!c.isBuffer(t))
              throw new TypeError("Argument must be a Buffer");
            if (
              (void 0 === e && (e = 0),
              void 0 === r && (r = t ? t.length : 0),
              void 0 === n && (n = 0),
              void 0 === i && (i = this.length),
              e < 0 || r > t.length || n < 0 || i > this.length)
            )
              throw new RangeError("out of range index");
            if (n >= i && e >= r) return 0;
            if (n >= i) return -1;
            if (e >= r) return 1;
            if (this === t) return 0;
            for (
              var o = (i >>>= 0) - (n >>>= 0),
                s = (r >>>= 0) - (e >>>= 0),
                a = Math.min(o, s),
                u = this.slice(n, i),
                f = t.slice(e, r),
                l = 0;
              l < a;
              ++l
            )
              if (u[l] !== f[l]) {
                (o = u[l]), (s = f[l]);
                break;
              }
            return o < s ? -1 : s < o ? 1 : 0;
          }),
          (c.prototype.includes = function(t, e, r) {
            return -1 !== this.indexOf(t, e, r);
          }),
          (c.prototype.indexOf = function(t, e, r) {
            return b(this, t, e, r, !0);
          }),
          (c.prototype.lastIndexOf = function(t, e, r) {
            return b(this, t, e, r, !1);
          }),
          (c.prototype.write = function(t, e, r, n) {
            if (void 0 === e) (n = "utf8"), (r = this.length), (e = 0);
            else if (void 0 === r && "string" == typeof e)
              (n = e), (r = this.length), (e = 0);
            else {
              if (!isFinite(e))
                throw new Error(
                  "Buffer.write(string, encoding, offset[, length]) is no longer supported"
                );
              (e |= 0),
                isFinite(r)
                  ? ((r |= 0), void 0 === n && (n = "utf8"))
                  : ((n = r), (r = void 0));
            }
            var i = this.length - e;
            if (
              ((void 0 === r || r > i) && (r = i),
              (t.length > 0 && (r < 0 || e < 0)) || e > this.length)
            )
              throw new RangeError("Attempt to write outside buffer bounds");
            n || (n = "utf8");
            for (var o = !1; ; )
              switch (n) {
                case "hex":
                  return m(this, t, e, r);
                case "utf8":
                case "utf-8":
                  return w(this, t, e, r);
                case "ascii":
                  return _(this, t, e, r);
                case "latin1":
                case "binary":
                  return S(this, t, e, r);
                case "base64":
                  return x(this, t, e, r);
                case "ucs2":
                case "ucs-2":
                case "utf16le":
                case "utf-16le":
                  return O(this, t, e, r);
                default:
                  if (o) throw new TypeError("Unknown encoding: " + n);
                  (n = ("" + n).toLowerCase()), (o = !0);
              }
          }),
          (c.prototype.toJSON = function() {
            return {
              type: "Buffer",
              data: Array.prototype.slice.call(this._arr || this, 0)
            };
          });
        function P(t, e, r) {
          var n = "";
          r = Math.min(t.length, r);
          for (var i = e; i < r; ++i) n += String.fromCharCode(127 & t[i]);
          return n;
        }
        function T(t, e, r) {
          var n = "";
          r = Math.min(t.length, r);
          for (var i = e; i < r; ++i) n += String.fromCharCode(t[i]);
          return n;
        }
        function j(t, e, r) {
          var n = t.length;
          (!e || e < 0) && (e = 0), (!r || r < 0 || r > n) && (r = n);
          for (var i = "", o = e; o < r; ++o) i += N(t[o]);
          return i;
        }
        function A(t, e, r) {
          for (var n = t.slice(e, r), i = "", o = 0; o < n.length; o += 2)
            i += String.fromCharCode(n[o] + 256 * n[o + 1]);
          return i;
        }
        function R(t, e, r) {
          if (t % 1 != 0 || t < 0) throw new RangeError("offset is not uint");
          if (t + e > r)
            throw new RangeError("Trying to access beyond buffer length");
        }
        function C(t, e, r, n, i, o) {
          if (!c.isBuffer(t))
            throw new TypeError('"buffer" argument must be a Buffer instance');
          if (e > i || e < o)
            throw new RangeError('"value" argument is out of bounds');
          if (r + n > t.length) throw new RangeError("Index out of range");
        }
        function M(t, e, r, n) {
          e < 0 && (e = 65535 + e + 1);
          for (var i = 0, o = Math.min(t.length - r, 2); i < o; ++i)
            t[r + i] =
              (e & (255 << (8 * (n ? i : 1 - i)))) >>> (8 * (n ? i : 1 - i));
        }
        function L(t, e, r, n) {
          e < 0 && (e = 4294967295 + e + 1);
          for (var i = 0, o = Math.min(t.length - r, 4); i < o; ++i)
            t[r + i] = (e >>> (8 * (n ? i : 3 - i))) & 255;
        }
        function I(t, e, r, n, i, o) {
          if (r + n > t.length) throw new RangeError("Index out of range");
          if (r < 0) throw new RangeError("Index out of range");
        }
        function D(t, e, r, n, o) {
          return o || I(t, 0, r, 4), i.write(t, e, r, n, 23, 4), r + 4;
        }
        function U(t, e, r, n, o) {
          return o || I(t, 0, r, 8), i.write(t, e, r, n, 52, 8), r + 8;
        }
        (c.prototype.slice = function(t, e) {
          var r,
            n = this.length;
          if (
            ((t = ~~t) < 0 ? (t += n) < 0 && (t = 0) : t > n && (t = n),
            (e = void 0 === e ? n : ~~e) < 0
              ? (e += n) < 0 && (e = 0)
              : e > n && (e = n),
            e < t && (e = t),
            c.TYPED_ARRAY_SUPPORT)
          )
            (r = this.subarray(t, e)).__proto__ = c.prototype;
          else {
            var i = e - t;
            r = new c(i, void 0);
            for (var o = 0; o < i; ++o) r[o] = this[o + t];
          }
          return r;
        }),
          (c.prototype.readUIntLE = function(t, e, r) {
            (t |= 0), (e |= 0), r || R(t, e, this.length);
            for (var n = this[t], i = 1, o = 0; ++o < e && (i *= 256); )
              n += this[t + o] * i;
            return n;
          }),
          (c.prototype.readUIntBE = function(t, e, r) {
            (t |= 0), (e |= 0), r || R(t, e, this.length);
            for (var n = this[t + --e], i = 1; e > 0 && (i *= 256); )
              n += this[t + --e] * i;
            return n;
          }),
          (c.prototype.readUInt8 = function(t, e) {
            return e || R(t, 1, this.length), this[t];
          }),
          (c.prototype.readUInt16LE = function(t, e) {
            return e || R(t, 2, this.length), this[t] | (this[t + 1] << 8);
          }),
          (c.prototype.readUInt16BE = function(t, e) {
            return e || R(t, 2, this.length), (this[t] << 8) | this[t + 1];
          }),
          (c.prototype.readUInt32LE = function(t, e) {
            return (
              e || R(t, 4, this.length),
              (this[t] | (this[t + 1] << 8) | (this[t + 2] << 16)) +
                16777216 * this[t + 3]
            );
          }),
          (c.prototype.readUInt32BE = function(t, e) {
            return (
              e || R(t, 4, this.length),
              16777216 * this[t] +
                ((this[t + 1] << 16) | (this[t + 2] << 8) | this[t + 3])
            );
          }),
          (c.prototype.readIntLE = function(t, e, r) {
            (t |= 0), (e |= 0), r || R(t, e, this.length);
            for (var n = this[t], i = 1, o = 0; ++o < e && (i *= 256); )
              n += this[t + o] * i;
            return n >= (i *= 128) && (n -= Math.pow(2, 8 * e)), n;
          }),
          (c.prototype.readIntBE = function(t, e, r) {
            (t |= 0), (e |= 0), r || R(t, e, this.length);
            for (var n = e, i = 1, o = this[t + --n]; n > 0 && (i *= 256); )
              o += this[t + --n] * i;
            return o >= (i *= 128) && (o -= Math.pow(2, 8 * e)), o;
          }),
          (c.prototype.readInt8 = function(t, e) {
            return (
              e || R(t, 1, this.length),
              128 & this[t] ? -1 * (255 - this[t] + 1) : this[t]
            );
          }),
          (c.prototype.readInt16LE = function(t, e) {
            e || R(t, 2, this.length);
            var r = this[t] | (this[t + 1] << 8);
            return 32768 & r ? 4294901760 | r : r;
          }),
          (c.prototype.readInt16BE = function(t, e) {
            e || R(t, 2, this.length);
            var r = this[t + 1] | (this[t] << 8);
            return 32768 & r ? 4294901760 | r : r;
          }),
          (c.prototype.readInt32LE = function(t, e) {
            return (
              e || R(t, 4, this.length),
              this[t] |
                (this[t + 1] << 8) |
                (this[t + 2] << 16) |
                (this[t + 3] << 24)
            );
          }),
          (c.prototype.readInt32BE = function(t, e) {
            return (
              e || R(t, 4, this.length),
              (this[t] << 24) |
                (this[t + 1] << 16) |
                (this[t + 2] << 8) |
                this[t + 3]
            );
          }),
          (c.prototype.readFloatLE = function(t, e) {
            return e || R(t, 4, this.length), i.read(this, t, !0, 23, 4);
          }),
          (c.prototype.readFloatBE = function(t, e) {
            return e || R(t, 4, this.length), i.read(this, t, !1, 23, 4);
          }),
          (c.prototype.readDoubleLE = function(t, e) {
            return e || R(t, 8, this.length), i.read(this, t, !0, 52, 8);
          }),
          (c.prototype.readDoubleBE = function(t, e) {
            return e || R(t, 8, this.length), i.read(this, t, !1, 52, 8);
          }),
          (c.prototype.writeUIntLE = function(t, e, r, n) {
            ((t = +t), (e |= 0), (r |= 0), n) ||
              C(this, t, e, r, Math.pow(2, 8 * r) - 1, 0);
            var i = 1,
              o = 0;
            for (this[e] = 255 & t; ++o < r && (i *= 256); )
              this[e + o] = (t / i) & 255;
            return e + r;
          }),
          (c.prototype.writeUIntBE = function(t, e, r, n) {
            ((t = +t), (e |= 0), (r |= 0), n) ||
              C(this, t, e, r, Math.pow(2, 8 * r) - 1, 0);
            var i = r - 1,
              o = 1;
            for (this[e + i] = 255 & t; --i >= 0 && (o *= 256); )
              this[e + i] = (t / o) & 255;
            return e + r;
          }),
          (c.prototype.writeUInt8 = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 1, 255, 0),
              c.TYPED_ARRAY_SUPPORT || (t = Math.floor(t)),
              (this[e] = 255 & t),
              e + 1
            );
          }),
          (c.prototype.writeUInt16LE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 2, 65535, 0),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = 255 & t), (this[e + 1] = t >>> 8))
                : M(this, t, e, !0),
              e + 2
            );
          }),
          (c.prototype.writeUInt16BE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 2, 65535, 0),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = t >>> 8), (this[e + 1] = 255 & t))
                : M(this, t, e, !1),
              e + 2
            );
          }),
          (c.prototype.writeUInt32LE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 4, 4294967295, 0),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e + 3] = t >>> 24),
                  (this[e + 2] = t >>> 16),
                  (this[e + 1] = t >>> 8),
                  (this[e] = 255 & t))
                : L(this, t, e, !0),
              e + 4
            );
          }),
          (c.prototype.writeUInt32BE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 4, 4294967295, 0),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = t >>> 24),
                  (this[e + 1] = t >>> 16),
                  (this[e + 2] = t >>> 8),
                  (this[e + 3] = 255 & t))
                : L(this, t, e, !1),
              e + 4
            );
          }),
          (c.prototype.writeIntLE = function(t, e, r, n) {
            if (((t = +t), (e |= 0), !n)) {
              var i = Math.pow(2, 8 * r - 1);
              C(this, t, e, r, i - 1, -i);
            }
            var o = 0,
              s = 1,
              a = 0;
            for (this[e] = 255 & t; ++o < r && (s *= 256); )
              t < 0 && 0 === a && 0 !== this[e + o - 1] && (a = 1),
                (this[e + o] = (((t / s) >> 0) - a) & 255);
            return e + r;
          }),
          (c.prototype.writeIntBE = function(t, e, r, n) {
            if (((t = +t), (e |= 0), !n)) {
              var i = Math.pow(2, 8 * r - 1);
              C(this, t, e, r, i - 1, -i);
            }
            var o = r - 1,
              s = 1,
              a = 0;
            for (this[e + o] = 255 & t; --o >= 0 && (s *= 256); )
              t < 0 && 0 === a && 0 !== this[e + o + 1] && (a = 1),
                (this[e + o] = (((t / s) >> 0) - a) & 255);
            return e + r;
          }),
          (c.prototype.writeInt8 = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 1, 127, -128),
              c.TYPED_ARRAY_SUPPORT || (t = Math.floor(t)),
              t < 0 && (t = 255 + t + 1),
              (this[e] = 255 & t),
              e + 1
            );
          }),
          (c.prototype.writeInt16LE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 2, 32767, -32768),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = 255 & t), (this[e + 1] = t >>> 8))
                : M(this, t, e, !0),
              e + 2
            );
          }),
          (c.prototype.writeInt16BE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 2, 32767, -32768),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = t >>> 8), (this[e + 1] = 255 & t))
                : M(this, t, e, !1),
              e + 2
            );
          }),
          (c.prototype.writeInt32LE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 4, 2147483647, -2147483648),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = 255 & t),
                  (this[e + 1] = t >>> 8),
                  (this[e + 2] = t >>> 16),
                  (this[e + 3] = t >>> 24))
                : L(this, t, e, !0),
              e + 4
            );
          }),
          (c.prototype.writeInt32BE = function(t, e, r) {
            return (
              (t = +t),
              (e |= 0),
              r || C(this, t, e, 4, 2147483647, -2147483648),
              t < 0 && (t = 4294967295 + t + 1),
              c.TYPED_ARRAY_SUPPORT
                ? ((this[e] = t >>> 24),
                  (this[e + 1] = t >>> 16),
                  (this[e + 2] = t >>> 8),
                  (this[e + 3] = 255 & t))
                : L(this, t, e, !1),
              e + 4
            );
          }),
          (c.prototype.writeFloatLE = function(t, e, r) {
            return D(this, t, e, !0, r);
          }),
          (c.prototype.writeFloatBE = function(t, e, r) {
            return D(this, t, e, !1, r);
          }),
          (c.prototype.writeDoubleLE = function(t, e, r) {
            return U(this, t, e, !0, r);
          }),
          (c.prototype.writeDoubleBE = function(t, e, r) {
            return U(this, t, e, !1, r);
          }),
          (c.prototype.copy = function(t, e, r, n) {
            if (
              (r || (r = 0),
              n || 0 === n || (n = this.length),
              e >= t.length && (e = t.length),
              e || (e = 0),
              n > 0 && n < r && (n = r),
              n === r)
            )
              return 0;
            if (0 === t.length || 0 === this.length) return 0;
            if (e < 0) throw new RangeError("targetStart out of bounds");
            if (r < 0 || r >= this.length)
              throw new RangeError("sourceStart out of bounds");
            if (n < 0) throw new RangeError("sourceEnd out of bounds");
            n > this.length && (n = this.length),
              t.length - e < n - r && (n = t.length - e + r);
            var i,
              o = n - r;
            if (this === t && r < e && e < n)
              for (i = o - 1; i >= 0; --i) t[i + e] = this[i + r];
            else if (o < 1e3 || !c.TYPED_ARRAY_SUPPORT)
              for (i = 0; i < o; ++i) t[i + e] = this[i + r];
            else Uint8Array.prototype.set.call(t, this.subarray(r, r + o), e);
            return o;
          }),
          (c.prototype.fill = function(t, e, r, n) {
            if ("string" == typeof t) {
              if (
                ("string" == typeof e
                  ? ((n = e), (e = 0), (r = this.length))
                  : "string" == typeof r && ((n = r), (r = this.length)),
                1 === t.length)
              ) {
                var i = t.charCodeAt(0);
                i < 256 && (t = i);
              }
              if (void 0 !== n && "string" != typeof n)
                throw new TypeError("encoding must be a string");
              if ("string" == typeof n && !c.isEncoding(n))
                throw new TypeError("Unknown encoding: " + n);
            } else "number" == typeof t && (t &= 255);
            if (e < 0 || this.length < e || this.length < r)
              throw new RangeError("Out of range index");
            if (r <= e) return this;
            var o;
            if (
              ((e >>>= 0),
              (r = void 0 === r ? this.length : r >>> 0),
              t || (t = 0),
              "number" == typeof t)
            )
              for (o = e; o < r; ++o) this[o] = t;
            else {
              var s = c.isBuffer(t) ? t : q(new c(t, n).toString()),
                a = s.length;
              for (o = 0; o < r - e; ++o) this[o + e] = s[o % a];
            }
            return this;
          });
        var B = /[^+\/0-9A-Za-z-_]/g;
        function N(t) {
          return t < 16 ? "0" + t.toString(16) : t.toString(16);
        }
        function q(t, e) {
          var r;
          e = e || 1 / 0;
          for (var n = t.length, i = null, o = [], s = 0; s < n; ++s) {
            if ((r = t.charCodeAt(s)) > 55295 && r < 57344) {
              if (!i) {
                if (r > 56319) {
                  (e -= 3) > -1 && o.push(239, 191, 189);
                  continue;
                }
                if (s + 1 === n) {
                  (e -= 3) > -1 && o.push(239, 191, 189);
                  continue;
                }
                i = r;
                continue;
              }
              if (r < 56320) {
                (e -= 3) > -1 && o.push(239, 191, 189), (i = r);
                continue;
              }
              r = 65536 + (((i - 55296) << 10) | (r - 56320));
            } else i && (e -= 3) > -1 && o.push(239, 191, 189);
            if (((i = null), r < 128)) {
              if ((e -= 1) < 0) break;
              o.push(r);
            } else if (r < 2048) {
              if ((e -= 2) < 0) break;
              o.push((r >> 6) | 192, (63 & r) | 128);
            } else if (r < 65536) {
              if ((e -= 3) < 0) break;
              o.push((r >> 12) | 224, ((r >> 6) & 63) | 128, (63 & r) | 128);
            } else {
              if (!(r < 1114112)) throw new Error("Invalid code point");
              if ((e -= 4) < 0) break;
              o.push(
                (r >> 18) | 240,
                ((r >> 12) & 63) | 128,
                ((r >> 6) & 63) | 128,
                (63 & r) | 128
              );
            }
          }
          return o;
        }
        function F(t) {
          return n.toByteArray(
            (function(t) {
              if (
                (t = (function(t) {
                  return t.trim ? t.trim() : t.replace(/^\s+|\s+$/g, "");
                })(t).replace(B, "")).length < 2
              )
                return "";
              for (; t.length % 4 != 0; ) t += "=";
              return t;
            })(t)
          );
        }
        function H(t, e, r, n) {
          for (var i = 0; i < n && !(i + r >= e.length || i >= t.length); ++i)
            e[i + r] = t[i];
          return i;
        }
      }.call(this, r(4)));
    },
    function(t, e) {
      "function" == typeof Object.create
        ? (t.exports = function(t, e) {
            (t.super_ = e),
              (t.prototype = Object.create(e.prototype, {
                constructor: {
                  value: t,
                  enumerable: !1,
                  writable: !0,
                  configurable: !0
                }
              }));
          })
        : (t.exports = function(t, e) {
            t.super_ = e;
            var r = function() {};
            (r.prototype = e.prototype),
              (t.prototype = new r()),
              (t.prototype.constructor = t);
          });
    },
    function(t, e) {
      t.exports = !1;
    },
    function(t, e, r) {
      var n,
        i,
        o,
        s = r(131),
        a = r(1),
        c = r(12),
        u = r(8),
        f = r(6),
        l = r(48),
        h = r(49),
        p = a.WeakMap;
      if (s) {
        var d = new p(),
          y = d.get,
          v = d.has,
          b = d.set;
        (n = function(t, e) {
          return b.call(d, t, e), e;
        }),
          (i = function(t) {
            return y.call(d, t) || {};
          }),
          (o = function(t) {
            return v.call(d, t);
          });
      } else {
        var g = l("state");
        (h[g] = !0),
          (n = function(t, e) {
            return u(t, g, e), e;
          }),
          (i = function(t) {
            return f(t, g) ? t[g] : {};
          }),
          (o = function(t) {
            return f(t, g);
          });
      }
      t.exports = {
        set: n,
        get: i,
        has: o,
        enforce: function(t) {
          return o(t) ? i(t) : n(t, {});
        },
        getterFor: function(t) {
          return function(e) {
            var r;
            if (!c(e) || (r = i(e)).type !== t)
              throw TypeError("Incompatible receiver, " + t + " required");
            return r;
          };
        }
      };
    },
    function(t, e) {
      var r = {}.toString;
      t.exports = function(t) {
        return r.call(t).slice(8, -1);
      };
    },
    function(t, e) {
      t.exports = {};
    },
    function(t, e, r) {
      "use strict";
      var n = r(18),
        i = function(t) {
          var e, r;
          (this.promise = new t(function(t, n) {
            if (void 0 !== e || void 0 !== r)
              throw TypeError("Bad Promise constructor");
            (e = t), (r = n);
          })),
            (this.resolve = n(e)),
            (this.reject = n(r));
        };
      t.exports.f = function(t) {
        return new i(t);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(35),
        i = r(101),
        o = r(100),
        s = r(57),
        a = r(36),
        c = r(171),
        u = (function() {
          function t(t) {
            (this.closed = !1),
              (this._parent = null),
              (this._parents = null),
              (this._subscriptions = null),
              t && (this._unsubscribe = t);
          }
          var e;
          return (
            (t.prototype.unsubscribe = function() {
              var t,
                e = !1;
              if (!this.closed) {
                var r = this._parent,
                  u = this._parents,
                  l = this._unsubscribe,
                  h = this._subscriptions;
                (this.closed = !0),
                  (this._parent = null),
                  (this._parents = null),
                  (this._subscriptions = null);
                for (var p = -1, d = u ? u.length : 0; r; )
                  r.remove(this), (r = (++p < d && u[p]) || null);
                if (o.isFunction(l))
                  s.tryCatch(l).call(this) === a.errorObject &&
                    ((e = !0),
                    (t =
                      t ||
                      (a.errorObject.e instanceof c.UnsubscriptionError
                        ? f(a.errorObject.e.errors)
                        : [a.errorObject.e])));
                if (n.isArray(h))
                  for (p = -1, d = h.length; ++p < d; ) {
                    var y = h[p];
                    if (i.isObject(y))
                      if (s.tryCatch(y.unsubscribe).call(y) === a.errorObject) {
                        (e = !0), (t = t || []);
                        var v = a.errorObject.e;
                        v instanceof c.UnsubscriptionError
                          ? (t = t.concat(f(v.errors)))
                          : t.push(v);
                      }
                  }
                if (e) throw new c.UnsubscriptionError(t);
              }
            }),
            (t.prototype.add = function(e) {
              if (!e || e === t.EMPTY) return t.EMPTY;
              if (e === this) return this;
              var r = e;
              switch (typeof e) {
                case "function":
                  r = new t(e);
                case "object":
                  if (r.closed || "function" != typeof r.unsubscribe) return r;
                  if (this.closed) return r.unsubscribe(), r;
                  if ("function" != typeof r._addParent) {
                    var n = r;
                    (r = new t())._subscriptions = [n];
                  }
                  break;
                default:
                  throw new Error(
                    "unrecognized teardown " + e + " added to Subscription."
                  );
              }
              return (
                (this._subscriptions || (this._subscriptions = [])).push(r),
                r._addParent(this),
                r
              );
            }),
            (t.prototype.remove = function(t) {
              var e = this._subscriptions;
              if (e) {
                var r = e.indexOf(t);
                -1 !== r && e.splice(r, 1);
              }
            }),
            (t.prototype._addParent = function(t) {
              var e = this._parent,
                r = this._parents;
              e && e !== t
                ? r
                  ? -1 === r.indexOf(t) && r.push(t)
                  : (this._parents = [t])
                : (this._parent = t);
            }),
            (t.EMPTY = (((e = new t()).closed = !0), e)),
            t
          );
        })();
      function f(t) {
        return t.reduce(function(t, e) {
          return t.concat(e instanceof c.UnsubscriptionError ? e.errors : e);
        }, []);
      }
      e.Subscription = u;
    },
    function(t, e, r) {
      "use strict";
      (function(t) {
        var n,
          i =
            (this && this.__extends) ||
            ((n = function(t, e) {
              return (n =
                Object.setPrototypeOf ||
                ({ __proto__: [] } instanceof Array &&
                  function(t, e) {
                    t.__proto__ = e;
                  }) ||
                function(t, e) {
                  for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
                })(t, e);
            }),
            function(t, e) {
              function r() {
                this.constructor = t;
              }
              n(t, e),
                (t.prototype =
                  null === e
                    ? Object.create(e)
                    : ((r.prototype = e.prototype), new r()));
            });
        Object.defineProperty(e, "__esModule", { value: !0 });
        var o = (function(e) {
          function r(t) {
            var r = e.call(this, t) || this;
            return (r.length = 0), (r.bufferList = []), r;
          }
          return (
            i(r, e),
            (r.prototype._write = function(e, r, n) {
              var i = t.from(e);
              this.bufferList.push(i),
                (this.length += e.length),
                this._onData && this._onData(i),
                n();
            }),
            (r.prototype._read = function(t) {
              if (0 === this.bufferList.length) this.push(null);
              else
                for (var e = 0; e < t && this.bufferList.length > 0; ) {
                  var r = this.bufferList[0];
                  this.push(r), this.bufferList.splice(0, 1), (e += r.length);
                }
            }),
            (r.prototype.subscribe = function(t) {
              this._onData = t;
            }),
            r
          );
        })(r(261).Duplex);
        e.SubscribableStream = o;
      }.call(this, r(19).Buffer));
    },
    function(t, e, r) {
      (function(t) {
        function r(t) {
          return Object.prototype.toString.call(t);
        }
        (e.isArray = function(t) {
          return Array.isArray ? Array.isArray(t) : "[object Array]" === r(t);
        }),
          (e.isBoolean = function(t) {
            return "boolean" == typeof t;
          }),
          (e.isNull = function(t) {
            return null === t;
          }),
          (e.isNullOrUndefined = function(t) {
            return null == t;
          }),
          (e.isNumber = function(t) {
            return "number" == typeof t;
          }),
          (e.isString = function(t) {
            return "string" == typeof t;
          }),
          (e.isSymbol = function(t) {
            return "symbol" == typeof t;
          }),
          (e.isUndefined = function(t) {
            return void 0 === t;
          }),
          (e.isRegExp = function(t) {
            return "[object RegExp]" === r(t);
          }),
          (e.isObject = function(t) {
            return "object" == typeof t && null !== t;
          }),
          (e.isDate = function(t) {
            return "[object Date]" === r(t);
          }),
          (e.isError = function(t) {
            return "[object Error]" === r(t) || t instanceof Error;
          }),
          (e.isFunction = function(t) {
            return "function" == typeof t;
          }),
          (e.isPrimitive = function(t) {
            return (
              null === t ||
              "boolean" == typeof t ||
              "number" == typeof t ||
              "string" == typeof t ||
              "symbol" == typeof t ||
              void 0 === t
            );
          }),
          (e.isBuffer = t.isBuffer);
      }.call(this, r(19).Buffer));
    },
    function(t, e) {
      t.exports = function(t) {
        return t && t.__esModule ? t : { default: t };
      };
    },
    function(t, e) {
      t.exports = function(t, e) {
        return {
          enumerable: !(1 & t),
          configurable: !(2 & t),
          writable: !(4 & t),
          value: e
        };
      };
    },
    function(t, e, r) {
      var n = r(136),
        i = r(51);
      t.exports = function(t) {
        return n(i(t));
      };
    },
    function(t, e, r) {
      var n = r(5),
        i = r(157),
        o = r(86),
        s = r(92),
        a = r(158),
        c = r(159),
        u = function(t, e) {
          (this.stopped = t), (this.result = e);
        };
      (t.exports = function(t, e, r, f, l) {
        var h,
          p,
          d,
          y,
          v,
          b,
          g,
          m = s(e, r, f ? 2 : 1);
        if (l) h = t;
        else {
          if ("function" != typeof (p = a(t)))
            throw TypeError("Target is not iterable");
          if (i(p)) {
            for (d = 0, y = o(t.length); y > d; d++)
              if (
                (v = f ? m(n((g = t[d]))[0], g[1]) : m(t[d])) &&
                v instanceof u
              )
                return v;
            return new u(!1);
          }
          h = p.call(t);
        }
        for (b = h.next; !(g = b.call(h)).done; )
          if (
            "object" == typeof (v = c(h, m, g.value, f)) &&
            v &&
            v instanceof u
          )
            return v;
        return new u(!1);
      }).stop = function(t) {
        return new u(!0, t);
      };
    },
    function(t, e) {
      t.exports = function(t) {
        try {
          return { error: !1, value: t() };
        } catch (t) {
          return { error: !0, value: t };
        }
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(0),
        o = r(2),
        s = r(26),
        a = r(103),
        c = r(174),
        u = r(58),
        f = (function(t) {
          function e(e) {
            t.call(this, e), (this.destination = e);
          }
          return n(e, t), e;
        })(o.Subscriber);
      e.SubjectSubscriber = f;
      var l = (function(t) {
        function e() {
          t.call(this),
            (this.observers = []),
            (this.closed = !1),
            (this.isStopped = !1),
            (this.hasError = !1),
            (this.thrownError = null);
        }
        return (
          n(e, t),
          (e.prototype[u.rxSubscriber] = function() {
            return new f(this);
          }),
          (e.prototype.lift = function(t) {
            var e = new h(this, this);
            return (e.operator = t), e;
          }),
          (e.prototype.next = function(t) {
            if (this.closed) throw new a.ObjectUnsubscribedError();
            if (!this.isStopped)
              for (
                var e = this.observers, r = e.length, n = e.slice(), i = 0;
                i < r;
                i++
              )
                n[i].next(t);
          }),
          (e.prototype.error = function(t) {
            if (this.closed) throw new a.ObjectUnsubscribedError();
            (this.hasError = !0), (this.thrownError = t), (this.isStopped = !0);
            for (
              var e = this.observers, r = e.length, n = e.slice(), i = 0;
              i < r;
              i++
            )
              n[i].error(t);
            this.observers.length = 0;
          }),
          (e.prototype.complete = function() {
            if (this.closed) throw new a.ObjectUnsubscribedError();
            this.isStopped = !0;
            for (
              var t = this.observers, e = t.length, r = t.slice(), n = 0;
              n < e;
              n++
            )
              r[n].complete();
            this.observers.length = 0;
          }),
          (e.prototype.unsubscribe = function() {
            (this.isStopped = !0), (this.closed = !0), (this.observers = null);
          }),
          (e.prototype._trySubscribe = function(e) {
            if (this.closed) throw new a.ObjectUnsubscribedError();
            return t.prototype._trySubscribe.call(this, e);
          }),
          (e.prototype._subscribe = function(t) {
            if (this.closed) throw new a.ObjectUnsubscribedError();
            return this.hasError
              ? (t.error(this.thrownError), s.Subscription.EMPTY)
              : this.isStopped
              ? (t.complete(), s.Subscription.EMPTY)
              : (this.observers.push(t), new c.SubjectSubscription(this, t));
          }),
          (e.prototype.asObservable = function() {
            var t = new i.Observable();
            return (t.source = this), t;
          }),
          (e.create = function(t, e) {
            return new h(t, e);
          }),
          e
        );
      })(i.Observable);
      e.Subject = l;
      var h = (function(t) {
        function e(e, r) {
          t.call(this), (this.destination = e), (this.source = r);
        }
        return (
          n(e, t),
          (e.prototype.next = function(t) {
            var e = this.destination;
            e && e.next && e.next(t);
          }),
          (e.prototype.error = function(t) {
            var e = this.destination;
            e && e.error && this.destination.error(t);
          }),
          (e.prototype.complete = function() {
            var t = this.destination;
            t && t.complete && this.destination.complete();
          }),
          (e.prototype._subscribe = function(t) {
            return this.source
              ? this.source.subscribe(t)
              : s.Subscription.EMPTY;
          }),
          e
        );
      })(l);
      e.AnonymousSubject = h;
    },
    function(t, e, r) {
      "use strict";
      e.isArray =
        Array.isArray ||
        function(t) {
          return t && "number" == typeof t.length;
        };
    },
    function(t, e, r) {
      "use strict";
      e.errorObject = { e: {} };
    },
    function(t, e, r) {
      "use strict";
      var n = r(175),
        i = r(177);
      e.async = new i.AsyncScheduler(n.AsyncAction);
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e() {
            t.apply(this, arguments);
          }
          return (
            n(e, t),
            (e.prototype.notifyNext = function(t, e, r, n, i) {
              this.destination.next(e);
            }),
            (e.prototype.notifyError = function(t, e) {
              this.destination.error(t);
            }),
            (e.prototype.notifyComplete = function(t) {
              this.destination.complete();
            }),
            e
          );
        })(r(2).Subscriber);
      e.OuterSubscriber = i;
    },
    function(t, e, r) {
      "use strict";
      var n = r(7),
        i = r(104),
        o = r(105),
        s = r(101),
        a = r(0),
        c = r(60),
        u = r(182),
        f = r(59);
      e.subscribeToResult = function(t, e, r, l) {
        var h = new u.InnerSubscriber(t, r, l);
        if (h.closed) return null;
        if (e instanceof a.Observable)
          return e._isScalar
            ? (h.next(e.value), h.complete(), null)
            : ((h.syncErrorThrowable = !0), e.subscribe(h));
        if (i.isArrayLike(e)) {
          for (var p = 0, d = e.length; p < d && !h.closed; p++) h.next(e[p]);
          h.closed || h.complete();
        } else {
          if (o.isPromise(e))
            return (
              e
                .then(
                  function(t) {
                    h.closed || (h.next(t), h.complete());
                  },
                  function(t) {
                    return h.error(t);
                  }
                )
                .then(null, function(t) {
                  n.root.setTimeout(function() {
                    throw t;
                  });
                }),
              h
            );
          if (e && "function" == typeof e[c.iterator])
            for (var y = e[c.iterator](); ; ) {
              var v = y.next();
              if (v.done) {
                h.complete();
                break;
              }
              if ((h.next(v.value), h.closed)) break;
            }
          else if (e && "function" == typeof e[f.observable]) {
            var b = e[f.observable]();
            if ("function" == typeof b.subscribe)
              return b.subscribe(new u.InnerSubscriber(t, r, l));
            h.error(
              new TypeError(
                "Provided object does not correctly implement Symbol.observable"
              )
            );
          } else {
            var g =
              "You provided " +
              (s.isObject(e) ? "an invalid object" : "'" + e + "'") +
              " where a stream was expected. You can provide an Observable, Promise, Array, or Iterable.";
            h.error(new TypeError(g));
          }
        }
        return null;
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e) {
            t.call(this), (this.scheduler = e);
          }
          return (
            n(e, t),
            (e.create = function(t) {
              return new e(t);
            }),
            (e.dispatch = function(t) {
              t.subscriber.complete();
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.scheduler;
              if (r) return r.schedule(e.dispatch, 0, { subscriber: t });
              t.complete();
            }),
            e
          );
        })(r(0).Observable);
      e.EmptyObservable = i;
    },
    function(t, e) {
      var r,
        n,
        i = (t.exports = {});
      function o() {
        throw new Error("setTimeout has not been defined");
      }
      function s() {
        throw new Error("clearTimeout has not been defined");
      }
      function a(t) {
        if (r === setTimeout) return setTimeout(t, 0);
        if ((r === o || !r) && setTimeout)
          return (r = setTimeout), setTimeout(t, 0);
        try {
          return r(t, 0);
        } catch (e) {
          try {
            return r.call(null, t, 0);
          } catch (e) {
            return r.call(this, t, 0);
          }
        }
      }
      !(function() {
        try {
          r = "function" == typeof setTimeout ? setTimeout : o;
        } catch (t) {
          r = o;
        }
        try {
          n = "function" == typeof clearTimeout ? clearTimeout : s;
        } catch (t) {
          n = s;
        }
      })();
      var c,
        u = [],
        f = !1,
        l = -1;
      function h() {
        f &&
          c &&
          ((f = !1), c.length ? (u = c.concat(u)) : (l = -1), u.length && p());
      }
      function p() {
        if (!f) {
          var t = a(h);
          f = !0;
          for (var e = u.length; e; ) {
            for (c = u, u = []; ++l < e; ) c && c[l].run();
            (l = -1), (e = u.length);
          }
          (c = null),
            (f = !1),
            (function(t) {
              if (n === clearTimeout) return clearTimeout(t);
              if ((n === s || !n) && clearTimeout)
                return (n = clearTimeout), clearTimeout(t);
              try {
                n(t);
              } catch (e) {
                try {
                  return n.call(null, t);
                } catch (e) {
                  return n.call(this, t);
                }
              }
            })(t);
        }
      }
      function d(t, e) {
        (this.fun = t), (this.array = e);
      }
      function y() {}
      (i.nextTick = function(t) {
        var e = new Array(arguments.length - 1);
        if (arguments.length > 1)
          for (var r = 1; r < arguments.length; r++) e[r - 1] = arguments[r];
        u.push(new d(t, e)), 1 !== u.length || f || a(p);
      }),
        (d.prototype.run = function() {
          this.fun.apply(null, this.array);
        }),
        (i.title = "browser"),
        (i.browser = !0),
        (i.env = {}),
        (i.argv = []),
        (i.version = ""),
        (i.versions = {}),
        (i.on = y),
        (i.addListener = y),
        (i.once = y),
        (i.off = y),
        (i.removeListener = y),
        (i.removeAllListeners = y),
        (i.emit = y),
        (i.prependListener = y),
        (i.prependOnceListener = y),
        (i.listeners = function(t) {
          return [];
        }),
        (i.binding = function(t) {
          throw new Error("process.binding is not supported");
        }),
        (i.cwd = function() {
          return "/";
        }),
        (i.chdir = function(t) {
          throw new Error("process.chdir is not supported");
        }),
        (i.umask = function() {
          return 0;
        });
    },
    function(t, e, r) {
      "use strict";
      (function(e) {
        !e.version ||
        0 === e.version.indexOf("v0.") ||
        (0 === e.version.indexOf("v1.") && 0 !== e.version.indexOf("v1.8."))
          ? (t.exports = {
              nextTick: function(t, r, n, i) {
                if ("function" != typeof t)
                  throw new TypeError('"callback" argument must be a function');
                var o,
                  s,
                  a = arguments.length;
                switch (a) {
                  case 0:
                  case 1:
                    return e.nextTick(t);
                  case 2:
                    return e.nextTick(function() {
                      t.call(null, r);
                    });
                  case 3:
                    return e.nextTick(function() {
                      t.call(null, r, n);
                    });
                  case 4:
                    return e.nextTick(function() {
                      t.call(null, r, n, i);
                    });
                  default:
                    for (o = new Array(a - 1), s = 0; s < o.length; )
                      o[s++] = arguments[s];
                    return e.nextTick(function() {
                      t.apply(null, o);
                    });
                }
              }
            })
          : (t.exports = e);
      }.call(this, r(41)));
    },
    function(t, e, r) {
      var n = r(19),
        i = n.Buffer;
      function o(t, e) {
        for (var r in t) e[r] = t[r];
      }
      function s(t, e, r) {
        return i(t, e, r);
      }
      i.from && i.alloc && i.allocUnsafe && i.allocUnsafeSlow
        ? (t.exports = n)
        : (o(n, e), (e.Buffer = s)),
        o(i, s),
        (s.from = function(t, e, r) {
          if ("number" == typeof t)
            throw new TypeError("Argument must not be a number");
          return i(t, e, r);
        }),
        (s.alloc = function(t, e, r) {
          if ("number" != typeof t)
            throw new TypeError("Argument must be a number");
          var n = i(t);
          return (
            void 0 !== e
              ? "string" == typeof r
                ? n.fill(e, r)
                : n.fill(e)
              : n.fill(0),
            n
          );
        }),
        (s.allocUnsafe = function(t) {
          if ("number" != typeof t)
            throw new TypeError("Argument must be a number");
          return i(t);
        }),
        (s.allocUnsafeSlow = function(t) {
          if ("number" != typeof t)
            throw new TypeError("Argument must be a number");
          return n.SlowBuffer(t);
        });
    },
    function(t, e, r) {
      var n = {};
      (n[r(3)("toStringTag")] = "z"), (t.exports = "[object z]" === String(n));
    },
    function(t, e, r) {
      var n = r(1),
        i = r(8);
      t.exports = function(t, e) {
        try {
          i(n, t, e);
        } catch (r) {
          n[t] = e;
        }
        return e;
      };
    },
    function(t, e, r) {
      var n = r(1),
        i = r(12),
        o = n.document,
        s = i(o) && i(o.createElement);
      t.exports = function(t) {
        return s ? o.createElement(t) : {};
      };
    },
    function(t, e, r) {
      var n = r(77),
        i = Function.toString;
      "function" != typeof n.inspectSource &&
        (n.inspectSource = function(t) {
          return i.call(t);
        }),
        (t.exports = n.inspectSource);
    },
    function(t, e, r) {
      var n = r(76),
        i = r(80),
        o = n("keys");
      t.exports = function(t) {
        return o[t] || (o[t] = i(t));
      };
    },
    function(t, e) {
      t.exports = {};
    },
    function(t, e) {
      var r = Math.ceil,
        n = Math.floor;
      t.exports = function(t) {
        return isNaN((t = +t)) ? 0 : (t > 0 ? n : r)(t);
      };
    },
    function(t, e) {
      t.exports = function(t) {
        if (null == t) throw TypeError("Can't call method on " + t);
        return t;
      };
    },
    function(t, e, r) {
      var n = r(10),
        i = r(135),
        o = r(30),
        s = r(31),
        a = r(79),
        c = r(6),
        u = r(78),
        f = Object.getOwnPropertyDescriptor;
      e.f = n
        ? f
        : function(t, e) {
            if (((t = s(t)), (e = a(e, !0)), u))
              try {
                return f(t, e);
              } catch (t) {}
            if (c(t, e)) return o(!i.f.call(t, e), t[e]);
          };
    },
    function(t, e) {
      t.exports = [
        "constructor",
        "hasOwnProperty",
        "isPrototypeOf",
        "propertyIsEnumerable",
        "toLocaleString",
        "toString",
        "valueOf"
      ];
    },
    function(t, e, r) {
      var n = r(6),
        i = r(144),
        o = r(48),
        s = r(145),
        a = o("IE_PROTO"),
        c = Object.prototype;
      t.exports = s
        ? Object.getPrototypeOf
        : function(t) {
            return (
              (t = i(t)),
              n(t, a)
                ? t[a]
                : "function" == typeof t.constructor &&
                  t instanceof t.constructor
                ? t.constructor.prototype
                : t instanceof Object
                ? c
                : null
            );
          };
    },
    function(t, e, r) {
      var n,
        i = r(5),
        o = r(146),
        s = r(53),
        a = r(49),
        c = r(89),
        u = r(46),
        f = r(48),
        l = f("IE_PROTO"),
        h = function() {},
        p = function(t) {
          return "<script>" + t + "</script>";
        },
        d = function() {
          try {
            n = document.domain && new ActiveXObject("htmlfile");
          } catch (t) {}
          var t, e;
          d = n
            ? (function(t) {
                t.write(p("")), t.close();
                var e = t.parentWindow.Object;
                return (t = null), e;
              })(n)
            : (((e = u("iframe")).style.display = "none"),
              c.appendChild(e),
              (e.src = String("javascript:")),
              (t = e.contentWindow.document).open(),
              t.write(p("document.F=Object")),
              t.close(),
              t.F);
          for (var r = s.length; r--; ) delete d.prototype[s[r]];
          return d();
        };
      (a[l] = !0),
        (t.exports =
          Object.create ||
          function(t, e) {
            var r;
            return (
              null !== t
                ? ((h.prototype = i(t)),
                  (r = new h()),
                  (h.prototype = null),
                  (r[l] = t))
                : (r = d()),
              void 0 === e ? r : o(r, e)
            );
          });
    },
    function(t, e, r) {
      var n = r(11).f,
        i = r(6),
        o = r(3)("toStringTag");
      t.exports = function(t, e, r) {
        t &&
          !i((t = r ? t : t.prototype), o) &&
          n(t, o, { configurable: !0, value: e });
      };
    },
    function(t, e, r) {
      "use strict";
      var n,
        i = r(36);
      function o() {
        try {
          return n.apply(this, arguments);
        } catch (t) {
          return (i.errorObject.e = t), i.errorObject;
        }
      }
      e.tryCatch = function(t) {
        return (n = t), o;
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(7).root.Symbol;
      (e.rxSubscriber =
        "function" == typeof n && "function" == typeof n.for
          ? n.for("rxSubscriber")
          : "@@rxSubscriber"),
        (e.$$rxSubscriber = e.rxSubscriber);
    },
    function(t, e, r) {
      "use strict";
      var n = r(7);
      function i(t) {
        var e,
          r = t.Symbol;
        return (
          "function" == typeof r
            ? r.observable
              ? (e = r.observable)
              : ((e = r("observable")), (r.observable = e))
            : (e = "@@observable"),
          e
        );
      }
      (e.getSymbolObservable = i),
        (e.observable = i(n.root)),
        (e.$$observable = e.observable);
    },
    function(t, e, r) {
      "use strict";
      var n = r(7);
      function i(t) {
        var e = t.Symbol;
        if ("function" == typeof e)
          return (
            e.iterator || (e.iterator = e("iterator polyfill")), e.iterator
          );
        var r = t.Set;
        if (r && "function" == typeof new r()["@@iterator"])
          return "@@iterator";
        var n = t.Map;
        if (n)
          for (
            var i = Object.getOwnPropertyNames(n.prototype), o = 0;
            o < i.length;
            ++o
          ) {
            var s = i[o];
            if (
              "entries" !== s &&
              "size" !== s &&
              n.prototype[s] === n.prototype.entries
            )
              return s;
          }
        return "@@iterator";
      }
      (e.symbolIteratorPonyfill = i),
        (e.iterator = i(n.root)),
        (e.$$iterator = e.iterator);
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(0),
        o = r(106),
        s = r(40),
        a = r(186),
        c = (function(t) {
          function e(e, r) {
            t.call(this),
              (this.array = e),
              (this.scheduler = r),
              r ||
                1 !== e.length ||
                ((this._isScalar = !0), (this.value = e[0]));
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              return new e(t, r);
            }),
            (e.of = function() {
              for (var t = [], r = 0; r < arguments.length; r++)
                t[r - 0] = arguments[r];
              var n = t[t.length - 1];
              a.isScheduler(n) ? t.pop() : (n = null);
              var i = t.length;
              return i > 1
                ? new e(t, n)
                : 1 === i
                ? new o.ScalarObservable(t[0], n)
                : new s.EmptyObservable(n);
            }),
            (e.dispatch = function(t) {
              var e = t.array,
                r = t.index,
                n = t.count,
                i = t.subscriber;
              r >= n
                ? i.complete()
                : (i.next(e[r]),
                  i.closed || ((t.index = r + 1), this.schedule(t)));
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.array,
                n = r.length,
                i = this.scheduler;
              if (i)
                return i.schedule(e.dispatch, 0, {
                  array: r,
                  index: 0,
                  count: n,
                  subscriber: t
                });
              for (var o = 0; o < n && !t.closed; o++) t.next(r[o]);
              t.complete();
            }),
            e
          );
        })(i.Observable);
      e.ArrayObservable = c;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(114),
        i = function(t) {
          (this.id = n.generateGuid()),
            (this.content = t),
            (this.description = {
              id: this.id,
              type: this.content.headers
                ? this.content.headers.type
                : "unknown",
              length: this.content.headers
                ? this.content.headers.contentLength
                : 0
            });
        };
      e.HttpContentStream = i;
      var o = (function() {
        function t(t, e) {
          (this.headers = t), (this.stream = e);
        }
        return (
          (t.prototype.getStream = function() {
            return this.stream;
          }),
          t
        );
      })();
      e.HttpContent = o;
    },
    function(t, e, r) {
      "use strict";
      var n,
        i = "object" == typeof Reflect ? Reflect : null,
        o =
          i && "function" == typeof i.apply
            ? i.apply
            : function(t, e, r) {
                return Function.prototype.apply.call(t, e, r);
              };
      n =
        i && "function" == typeof i.ownKeys
          ? i.ownKeys
          : Object.getOwnPropertySymbols
          ? function(t) {
              return Object.getOwnPropertyNames(t).concat(
                Object.getOwnPropertySymbols(t)
              );
            }
          : function(t) {
              return Object.getOwnPropertyNames(t);
            };
      var s =
        Number.isNaN ||
        function(t) {
          return t != t;
        };
      function a() {
        a.init.call(this);
      }
      (t.exports = a),
        (a.EventEmitter = a),
        (a.prototype._events = void 0),
        (a.prototype._eventsCount = 0),
        (a.prototype._maxListeners = void 0);
      var c = 10;
      function u(t) {
        if ("function" != typeof t)
          throw new TypeError(
            'The "listener" argument must be of type Function. Received type ' +
              typeof t
          );
      }
      function f(t) {
        return void 0 === t._maxListeners
          ? a.defaultMaxListeners
          : t._maxListeners;
      }
      function l(t, e, r, n) {
        var i, o, s, a;
        if (
          (u(r),
          void 0 === (o = t._events)
            ? ((o = t._events = Object.create(null)), (t._eventsCount = 0))
            : (void 0 !== o.newListener &&
                (t.emit("newListener", e, r.listener ? r.listener : r),
                (o = t._events)),
              (s = o[e])),
          void 0 === s)
        )
          (s = o[e] = r), ++t._eventsCount;
        else if (
          ("function" == typeof s
            ? (s = o[e] = n ? [r, s] : [s, r])
            : n
            ? s.unshift(r)
            : s.push(r),
          (i = f(t)) > 0 && s.length > i && !s.warned)
        ) {
          s.warned = !0;
          var c = new Error(
            "Possible EventEmitter memory leak detected. " +
              s.length +
              " " +
              String(e) +
              " listeners added. Use emitter.setMaxListeners() to increase limit"
          );
          (c.name = "MaxListenersExceededWarning"),
            (c.emitter = t),
            (c.type = e),
            (c.count = s.length),
            (a = c),
            console && console.warn && console.warn(a);
        }
        return t;
      }
      function h() {
        if (!this.fired)
          return (
            this.target.removeListener(this.type, this.wrapFn),
            (this.fired = !0),
            0 === arguments.length
              ? this.listener.call(this.target)
              : this.listener.apply(this.target, arguments)
          );
      }
      function p(t, e, r) {
        var n = { fired: !1, wrapFn: void 0, target: t, type: e, listener: r },
          i = h.bind(n);
        return (i.listener = r), (n.wrapFn = i), i;
      }
      function d(t, e, r) {
        var n = t._events;
        if (void 0 === n) return [];
        var i = n[e];
        return void 0 === i
          ? []
          : "function" == typeof i
          ? r
            ? [i.listener || i]
            : [i]
          : r
          ? (function(t) {
              for (var e = new Array(t.length), r = 0; r < e.length; ++r)
                e[r] = t[r].listener || t[r];
              return e;
            })(i)
          : v(i, i.length);
      }
      function y(t) {
        var e = this._events;
        if (void 0 !== e) {
          var r = e[t];
          if ("function" == typeof r) return 1;
          if (void 0 !== r) return r.length;
        }
        return 0;
      }
      function v(t, e) {
        for (var r = new Array(e), n = 0; n < e; ++n) r[n] = t[n];
        return r;
      }
      Object.defineProperty(a, "defaultMaxListeners", {
        enumerable: !0,
        get: function() {
          return c;
        },
        set: function(t) {
          if ("number" != typeof t || t < 0 || s(t))
            throw new RangeError(
              'The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' +
                t +
                "."
            );
          c = t;
        }
      }),
        (a.init = function() {
          (void 0 !== this._events &&
            this._events !== Object.getPrototypeOf(this)._events) ||
            ((this._events = Object.create(null)), (this._eventsCount = 0)),
            (this._maxListeners = this._maxListeners || void 0);
        }),
        (a.prototype.setMaxListeners = function(t) {
          if ("number" != typeof t || t < 0 || s(t))
            throw new RangeError(
              'The value of "n" is out of range. It must be a non-negative number. Received ' +
                t +
                "."
            );
          return (this._maxListeners = t), this;
        }),
        (a.prototype.getMaxListeners = function() {
          return f(this);
        }),
        (a.prototype.emit = function(t) {
          for (var e = [], r = 1; r < arguments.length; r++)
            e.push(arguments[r]);
          var n = "error" === t,
            i = this._events;
          if (void 0 !== i) n = n && void 0 === i.error;
          else if (!n) return !1;
          if (n) {
            var s;
            if ((e.length > 0 && (s = e[0]), s instanceof Error)) throw s;
            var a = new Error(
              "Unhandled error." + (s ? " (" + s.message + ")" : "")
            );
            throw ((a.context = s), a);
          }
          var c = i[t];
          if (void 0 === c) return !1;
          if ("function" == typeof c) o(c, this, e);
          else {
            var u = c.length,
              f = v(c, u);
            for (r = 0; r < u; ++r) o(f[r], this, e);
          }
          return !0;
        }),
        (a.prototype.addListener = function(t, e) {
          return l(this, t, e, !1);
        }),
        (a.prototype.on = a.prototype.addListener),
        (a.prototype.prependListener = function(t, e) {
          return l(this, t, e, !0);
        }),
        (a.prototype.once = function(t, e) {
          return u(e), this.on(t, p(this, t, e)), this;
        }),
        (a.prototype.prependOnceListener = function(t, e) {
          return u(e), this.prependListener(t, p(this, t, e)), this;
        }),
        (a.prototype.removeListener = function(t, e) {
          var r, n, i, o, s;
          if ((u(e), void 0 === (n = this._events))) return this;
          if (void 0 === (r = n[t])) return this;
          if (r === e || r.listener === e)
            0 == --this._eventsCount
              ? (this._events = Object.create(null))
              : (delete n[t],
                n.removeListener &&
                  this.emit("removeListener", t, r.listener || e));
          else if ("function" != typeof r) {
            for (i = -1, o = r.length - 1; o >= 0; o--)
              if (r[o] === e || r[o].listener === e) {
                (s = r[o].listener), (i = o);
                break;
              }
            if (i < 0) return this;
            0 === i
              ? r.shift()
              : (function(t, e) {
                  for (; e + 1 < t.length; e++) t[e] = t[e + 1];
                  t.pop();
                })(r, i),
              1 === r.length && (n[t] = r[0]),
              void 0 !== n.removeListener &&
                this.emit("removeListener", t, s || e);
          }
          return this;
        }),
        (a.prototype.off = a.prototype.removeListener),
        (a.prototype.removeAllListeners = function(t) {
          var e, r, n;
          if (void 0 === (r = this._events)) return this;
          if (void 0 === r.removeListener)
            return (
              0 === arguments.length
                ? ((this._events = Object.create(null)),
                  (this._eventsCount = 0))
                : void 0 !== r[t] &&
                  (0 == --this._eventsCount
                    ? (this._events = Object.create(null))
                    : delete r[t]),
              this
            );
          if (0 === arguments.length) {
            var i,
              o = Object.keys(r);
            for (n = 0; n < o.length; ++n)
              "removeListener" !== (i = o[n]) && this.removeAllListeners(i);
            return (
              this.removeAllListeners("removeListener"),
              (this._events = Object.create(null)),
              (this._eventsCount = 0),
              this
            );
          }
          if ("function" == typeof (e = r[t])) this.removeListener(t, e);
          else if (void 0 !== e)
            for (n = e.length - 1; n >= 0; n--) this.removeListener(t, e[n]);
          return this;
        }),
        (a.prototype.listeners = function(t) {
          return d(this, t, !0);
        }),
        (a.prototype.rawListeners = function(t) {
          return d(this, t, !1);
        }),
        (a.listenerCount = function(t, e) {
          return "function" == typeof t.listenerCount
            ? t.listenerCount(e)
            : y.call(t, e);
        }),
        (a.prototype.listenerCount = y),
        (a.prototype.eventNames = function() {
          return this._eventsCount > 0 ? n(this._events) : [];
        });
    },
    function(t, e, r) {
      ((e = t.exports = r(115)).Stream = e),
        (e.Readable = e),
        (e.Writable = r(65)),
        (e.Duplex = r(15)),
        (e.Transform = r(119)),
        (e.PassThrough = r(268));
    },
    function(t, e, r) {
      "use strict";
      (function(e, n, i) {
        var o = r(42);
        function s(t) {
          var e = this;
          (this.next = null),
            (this.entry = null),
            (this.finish = function() {
              !(function(t, e, r) {
                var n = t.entry;
                t.entry = null;
                for (; n; ) {
                  var i = n.callback;
                  e.pendingcb--, i(r), (n = n.next);
                }
                e.corkedRequestsFree
                  ? (e.corkedRequestsFree.next = t)
                  : (e.corkedRequestsFree = t);
              })(e, t);
            });
        }
        t.exports = g;
        var a,
          c =
            !e.browser && ["v0.10", "v0.9."].indexOf(e.version.slice(0, 5)) > -1
              ? n
              : o.nextTick;
        g.WritableState = b;
        var u = r(28);
        u.inherits = r(20);
        var f = { deprecate: r(267) },
          l = r(116),
          h = r(43).Buffer,
          p = i.Uint8Array || function() {};
        var d,
          y = r(117);
        function v() {}
        function b(t, e) {
          (a = a || r(15)), (t = t || {});
          var n = e instanceof a;
          (this.objectMode = !!t.objectMode),
            n && (this.objectMode = this.objectMode || !!t.writableObjectMode);
          var i = t.highWaterMark,
            u = t.writableHighWaterMark,
            f = this.objectMode ? 16 : 16384;
          (this.highWaterMark = i || 0 === i ? i : n && (u || 0 === u) ? u : f),
            (this.highWaterMark = Math.floor(this.highWaterMark)),
            (this.finalCalled = !1),
            (this.needDrain = !1),
            (this.ending = !1),
            (this.ended = !1),
            (this.finished = !1),
            (this.destroyed = !1);
          var l = !1 === t.decodeStrings;
          (this.decodeStrings = !l),
            (this.defaultEncoding = t.defaultEncoding || "utf8"),
            (this.length = 0),
            (this.writing = !1),
            (this.corked = 0),
            (this.sync = !0),
            (this.bufferProcessing = !1),
            (this.onwrite = function(t) {
              !(function(t, e) {
                var r = t._writableState,
                  n = r.sync,
                  i = r.writecb;
                if (
                  ((function(t) {
                    (t.writing = !1),
                      (t.writecb = null),
                      (t.length -= t.writelen),
                      (t.writelen = 0);
                  })(r),
                  e)
                )
                  !(function(t, e, r, n, i) {
                    --e.pendingcb,
                      r
                        ? (o.nextTick(i, n),
                          o.nextTick(O, t, e),
                          (t._writableState.errorEmitted = !0),
                          t.emit("error", n))
                        : (i(n),
                          (t._writableState.errorEmitted = !0),
                          t.emit("error", n),
                          O(t, e));
                  })(t, r, n, e, i);
                else {
                  var s = S(r);
                  s ||
                    r.corked ||
                    r.bufferProcessing ||
                    !r.bufferedRequest ||
                    _(t, r),
                    n ? c(w, t, r, s, i) : w(t, r, s, i);
                }
              })(e, t);
            }),
            (this.writecb = null),
            (this.writelen = 0),
            (this.bufferedRequest = null),
            (this.lastBufferedRequest = null),
            (this.pendingcb = 0),
            (this.prefinished = !1),
            (this.errorEmitted = !1),
            (this.bufferedRequestCount = 0),
            (this.corkedRequestsFree = new s(this));
        }
        function g(t) {
          if (((a = a || r(15)), !(d.call(g, this) || this instanceof a)))
            return new g(t);
          (this._writableState = new b(t, this)),
            (this.writable = !0),
            t &&
              ("function" == typeof t.write && (this._write = t.write),
              "function" == typeof t.writev && (this._writev = t.writev),
              "function" == typeof t.destroy && (this._destroy = t.destroy),
              "function" == typeof t.final && (this._final = t.final)),
            l.call(this);
        }
        function m(t, e, r, n, i, o, s) {
          (e.writelen = n),
            (e.writecb = s),
            (e.writing = !0),
            (e.sync = !0),
            r ? t._writev(i, e.onwrite) : t._write(i, o, e.onwrite),
            (e.sync = !1);
        }
        function w(t, e, r, n) {
          r ||
            (function(t, e) {
              0 === e.length &&
                e.needDrain &&
                ((e.needDrain = !1), t.emit("drain"));
            })(t, e),
            e.pendingcb--,
            n(),
            O(t, e);
        }
        function _(t, e) {
          e.bufferProcessing = !0;
          var r = e.bufferedRequest;
          if (t._writev && r && r.next) {
            var n = e.bufferedRequestCount,
              i = new Array(n),
              o = e.corkedRequestsFree;
            o.entry = r;
            for (var a = 0, c = !0; r; )
              (i[a] = r), r.isBuf || (c = !1), (r = r.next), (a += 1);
            (i.allBuffers = c),
              m(t, e, !0, e.length, i, "", o.finish),
              e.pendingcb++,
              (e.lastBufferedRequest = null),
              o.next
                ? ((e.corkedRequestsFree = o.next), (o.next = null))
                : (e.corkedRequestsFree = new s(e)),
              (e.bufferedRequestCount = 0);
          } else {
            for (; r; ) {
              var u = r.chunk,
                f = r.encoding,
                l = r.callback;
              if (
                (m(t, e, !1, e.objectMode ? 1 : u.length, u, f, l),
                (r = r.next),
                e.bufferedRequestCount--,
                e.writing)
              )
                break;
            }
            null === r && (e.lastBufferedRequest = null);
          }
          (e.bufferedRequest = r), (e.bufferProcessing = !1);
        }
        function S(t) {
          return (
            t.ending &&
            0 === t.length &&
            null === t.bufferedRequest &&
            !t.finished &&
            !t.writing
          );
        }
        function x(t, e) {
          t._final(function(r) {
            e.pendingcb--,
              r && t.emit("error", r),
              (e.prefinished = !0),
              t.emit("prefinish"),
              O(t, e);
          });
        }
        function O(t, e) {
          var r = S(e);
          return (
            r &&
              (!(function(t, e) {
                e.prefinished ||
                  e.finalCalled ||
                  ("function" == typeof t._final
                    ? (e.pendingcb++, (e.finalCalled = !0), o.nextTick(x, t, e))
                    : ((e.prefinished = !0), t.emit("prefinish")));
              })(t, e),
              0 === e.pendingcb && ((e.finished = !0), t.emit("finish"))),
            r
          );
        }
        u.inherits(g, l),
          (b.prototype.getBuffer = function() {
            for (var t = this.bufferedRequest, e = []; t; )
              e.push(t), (t = t.next);
            return e;
          }),
          (function() {
            try {
              Object.defineProperty(b.prototype, "buffer", {
                get: f.deprecate(
                  function() {
                    return this.getBuffer();
                  },
                  "_writableState.buffer is deprecated. Use _writableState.getBuffer instead.",
                  "DEP0003"
                )
              });
            } catch (t) {}
          })(),
          "function" == typeof Symbol &&
          Symbol.hasInstance &&
          "function" == typeof Function.prototype[Symbol.hasInstance]
            ? ((d = Function.prototype[Symbol.hasInstance]),
              Object.defineProperty(g, Symbol.hasInstance, {
                value: function(t) {
                  return (
                    !!d.call(this, t) ||
                    (this === g && t && t._writableState instanceof b)
                  );
                }
              }))
            : (d = function(t) {
                return t instanceof this;
              }),
          (g.prototype.pipe = function() {
            this.emit("error", new Error("Cannot pipe, not readable"));
          }),
          (g.prototype.write = function(t, e, r) {
            var n,
              i = this._writableState,
              s = !1,
              a = !i.objectMode && ((n = t), h.isBuffer(n) || n instanceof p);
            return (
              a &&
                !h.isBuffer(t) &&
                (t = (function(t) {
                  return h.from(t);
                })(t)),
              "function" == typeof e && ((r = e), (e = null)),
              a ? (e = "buffer") : e || (e = i.defaultEncoding),
              "function" != typeof r && (r = v),
              i.ended
                ? (function(t, e) {
                    var r = new Error("write after end");
                    t.emit("error", r), o.nextTick(e, r);
                  })(this, r)
                : (a ||
                    (function(t, e, r, n) {
                      var i = !0,
                        s = !1;
                      return (
                        null === r
                          ? (s = new TypeError(
                              "May not write null values to stream"
                            ))
                          : "string" == typeof r ||
                            void 0 === r ||
                            e.objectMode ||
                            (s = new TypeError(
                              "Invalid non-string/buffer chunk"
                            )),
                        s && (t.emit("error", s), o.nextTick(n, s), (i = !1)),
                        i
                      );
                    })(this, i, t, r)) &&
                  (i.pendingcb++,
                  (s = (function(t, e, r, n, i, o) {
                    if (!r) {
                      var s = (function(t, e, r) {
                        t.objectMode ||
                          !1 === t.decodeStrings ||
                          "string" != typeof e ||
                          (e = h.from(e, r));
                        return e;
                      })(e, n, i);
                      n !== s && ((r = !0), (i = "buffer"), (n = s));
                    }
                    var a = e.objectMode ? 1 : n.length;
                    e.length += a;
                    var c = e.length < e.highWaterMark;
                    c || (e.needDrain = !0);
                    if (e.writing || e.corked) {
                      var u = e.lastBufferedRequest;
                      (e.lastBufferedRequest = {
                        chunk: n,
                        encoding: i,
                        isBuf: r,
                        callback: o,
                        next: null
                      }),
                        u
                          ? (u.next = e.lastBufferedRequest)
                          : (e.bufferedRequest = e.lastBufferedRequest),
                        (e.bufferedRequestCount += 1);
                    } else m(t, e, !1, a, n, i, o);
                    return c;
                  })(this, i, a, t, e, r))),
              s
            );
          }),
          (g.prototype.cork = function() {
            this._writableState.corked++;
          }),
          (g.prototype.uncork = function() {
            var t = this._writableState;
            t.corked &&
              (t.corked--,
              t.writing ||
                t.corked ||
                t.finished ||
                t.bufferProcessing ||
                !t.bufferedRequest ||
                _(this, t));
          }),
          (g.prototype.setDefaultEncoding = function(t) {
            if (
              ("string" == typeof t && (t = t.toLowerCase()),
              !(
                [
                  "hex",
                  "utf8",
                  "utf-8",
                  "ascii",
                  "binary",
                  "base64",
                  "ucs2",
                  "ucs-2",
                  "utf16le",
                  "utf-16le",
                  "raw"
                ].indexOf((t + "").toLowerCase()) > -1
              ))
            )
              throw new TypeError("Unknown encoding: " + t);
            return (this._writableState.defaultEncoding = t), this;
          }),
          Object.defineProperty(g.prototype, "writableHighWaterMark", {
            enumerable: !1,
            get: function() {
              return this._writableState.highWaterMark;
            }
          }),
          (g.prototype._write = function(t, e, r) {
            r(new Error("_write() is not implemented"));
          }),
          (g.prototype._writev = null),
          (g.prototype.end = function(t, e, r) {
            var n = this._writableState;
            "function" == typeof t
              ? ((r = t), (t = null), (e = null))
              : "function" == typeof e && ((r = e), (e = null)),
              null != t && this.write(t, e),
              n.corked && ((n.corked = 1), this.uncork()),
              n.ending ||
                n.finished ||
                (function(t, e, r) {
                  (e.ending = !0),
                    O(t, e),
                    r && (e.finished ? o.nextTick(r) : t.once("finish", r));
                  (e.ended = !0), (t.writable = !1);
                })(this, n, r);
          }),
          Object.defineProperty(g.prototype, "destroyed", {
            get: function() {
              return (
                void 0 !== this._writableState && this._writableState.destroyed
              );
            },
            set: function(t) {
              this._writableState && (this._writableState.destroyed = t);
            }
          }),
          (g.prototype.destroy = y.destroy),
          (g.prototype._undestroy = y.undestroy),
          (g.prototype._destroy = function(t, e) {
            this.end(), e(t);
          });
      }.call(this, r(41), r(265).setImmediate, r(4)));
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(67),
        i = (function() {
          function t() {}
          return (
            (t.serialize = function(t, e) {
              e.write(t.payloadType, this.TypeOffset, 1, this.Encoding),
                e.write(
                  this.Delimiter,
                  this.TypeDelimiterOffset,
                  1,
                  this.Encoding
                ),
                e.write(
                  this.headerLengthPadder(
                    t.payloadLength,
                    this.LengthLength,
                    "0"
                  ),
                  this.LengthOffset,
                  this.LengthLength,
                  this.Encoding
                ),
                e.write(
                  this.Delimiter,
                  this.LengthDelimeterOffset,
                  1,
                  this.Encoding
                ),
                e.write(t.id, this.IdOffset),
                e.write(
                  this.Delimiter,
                  this.IdDelimeterOffset,
                  1,
                  this.Encoding
                ),
                e.write(t.end ? this.End : this.NotEnd, this.EndOffset),
                e.write(this.Terminator, this.TerminatorOffset);
            }),
            (t.deserialize = function(t) {
              var e = t.toString(this.Encoding),
                r = e.split(this.Delimiter);
              if (4 !== r.length)
                throw Error(
                  "Cannot parse header, header is malformed. Header: " + e
                );
              var i = r[0],
                o = r[1],
                s = r[2],
                a = r[3],
                c = {
                  end: "1\n" === a,
                  payloadLength: Number(o),
                  payloadType: i,
                  id: s
                };
              if (
                !(
                  c.payloadLength <= n.PayloadConstants.MaxPayloadLength &&
                  c.payloadLength >= n.PayloadConstants.MinLength
                )
              )
                throw Error(
                  "Header length of " +
                    c.payloadLength +
                    " is missing or malformed"
                );
              if (c.payloadType.length !== this.TypeDelimiterOffset)
                throw Error(
                  "Header type '" +
                    c.payloadType.length +
                    "' is missing or malformed."
                );
              if (
                !c.id ||
                !c.id.match(
                  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
                ) ||
                c.id.length !== this.IdLength
              )
                throw Error(
                  "Header ID '" + c.id + "' is missing or malformed."
                );
              if ("0\n" !== a && "1\n" !== a)
                throw Error(
                  "Header End is missing or not a valid value. Header end: '" +
                    a +
                    "'"
                );
              return c;
            }),
            (t.headerLengthPadder = function(t, e, r) {
              var n = Array(e + 1).join(r),
                i = t.toString();
              return (n + i).slice(i.length);
            }),
            (t.Delimiter = "."),
            (t.Terminator = "\n"),
            (t.End = "1"),
            (t.NotEnd = "0"),
            (t.TypeOffset = 0),
            (t.TypeDelimiterOffset = 1),
            (t.LengthOffset = 2),
            (t.LengthLength = 6),
            (t.LengthDelimeterOffset = 8),
            (t.IdOffset = 9),
            (t.IdLength = 36),
            (t.IdDelimeterOffset = 45),
            (t.EndOffset = 46),
            (t.TerminatorOffset = 47),
            (t.Encoding = "utf8"),
            t
          );
        })();
      e.HeaderSerializer = i;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 }),
        (function(t) {
          (t[(t.MaxPayloadLength = 4096)] = "MaxPayloadLength"),
            (t[(t.MaxHeaderLength = 48)] = "MaxHeaderLength"),
            (t[(t.MaxLength = 999999)] = "MaxLength"),
            (t[(t.MinLength = 0)] = "MinLength");
        })(e.PayloadConstants || (e.PayloadConstants = {}));
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(122),
        i = (function() {
          function t(t) {
            (this.activeAssemblers = []), (this.onCancelStream = t);
          }
          return (
            (t.prototype.getPayloadAssembler = function(t) {
              if (this.activeAssemblers[t]) return this.activeAssemblers[t];
              var e = new n.PayloadAssembler(this, { id: t });
              return (this.activeAssemblers[t] = e), e;
            }),
            (t.prototype.getPayloadStream = function(t) {
              return this.getPayloadAssembler(t.id).getPayloadStream();
            }),
            (t.prototype.onReceive = function(t, e, r) {
              this.activeAssemblers[t.id] &&
                this.activeAssemblers[t.id].onReceive(t, e, r);
            }),
            (t.prototype.closeStream = function(t) {
              if (this.activeAssemblers[t]) {
                var e = this.activeAssemblers[t];
                this.activeAssemblers.splice(
                  this.activeAssemblers.indexOf(t),
                  1
                );
                var r = e.getPayloadStream();
                ((e.contentLength && r.length < e.contentLength) || !e.end) &&
                  this.onCancelStream(e);
              }
            }),
            t
          );
        })();
      e.StreamManager = i;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = r(27),
        s = (function() {
          function t(t, e) {
            (this.sender = t), (this.id = e);
          }
          return (
            (t.serialize = function(t) {
              var e = new o.SubscribableStream();
              return (
                e.write(JSON.stringify(t)),
                e.end(),
                { stream: e, streamLength: e.length }
              );
            }),
            (t.prototype.disassemble = function() {
              return n(this, void 0, void 0, function() {
                var t, e, r;
                return i(this, function(n) {
                  switch (n.label) {
                    case 0:
                      return [4, this.getStream()];
                    case 1:
                      return (
                        (t = n.sent()),
                        (e = t.stream),
                        (r = t.streamLength),
                        (this.stream = e),
                        (this.streamLength = r),
                        [2, this.send()]
                      );
                  }
                });
              });
            }),
            (t.prototype.send = function() {
              return n(this, void 0, void 0, function() {
                var t;
                return i(this, function(e) {
                  return (
                    (t = {
                      payloadType: this.payloadType,
                      payloadLength: this.streamLength,
                      id: this.id,
                      end: !0
                    }),
                    this.sender.sendPayload(t, this.stream),
                    [2]
                  );
                });
              });
            }),
            t
          );
        })();
      e.PayloadDisassembler = s;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = (function() {
        function t(t) {
          this.reason = t;
        }
        return (t.Empty = new t()), t;
      })();
      e.TransportDisconnectedEvent = n;
    },
    function(t, e, r) {
      "use strict";
      var n = r(29);
      Object.defineProperty(e, "__esModule", { value: !0 }),
        Object.defineProperty(e, "DirectLineStreaming", {
          enumerable: !0,
          get: function() {
            return h.DirectLineStreaming;
          }
        }),
        (e.DirectLine = e.ConnectionStatus = void 0);
      var i = n(r(72)),
        o = n(r(73)),
        s = n(r(74)),
        a = n(r(75));
      r(127), r(169);
      var c = r(99),
        u = r(0),
        f = r(37);
      r(179),
        r(183),
        r(187),
        r(190),
        r(194),
        r(197),
        r(200),
        r(202),
        r(204),
        r(207),
        r(210),
        r(215),
        r(219),
        r(222),
        r(224),
        r(231),
        r(235),
        r(237);
      var l = n(r(240)),
        h = r(245);
      function p(t, e) {
        var r = Object.keys(t);
        if (Object.getOwnPropertySymbols) {
          var n = Object.getOwnPropertySymbols(t);
          e &&
            (n = n.filter(function(e) {
              return Object.getOwnPropertyDescriptor(t, e).enumerable;
            })),
            r.push.apply(r, n);
        }
        return r;
      }
      function d(t) {
        for (var e = 1; e < arguments.length; e++) {
          var r = null != arguments[e] ? arguments[e] : {};
          e % 2
            ? p(r, !0).forEach(function(e) {
                (0, a.default)(t, e, r[e]);
              })
            : Object.getOwnPropertyDescriptors
            ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r))
            : p(r).forEach(function(e) {
                Object.defineProperty(
                  t,
                  e,
                  Object.getOwnPropertyDescriptor(r, e)
                );
              });
        }
        return t;
      }
      var y;
      (e.ConnectionStatus = y),
        (function(t) {
          (t[(t.Uninitialized = 0)] = "Uninitialized"),
            (t[(t.Connecting = 1)] = "Connecting"),
            (t[(t.Online = 2)] = "Online"),
            (t[(t.ExpiredToken = 3)] = "ExpiredToken"),
            (t[(t.FailedToConnect = 4)] = "FailedToConnect"),
            (t[(t.Ended = 5)] = "Ended");
        })(y || (e.ConnectionStatus = y = {}));
      var v = function(t, e) {
          var r = function() {
            throw new Error("not implemented");
          };
          return Object.assign(
            function(r) {
              return t(r).catch(function(t) {
                if (429 === t.status) {
                  var r = t.xhr.getResponseHeader("Retry-After"),
                    n = Number(r);
                  if (!isNaN(n))
                    return u.Observable.timer(n, e).flatMap(function(r) {
                      return u.Observable.throw(t, e);
                    });
                }
                return u.Observable.throw(t, e);
              });
            },
            {
              get: function(t, e) {
                return r();
              },
              post: function(t, e, n) {
                return r();
              },
              put: function(t, e, n) {
                return r();
              },
              patch: function(t, e, n) {
                return r();
              },
              delete: function(t, e) {
                return r();
              },
              getJSON: function(t, e) {
                return r();
              }
            }
          );
        },
        b = new Error("expired token"),
        g = new Error("conversation ended"),
        m = new Error("failed to connect"),
        w = function(t) {
          for (
            var e,
              r = arguments.length,
              n = new Array(r > 1 ? r - 1 : 0),
              i = 1;
            i < r;
            i++
          )
            n[i - 1] = arguments[i];
          "undefined" != typeof window &&
            window.botchatDebug &&
            t &&
            (e = console).log.apply(e, [t].concat(n));
        },
        _ = (function() {
          function t(e) {
            var r, n;
            (0, o.default)(this, t),
              (0, a.default)(
                this,
                "connectionStatus$",
                new c.BehaviorSubject(y.Uninitialized)
              ),
              (0, a.default)(this, "activity$", void 0),
              (0, a.default)(
                this,
                "domain",
                "https://directline.botframework.com/v3/directline"
              ),
              (0, a.default)(this, "webSocket", void 0),
              (0, a.default)(this, "conversationId", void 0),
              (0, a.default)(this, "expiredTokenExhaustion", void 0),
              (0, a.default)(this, "secret", void 0),
              (0, a.default)(this, "token", void 0),
              (0, a.default)(this, "watermark", ""),
              (0, a.default)(this, "streamUrl", void 0),
              (0, a.default)(this, "_botAgent", ""),
              (0, a.default)(this, "services", void 0),
              (0, a.default)(this, "_userAgent", void 0),
              (0, a.default)(this, "referenceGrammarId", void 0),
              (0, a.default)(this, "timeout", 2e4),
              (0, a.default)(this, "retries", void 0),
              (0, a.default)(this, "localeOnStartConversation", void 0),
              (0, a.default)(this, "pollingInterval", 1e3),
              (0, a.default)(this, "tokenRefreshSubscription", void 0),
              (this.secret = e.secret),
              (this.token = e.secret || e.token),
              (this.webSocket =
                (void 0 === e.webSocket || e.webSocket) &&
                "undefined" != typeof WebSocket &&
                void 0 !== WebSocket),
              e.conversationStartProperties &&
                e.conversationStartProperties.locale &&
                ("[object String]" ===
                Object.prototype.toString.call(
                  e.conversationStartProperties.locale
                )
                  ? (this.localeOnStartConversation =
                      e.conversationStartProperties.locale)
                  : console.warn(
                      "DirectLineJS: conversationStartProperties.locale was ignored: the locale name may be a BCP 47 language tag"
                    )),
              e.domain && (this.domain = e.domain),
              e.conversationId && (this.conversationId = e.conversationId),
              e.watermark && (this.watermark = e.watermark),
              e.streamUrl &&
                (e.token && e.conversationId
                  ? (this.streamUrl = e.streamUrl)
                  : console.warn(
                      "DirectLineJS: streamUrl was ignored: you need to provide a token and a conversationid"
                    )),
              void 0 !== e.timeout && (this.timeout = e.timeout),
              (this.retries = 9e5 / this.timeout),
              (this._botAgent = this.getBotAgent(e.botAgent)),
              (this.services = {
                scheduler: (n = (r = e).scheduler || f.async),
                ajax: v(r.ajax || u.Observable.ajax, n),
                WebSocket: r.WebSocket || WebSocket,
                random: r.random || Math.random
              });
            var i = ~~e.pollingInterval;
            i < 200
              ? void 0 !== e.pollingInterval &&
                console.warn(
                  "DirectLineJS: provided pollingInterval (".concat(
                    e.pollingInterval,
                    ") is under lower bound (200ms), using default of 1000ms"
                  )
                )
              : (this.pollingInterval = i),
              (this.expiredTokenExhaustion = this.setConnectionStatusFallback(
                y.ExpiredToken,
                y.FailedToConnect,
                5
              )),
              (this.activity$ = (this.webSocket
                ? this.webSocketActivity$()
                : this.pollingGetActivity$()
              ).share());
          }
          return (
            (0, s.default)(t, [
              {
                key: "checkConnection",
                value: function() {
                  var t = this,
                    e =
                      arguments.length > 0 &&
                      void 0 !== arguments[0] &&
                      arguments[0],
                    r = this.connectionStatus$
                      .flatMap(function(e) {
                        return e === y.Uninitialized
                          ? (t.connectionStatus$.next(y.Connecting),
                            t.token && t.streamUrl
                              ? (t.connectionStatus$.next(y.Online),
                                u.Observable.of(e, t.services.scheduler))
                              : t
                                  .startConversation()
                                  .do(
                                    function(e) {
                                      (t.conversationId = e.conversationId),
                                        (t.token = t.secret || e.token),
                                        (t.streamUrl = e.streamUrl),
                                        (t.referenceGrammarId =
                                          e.referenceGrammarId),
                                        t.connectionStatus$.next(y.Online);
                                    },
                                    function(e) {
                                      t.connectionStatus$.next(
                                        y.FailedToConnect
                                      );
                                    }
                                  )
                                  .map(function(t) {
                                    return e;
                                  }))
                          : u.Observable.of(e, t.services.scheduler);
                      })
                      .filter(function(t) {
                        return t != y.Uninitialized && t != y.Connecting;
                      })
                      .flatMap(function(e) {
                        switch (e) {
                          case y.Ended:
                            return u.Observable.throw(g, t.services.scheduler);
                          case y.FailedToConnect:
                            return u.Observable.throw(m, t.services.scheduler);
                          case y.ExpiredToken:
                          default:
                            return u.Observable.of(e, t.services.scheduler);
                        }
                      });
                  return e ? r.take(1) : r;
                }
              },
              {
                key: "setConnectionStatusFallback",
                value: function(t, e) {
                  var r =
                    arguments.length > 2 && void 0 !== arguments[2]
                      ? arguments[2]
                      : 5;
                  r--;
                  var n = 0,
                    i = null;
                  return function(o) {
                    return o === t && i === o && n >= r
                      ? ((n = 0), e)
                      : (n++, (i = o), o);
                  };
                }
              },
              {
                key: "expiredToken",
                value: function() {
                  var t = this.connectionStatus$.getValue();
                  t != y.Ended &&
                    t != y.FailedToConnect &&
                    this.connectionStatus$.next(y.ExpiredToken);
                  var e = this.expiredTokenExhaustion(
                    this.connectionStatus$.getValue()
                  );
                  this.connectionStatus$.next(e);
                }
              },
              {
                key: "startConversation",
                value: function() {
                  var t = this,
                    e = this.conversationId
                      ? ""
                          .concat(this.domain, "/conversations/")
                          .concat(this.conversationId, "?watermark=")
                          .concat(this.watermark)
                      : "".concat(this.domain, "/conversations"),
                    r = this.conversationId ? "GET" : "POST",
                    n = this.conversationId
                      ? void 0
                      : { locale: this.localeOnStartConversation };
                  return this.services
                    .ajax({
                      method: r,
                      url: e,
                      body: n,
                      timeout: this.timeout,
                      headers: d(
                        {
                          Accept: "application/json",
                          "Content-Type": "application/json"
                        },
                        this.commonHeaders()
                      )
                    })
                    .map(function(t) {
                      return t.response;
                    })
                    .retryWhen(function(e) {
                      return e
                        .mergeMap(function(e) {
                          return e.status >= 400 && e.status < 600
                            ? u.Observable.throw(e, t.services.scheduler)
                            : u.Observable.of(e, t.services.scheduler);
                        })
                        .delay(t.timeout, t.services.scheduler)
                        .take(t.retries);
                    });
                }
              },
              {
                key: "reconnect",
                value: function(t) {
                  (this.token = t.token),
                    (this.streamUrl = t.streamUrl),
                    this.connectionStatus$.getValue() === y.ExpiredToken &&
                      this.connectionStatus$.next(y.Online);
                }
              },
              {
                key: "end",
                value: function() {
                  this.tokenRefreshSubscription &&
                    this.tokenRefreshSubscription.unsubscribe();
                  try {
                    this.connectionStatus$.next(y.Ended);
                  } catch (t) {
                    if (t === g) return;
                    throw t;
                  }
                }
              },
              {
                key: "getSessionId",
                value: function() {
                  var t = this;
                  return (
                    w("getSessionId"),
                    this.checkConnection(!0)
                      .flatMap(function(e) {
                        return t.services
                          .ajax({
                            method: "GET",
                            url: "".concat(t.domain, "/session/getsessionid"),
                            withCredentials: !0,
                            timeout: t.timeout,
                            headers: d(
                              { "Content-Type": "application/json" },
                              t.commonHeaders()
                            )
                          })
                          .map(function(t) {
                            return t && t.response && t.response.sessionId
                              ? (w(
                                  "getSessionId response: " +
                                    t.response.sessionId
                                ),
                                t.response.sessionId)
                              : "";
                          })
                          .catch(function(e) {
                            return (
                              w("getSessionId error: " + e.status),
                              u.Observable.of("", t.services.scheduler)
                            );
                          });
                      })
                      .catch(function(e) {
                        return t.catchExpiredToken(e);
                      })
                  );
                }
              },
              {
                key: "postActivity",
                value: function(t) {
                  var e = this;
                  return "message" === t.type &&
                    t.attachments &&
                    t.attachments.length > 0
                    ? this.postMessageWithAttachments(t)
                    : (w("postActivity", t),
                      this.checkConnection(!0)
                        .flatMap(function(r) {
                          return e.services
                            .ajax({
                              method: "POST",
                              url: ""
                                .concat(e.domain, "/conversations/")
                                .concat(e.conversationId, "/activities"),
                              body: t,
                              timeout: e.timeout,
                              headers: d(
                                { "Content-Type": "application/json" },
                                e.commonHeaders()
                              )
                            })
                            .map(function(t) {
                              return t.response.id;
                            })
                            .catch(function(t) {
                              return e.catchPostError(t);
                            });
                        })
                        .catch(function(t) {
                          return e.catchExpiredToken(t);
                        }));
                }
              },
              {
                key: "postMessageWithAttachments",
                value: function(t) {
                  var e,
                    r = this,
                    n = t.attachments,
                    o = (0, l.default)(
                      n.map(function(t) {
                        return t.name || "blob";
                      })
                    ),
                    s = n.map(function(t, e) {
                      return d({}, t, { name: o[e] });
                    });
                  return this.checkConnection(!0)
                    .flatMap(function(n) {
                      return (
                        (e = new FormData()).append(
                          "activity",
                          new Blob(
                            [
                              JSON.stringify(
                                d({}, t, {
                                  attachments: s.map(function(t) {
                                    t.contentUrl;
                                    return d(
                                      {},
                                      (0, i.default)(t, ["contentUrl"])
                                    );
                                  })
                                })
                              )
                            ],
                            { type: "application/vnd.microsoft.activity" }
                          )
                        ),
                        u.Observable.from(s, r.services.scheduler)
                          .flatMap(function(t) {
                            return r.services
                              .ajax({
                                method: "GET",
                                url: t.contentUrl,
                                responseType: "arraybuffer"
                              })
                              .do(function(r) {
                                return e.append(
                                  "file",
                                  new Blob([r.response], {
                                    type: t.contentType
                                  }),
                                  t.name
                                );
                              });
                          })
                          .count()
                      );
                    })
                    .flatMap(function(n) {
                      return r.services
                        .ajax({
                          method: "POST",
                          url: ""
                            .concat(r.domain, "/conversations/")
                            .concat(r.conversationId, "/upload?userId=")
                            .concat(t.from.id),
                          body: e,
                          timeout: r.timeout,
                          headers: d({}, r.commonHeaders())
                        })
                        .map(function(t) {
                          return t.response.id;
                        })
                        .catch(function(t) {
                          return r.catchPostError(t);
                        });
                    })
                    .catch(function(t) {
                      return r.catchPostError(t);
                    });
                }
              },
              {
                key: "catchPostError",
                value: function(t) {
                  if (403 === t.status) this.expiredToken();
                  else if (t.status >= 400 && t.status < 500)
                    return u.Observable.throw(t, this.services.scheduler);
                  return u.Observable.of("retry", this.services.scheduler);
                }
              },
              {
                key: "catchExpiredToken",
                value: function(t) {
                  return t === b
                    ? u.Observable.of("retry", this.services.scheduler)
                    : u.Observable.throw(t, this.services.scheduler);
                }
              },
              {
                key: "pollingGetActivity$",
                value: function() {
                  var t = this,
                    e = u.Observable.create(function(e) {
                      var r = new c.BehaviorSubject({});
                      r.subscribe(function() {
                        if (t.connectionStatus$.getValue() === y.Online) {
                          var n = Date.now();
                          t.services
                            .ajax({
                              headers: d(
                                { Accept: "application/json" },
                                t.commonHeaders()
                              ),
                              method: "GET",
                              url: ""
                                .concat(t.domain, "/conversations/")
                                .concat(
                                  t.conversationId,
                                  "/activities?watermark="
                                )
                                .concat(t.watermark),
                              timeout: t.timeout
                            })
                            .subscribe(
                              function(i) {
                                e.next(i),
                                  setTimeout(function() {
                                    return r.next(null);
                                  }, Math.max(
                                    0,
                                    t.pollingInterval - Date.now() + n
                                  ));
                              },
                              function(n) {
                                switch (n.status) {
                                  case 403:
                                    t.connectionStatus$.next(y.ExpiredToken),
                                      setTimeout(function() {
                                        return r.next(null);
                                      }, t.pollingInterval);
                                    break;
                                  case 404:
                                    t.connectionStatus$.next(y.Ended);
                                    break;
                                  default:
                                    e.error(n);
                                }
                              }
                            );
                        }
                      });
                    });
                  return this.checkConnection().flatMap(function(r) {
                    return e
                      .catch(function() {
                        return u.Observable.empty();
                      })
                      .map(function(t) {
                        return t.response;
                      })
                      .flatMap(function(e) {
                        return t.observableFromActivityGroup(e);
                      });
                  });
                }
              },
              {
                key: "observableFromActivityGroup",
                value: function(t) {
                  return (
                    t.watermark && (this.watermark = t.watermark),
                    u.Observable.from(t.activities, this.services.scheduler)
                  );
                }
              },
              {
                key: "webSocketActivity$",
                value: function() {
                  var t = this;
                  return this.checkConnection()
                    .flatMap(function(e) {
                      return t.observableWebSocket().retryWhen(function(e) {
                        return e
                          .delay(t.getRetryDelay(), t.services.scheduler)
                          .mergeMap(function(e) {
                            return t.reconnectToConversation();
                          });
                      });
                    })
                    .flatMap(function(e) {
                      return t.observableFromActivityGroup(e);
                    });
                }
              },
              {
                key: "getRetryDelay",
                value: function() {
                  return Math.floor(3e3 + 12e3 * this.services.random());
                }
              },
              {
                key: "observableWebSocket",
                value: function() {
                  var t = this;
                  return u.Observable.create(function(e) {
                    w("creating WebSocket", t.streamUrl);
                    var r,
                      n,
                      i = new t.services.WebSocket(t.streamUrl);
                    return (
                      (i.onopen = function(e) {
                        w("WebSocket open", e),
                          (r = u.Observable.interval(
                            t.timeout,
                            t.services.scheduler
                          ).subscribe(function(t) {
                            try {
                              i.send("");
                            } catch (t) {
                              w("Ping error", t);
                            }
                          }));
                      }),
                      (i.onclose = function(t) {
                        w("WebSocket close", t),
                          r && r.unsubscribe(),
                          n || e.error(t),
                          (n = !0);
                      }),
                      (i.onerror = function(t) {
                        w("WebSocket error", t),
                          r && r.unsubscribe(),
                          n || e.error(t),
                          (n = !0);
                      }),
                      (i.onmessage = function(t) {
                        return t.data && e.next(JSON.parse(t.data));
                      }),
                      function() {
                        (0 !== i.readyState && 1 !== i.readyState) || i.close();
                      }
                    );
                  });
                }
              },
              {
                key: "reconnectToConversation",
                value: function() {
                  var t = this;
                  return this.checkConnection(!0).flatMap(function(e) {
                    return t.services
                      .ajax({
                        method: "GET",
                        url: ""
                          .concat(t.domain, "/conversations/")
                          .concat(t.conversationId, "?watermark=")
                          .concat(t.watermark),
                        timeout: t.timeout,
                        headers: d(
                          { Accept: "application/json" },
                          t.commonHeaders()
                        )
                      })
                      .do(function(e) {
                        t.secret || (t.token = e.response.token),
                          (t.streamUrl = e.response.streamUrl);
                      })
                      .map(function(t) {
                        return null;
                      })
                      .retryWhen(function(e) {
                        return e
                          .mergeMap(function(e) {
                            if (403 === e.status) t.expiredToken();
                            else if (404 === e.status)
                              return u.Observable.throw(
                                g,
                                t.services.scheduler
                              );
                            return u.Observable.of(e, t.services.scheduler);
                          })
                          .delay(t.timeout, t.services.scheduler)
                          .take(t.retries);
                      });
                  });
                }
              },
              {
                key: "commonHeaders",
                value: function() {
                  return {
                    Authorization: "Bearer ".concat(this.token),
                    "x-ms-bot-agent": this._botAgent
                  };
                }
              },
              {
                key: "getBotAgent",
                value: function() {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : "",
                    e = "directlinejs";
                  return (
                    t && (e += "; ".concat(t)),
                    ""
                      .concat("DirectLine/3.0", " (")
                      .concat(e, " ")
                      .concat("0.13.2-0", ")")
                  );
                }
              }
            ]),
            t
          );
        })();
      e.DirectLine = _;
    },
    function(t, e, r) {
      var n = r(126);
      t.exports = function(t, e) {
        if (null == t) return {};
        var r,
          i,
          o = n(t, e);
        if (Object.getOwnPropertySymbols) {
          var s = Object.getOwnPropertySymbols(t);
          for (i = 0; i < s.length; i++)
            (r = s[i]),
              e.indexOf(r) >= 0 ||
                (Object.prototype.propertyIsEnumerable.call(t, r) &&
                  (o[r] = t[r]));
        }
        return o;
      };
    },
    function(t, e) {
      t.exports = function(t, e) {
        if (!(t instanceof e))
          throw new TypeError("Cannot call a class as a function");
      };
    },
    function(t, e) {
      function r(t, e) {
        for (var r = 0; r < e.length; r++) {
          var n = e[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(t, n.key, n);
        }
      }
      t.exports = function(t, e, n) {
        return e && r(t.prototype, e), n && r(t, n), t;
      };
    },
    function(t, e) {
      t.exports = function(t, e, r) {
        return (
          e in t
            ? Object.defineProperty(t, e, {
                value: r,
                enumerable: !0,
                configurable: !0,
                writable: !0
              })
            : (t[e] = r),
          t
        );
      };
    },
    function(t, e, r) {
      var n = r(21),
        i = r(77);
      (t.exports = function(t, e) {
        return i[t] || (i[t] = void 0 !== e ? e : {});
      })("versions", []).push({
        version: "3.6.4",
        mode: n ? "pure" : "global",
        copyright: "© 2020 Denis Pushkarev (zloirock.ru)"
      });
    },
    function(t, e, r) {
      var n = r(1),
        i = r(45),
        o = n["__core-js_shared__"] || i("__core-js_shared__", {});
      t.exports = o;
    },
    function(t, e, r) {
      var n = r(10),
        i = r(9),
        o = r(46);
      t.exports =
        !n &&
        !i(function() {
          return (
            7 !=
            Object.defineProperty(o("div"), "a", {
              get: function() {
                return 7;
              }
            }).a
          );
        });
    },
    function(t, e, r) {
      var n = r(12);
      t.exports = function(t, e) {
        if (!n(t)) return t;
        var r, i;
        if (e && "function" == typeof (r = t.toString) && !n((i = r.call(t))))
          return i;
        if ("function" == typeof (r = t.valueOf) && !n((i = r.call(t))))
          return i;
        if (!e && "function" == typeof (r = t.toString) && !n((i = r.call(t))))
          return i;
        throw TypeError("Can't convert object to primitive value");
      };
    },
    function(t, e) {
      var r = 0,
        n = Math.random();
      t.exports = function(t) {
        return (
          "Symbol(" +
          String(void 0 === t ? "" : t) +
          ")_" +
          (++r + n).toString(36)
        );
      };
    },
    function(t, e, r) {
      var n = r(9);
      t.exports =
        !!Object.getOwnPropertySymbols &&
        !n(function() {
          return !String(Symbol());
        });
    },
    function(t, e, r) {
      var n = r(44),
        i = r(23),
        o = r(3)("toStringTag"),
        s =
          "Arguments" ==
          i(
            (function() {
              return arguments;
            })()
          );
      t.exports = n
        ? i
        : function(t) {
            var e, r, n;
            return void 0 === t
              ? "Undefined"
              : null === t
              ? "Null"
              : "string" ==
                typeof (r = (function(t, e) {
                  try {
                    return t[e];
                  } catch (t) {}
                })((e = Object(t)), o))
              ? r
              : s
              ? i(e)
              : "Object" == (n = i(e)) && "function" == typeof e.callee
              ? "Arguments"
              : n;
          };
    },
    function(t, e, r) {
      "use strict";
      var n = r(13),
        i = r(143),
        o = r(54),
        s = r(90),
        a = r(56),
        c = r(8),
        u = r(17),
        f = r(3),
        l = r(21),
        h = r(24),
        p = r(88),
        d = p.IteratorPrototype,
        y = p.BUGGY_SAFARI_ITERATORS,
        v = f("iterator"),
        b = function() {
          return this;
        };
      t.exports = function(t, e, r, f, p, g, m) {
        i(r, e, f);
        var w,
          _,
          S,
          x = function(t) {
            if (t === p && T) return T;
            if (!y && t in k) return k[t];
            switch (t) {
              case "keys":
              case "values":
              case "entries":
                return function() {
                  return new r(this, t);
                };
            }
            return function() {
              return new r(this);
            };
          },
          O = e + " Iterator",
          E = !1,
          k = t.prototype,
          P = k[v] || k["@@iterator"] || (p && k[p]),
          T = (!y && P) || x(p),
          j = ("Array" == e && k.entries) || P;
        if (
          (j &&
            ((w = o(j.call(new t()))),
            d !== Object.prototype &&
              w.next &&
              (l ||
                o(w) === d ||
                (s ? s(w, d) : "function" != typeof w[v] && c(w, v, b)),
              a(w, O, !0, !0),
              l && (h[O] = b))),
          "values" == p &&
            P &&
            "values" !== P.name &&
            ((E = !0),
            (T = function() {
              return P.call(this);
            })),
          (l && !m) || k[v] === T || c(k, v, T),
          (h[e] = T),
          p)
        )
          if (
            ((_ = {
              values: x("values"),
              keys: g ? T : x("keys"),
              entries: x("entries")
            }),
            m)
          )
            for (S in _) (!y && !E && S in k) || u(k, S, _[S]);
          else n({ target: e, proto: !0, forced: y || E }, _);
        return _;
      };
    },
    function(t, e, r) {
      var n = r(1);
      t.exports = n;
    },
    function(t, e, r) {
      var n = r(6),
        i = r(31),
        o = r(140).indexOf,
        s = r(49);
      t.exports = function(t, e) {
        var r,
          a = i(t),
          c = 0,
          u = [];
        for (r in a) !n(s, r) && n(a, r) && u.push(r);
        for (; e.length > c; ) n(a, (r = e[c++])) && (~o(u, r) || u.push(r));
        return u;
      };
    },
    function(t, e, r) {
      var n = r(50),
        i = Math.min;
      t.exports = function(t) {
        return t > 0 ? i(n(t), 9007199254740991) : 0;
      };
    },
    function(t, e, r) {
      var n = r(9),
        i = /#|\.prototype\./,
        o = function(t, e) {
          var r = a[s(t)];
          return r == u || (r != c && ("function" == typeof e ? n(e) : !!e));
        },
        s = (o.normalize = function(t) {
          return String(t)
            .replace(i, ".")
            .toLowerCase();
        }),
        a = (o.data = {}),
        c = (o.NATIVE = "N"),
        u = (o.POLYFILL = "P");
      t.exports = o;
    },
    function(t, e, r) {
      "use strict";
      var n,
        i,
        o,
        s = r(54),
        a = r(8),
        c = r(6),
        u = r(3),
        f = r(21),
        l = u("iterator"),
        h = !1;
      [].keys &&
        ("next" in (o = [].keys())
          ? (i = s(s(o))) !== Object.prototype && (n = i)
          : (h = !0)),
        null == n && (n = {}),
        f ||
          c(n, l) ||
          a(n, l, function() {
            return this;
          }),
        (t.exports = { IteratorPrototype: n, BUGGY_SAFARI_ITERATORS: h });
    },
    function(t, e, r) {
      var n = r(14);
      t.exports = n("document", "documentElement");
    },
    function(t, e, r) {
      var n = r(5),
        i = r(148);
      t.exports =
        Object.setPrototypeOf ||
        ("__proto__" in {}
          ? (function() {
              var t,
                e = !1,
                r = {};
              try {
                (t = Object.getOwnPropertyDescriptor(
                  Object.prototype,
                  "__proto__"
                ).set).call(r, []),
                  (e = r instanceof Array);
              } catch (t) {}
              return function(r, o) {
                return n(r), i(o), e ? t.call(r, o) : (r.__proto__ = o), r;
              };
            })()
          : void 0);
    },
    function(t, e, r) {
      var n = r(1);
      t.exports = n.Promise;
    },
    function(t, e, r) {
      var n = r(18);
      t.exports = function(t, e, r) {
        if ((n(t), void 0 === e)) return t;
        switch (r) {
          case 0:
            return function() {
              return t.call(e);
            };
          case 1:
            return function(r) {
              return t.call(e, r);
            };
          case 2:
            return function(r, n) {
              return t.call(e, r, n);
            };
          case 3:
            return function(r, n, i) {
              return t.call(e, r, n, i);
            };
        }
        return function() {
          return t.apply(e, arguments);
        };
      };
    },
    function(t, e, r) {
      var n = r(5),
        i = r(18),
        o = r(3)("species");
      t.exports = function(t, e) {
        var r,
          s = n(t).constructor;
        return void 0 === s || null == (r = n(s)[o]) ? e : i(r);
      };
    },
    function(t, e, r) {
      var n,
        i,
        o,
        s = r(1),
        a = r(9),
        c = r(23),
        u = r(92),
        f = r(89),
        l = r(46),
        h = r(95),
        p = s.location,
        d = s.setImmediate,
        y = s.clearImmediate,
        v = s.process,
        b = s.MessageChannel,
        g = s.Dispatch,
        m = 0,
        w = {},
        _ = function(t) {
          if (w.hasOwnProperty(t)) {
            var e = w[t];
            delete w[t], e();
          }
        },
        S = function(t) {
          return function() {
            _(t);
          };
        },
        x = function(t) {
          _(t.data);
        },
        O = function(t) {
          s.postMessage(t + "", p.protocol + "//" + p.host);
        };
      (d && y) ||
        ((d = function(t) {
          for (var e = [], r = 1; arguments.length > r; )
            e.push(arguments[r++]);
          return (
            (w[++m] = function() {
              ("function" == typeof t ? t : Function(t)).apply(void 0, e);
            }),
            n(m),
            m
          );
        }),
        (y = function(t) {
          delete w[t];
        }),
        "process" == c(v)
          ? (n = function(t) {
              v.nextTick(S(t));
            })
          : g && g.now
          ? (n = function(t) {
              g.now(S(t));
            })
          : b && !h
          ? ((o = (i = new b()).port2),
            (i.port1.onmessage = x),
            (n = u(o.postMessage, o, 1)))
          : !s.addEventListener ||
            "function" != typeof postMessage ||
            s.importScripts ||
            a(O)
          ? (n =
              "onreadystatechange" in l("script")
                ? function(t) {
                    f.appendChild(l("script")).onreadystatechange = function() {
                      f.removeChild(this), _(t);
                    };
                  }
                : function(t) {
                    setTimeout(S(t), 0);
                  })
          : ((n = O), s.addEventListener("message", x, !1))),
        (t.exports = { set: d, clear: y });
    },
    function(t, e, r) {
      var n = r(96);
      t.exports = /(iphone|ipod|ipad).*applewebkit/i.test(n);
    },
    function(t, e, r) {
      var n = r(14);
      t.exports = n("navigator", "userAgent") || "";
    },
    function(t, e, r) {
      var n = r(5),
        i = r(12),
        o = r(25);
      t.exports = function(t, e) {
        if ((n(t), i(e) && e.constructor === t)) return e;
        var r = o.f(t);
        return (0, r.resolve)(e), r.promise;
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(13),
        i = r(18),
        o = r(25),
        s = r(33),
        a = r(32);
      n(
        { target: "Promise", stat: !0 },
        {
          allSettled: function(t) {
            var e = this,
              r = o.f(e),
              n = r.resolve,
              c = r.reject,
              u = s(function() {
                var r = i(e.resolve),
                  o = [],
                  s = 0,
                  c = 1;
                a(t, function(t) {
                  var i = s++,
                    a = !1;
                  o.push(void 0),
                    c++,
                    r.call(e, t).then(
                      function(t) {
                        a ||
                          ((a = !0),
                          (o[i] = { status: "fulfilled", value: t }),
                          --c || n(o));
                      },
                      function(t) {
                        a ||
                          ((a = !0),
                          (o[i] = { status: "rejected", reason: t }),
                          --c || n(o));
                      }
                    );
                }),
                  --c || n(o);
              });
            return u.error && c(u.value), r.promise;
          }
        }
      );
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(34),
        o = r(103),
        s = (function(t) {
          function e(e) {
            t.call(this), (this._value = e);
          }
          return (
            n(e, t),
            Object.defineProperty(e.prototype, "value", {
              get: function() {
                return this.getValue();
              },
              enumerable: !0,
              configurable: !0
            }),
            (e.prototype._subscribe = function(e) {
              var r = t.prototype._subscribe.call(this, e);
              return r && !r.closed && e.next(this._value), r;
            }),
            (e.prototype.getValue = function() {
              if (this.hasError) throw this.thrownError;
              if (this.closed) throw new o.ObjectUnsubscribedError();
              return this._value;
            }),
            (e.prototype.next = function(e) {
              t.prototype.next.call(this, (this._value = e));
            }),
            e
          );
        })(i.Subject);
      e.BehaviorSubject = s;
    },
    function(t, e, r) {
      "use strict";
      e.isFunction = function(t) {
        return "function" == typeof t;
      };
    },
    function(t, e, r) {
      "use strict";
      e.isObject = function(t) {
        return null != t && "object" == typeof t;
      };
    },
    function(t, e, r) {
      "use strict";
      e.empty = {
        closed: !0,
        next: function(t) {},
        error: function(t) {
          throw t;
        },
        complete: function() {}
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e() {
            var e = t.call(this, "object unsubscribed");
            (this.name = e.name = "ObjectUnsubscribedError"),
              (this.stack = e.stack),
              (this.message = e.message);
          }
          return n(e, t), e;
        })(Error);
      e.ObjectUnsubscribedError = i;
    },
    function(t, e, r) {
      "use strict";
      e.isArrayLike = function(t) {
        return t && "number" == typeof t.length;
      };
    },
    function(t, e, r) {
      "use strict";
      e.isPromise = function(t) {
        return (
          t && "function" != typeof t.subscribe && "function" == typeof t.then
        );
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e, r) {
            t.call(this),
              (this.value = e),
              (this.scheduler = r),
              (this._isScalar = !0),
              r && (this._isScalar = !1);
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              return new e(t, r);
            }),
            (e.dispatch = function(t) {
              var e = t.done,
                r = t.value,
                n = t.subscriber;
              e
                ? n.complete()
                : (n.next(r), n.closed || ((t.done = !0), this.schedule(t)));
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.value,
                n = this.scheduler;
              if (n)
                return n.schedule(e.dispatch, 0, {
                  done: !1,
                  value: r,
                  subscriber: t
                });
              t.next(r), t.closed || t.complete();
            }),
            e
          );
        })(r(0).Observable);
      e.ScalarObservable = i;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = (function() {
          function t(t, e, r) {
            (this.kind = t),
              (this.value = e),
              (this.error = r),
              (this.hasValue = "N" === t);
          }
          return (
            (t.prototype.observe = function(t) {
              switch (this.kind) {
                case "N":
                  return t.next && t.next(this.value);
                case "E":
                  return t.error && t.error(this.error);
                case "C":
                  return t.complete && t.complete();
              }
            }),
            (t.prototype.do = function(t, e, r) {
              switch (this.kind) {
                case "N":
                  return t && t(this.value);
                case "E":
                  return e && e(this.error);
                case "C":
                  return r && r();
              }
            }),
            (t.prototype.accept = function(t, e, r) {
              return t && "function" == typeof t.next
                ? this.observe(t)
                : this.do(t, e, r);
            }),
            (t.prototype.toObservable = function() {
              switch (this.kind) {
                case "N":
                  return n.Observable.of(this.value);
                case "E":
                  return n.Observable.throw(this.error);
                case "C":
                  return n.Observable.empty();
              }
              throw new Error("unexpected notification kind value");
            }),
            (t.createNext = function(e) {
              return void 0 !== e
                ? new t("N", e)
                : t.undefinedValueNotification;
            }),
            (t.createError = function(e) {
              return new t("E", void 0, e);
            }),
            (t.createComplete = function() {
              return t.completeNotification;
            }),
            (t.completeNotification = new t("C")),
            (t.undefinedValueNotification = new t("N", void 0)),
            t
          );
        })();
      e.Notification = i;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2);
      e.map = function(t, e) {
        return function(r) {
          if ("function" != typeof t)
            throw new TypeError(
              "argument is not a function. Are you looking for `mapTo()`?"
            );
          return r.lift(new o(t, e));
        };
      };
      var o = (function() {
        function t(t, e) {
          (this.project = t), (this.thisArg = e);
        }
        return (
          (t.prototype.call = function(t, e) {
            return e.subscribe(new s(t, this.project, this.thisArg));
          }),
          t
        );
      })();
      e.MapOperator = o;
      var s = (function(t) {
        function e(e, r, n) {
          t.call(this, e),
            (this.project = r),
            (this.count = 0),
            (this.thisArg = n || this);
        }
        return (
          n(e, t),
          (e.prototype._next = function(t) {
            var e;
            try {
              e = this.project.call(this.thisArg, t, this.count++);
            } catch (t) {
              return void this.destination.error(t);
            }
            this.destination.next(e);
          }),
          e
        );
      })(i.Subscriber);
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(39),
        o = r(38);
      e.mergeMap = function(t, e, r) {
        return (
          void 0 === r && (r = Number.POSITIVE_INFINITY),
          function(n) {
            return (
              "number" == typeof e && ((r = e), (e = null)),
              n.lift(new s(t, e, r))
            );
          }
        );
      };
      var s = (function() {
        function t(t, e, r) {
          void 0 === r && (r = Number.POSITIVE_INFINITY),
            (this.project = t),
            (this.resultSelector = e),
            (this.concurrent = r);
        }
        return (
          (t.prototype.call = function(t, e) {
            return e.subscribe(
              new a(t, this.project, this.resultSelector, this.concurrent)
            );
          }),
          t
        );
      })();
      e.MergeMapOperator = s;
      var a = (function(t) {
        function e(e, r, n, i) {
          void 0 === i && (i = Number.POSITIVE_INFINITY),
            t.call(this, e),
            (this.project = r),
            (this.resultSelector = n),
            (this.concurrent = i),
            (this.hasCompleted = !1),
            (this.buffer = []),
            (this.active = 0),
            (this.index = 0);
        }
        return (
          n(e, t),
          (e.prototype._next = function(t) {
            this.active < this.concurrent
              ? this._tryNext(t)
              : this.buffer.push(t);
          }),
          (e.prototype._tryNext = function(t) {
            var e,
              r = this.index++;
            try {
              e = this.project(t, r);
            } catch (t) {
              return void this.destination.error(t);
            }
            this.active++, this._innerSub(e, t, r);
          }),
          (e.prototype._innerSub = function(t, e, r) {
            this.add(i.subscribeToResult(this, t, e, r));
          }),
          (e.prototype._complete = function() {
            (this.hasCompleted = !0),
              0 === this.active &&
                0 === this.buffer.length &&
                this.destination.complete();
          }),
          (e.prototype.notifyNext = function(t, e, r, n, i) {
            this.resultSelector
              ? this._notifyResultSelector(t, e, r, n)
              : this.destination.next(e);
          }),
          (e.prototype._notifyResultSelector = function(t, e, r, n) {
            var i;
            try {
              i = this.resultSelector(t, e, r, n);
            } catch (t) {
              return void this.destination.error(t);
            }
            this.destination.next(i);
          }),
          (e.prototype.notifyComplete = function(t) {
            var e = this.buffer;
            this.remove(t),
              this.active--,
              e.length > 0
                ? this._next(e.shift())
                : 0 === this.active &&
                  this.hasCompleted &&
                  this.destination.complete();
          }),
          e
        );
      })(o.OuterSubscriber);
      e.MergeMapSubscriber = a;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2);
      e.refCount = function() {
        return function(t) {
          return t.lift(new o(t));
        };
      };
      var o = (function() {
          function t(t) {
            this.connectable = t;
          }
          return (
            (t.prototype.call = function(t, e) {
              var r = this.connectable;
              r._refCount++;
              var n = new s(t, r),
                i = e.subscribe(n);
              return n.closed || (n.connection = r.connect()), i;
            }),
            t
          );
        })(),
        s = (function(t) {
          function e(e, r) {
            t.call(this, e), (this.connectable = r);
          }
          return (
            n(e, t),
            (e.prototype._unsubscribe = function() {
              var t = this.connectable;
              if (t) {
                this.connectable = null;
                var e = t._refCount;
                if (e <= 0) this.connection = null;
                else if (((t._refCount = e - 1), e > 1)) this.connection = null;
                else {
                  var r = this.connection,
                    n = t._connection;
                  (this.connection = null),
                    !n || (r && n !== r) || n.unsubscribe();
                }
              } else this.connection = null;
            }),
            e
          );
        })(i.Subscriber);
    },
    function(t, e) {
      t.exports = function(t) {
        if (
          Symbol.iterator in Object(t) ||
          "[object Arguments]" === Object.prototype.toString.call(t)
        )
          return Array.from(t);
      };
    },
    function(t, e) {
      var r = {}.toString;
      t.exports =
        Array.isArray ||
        function(t) {
          return "[object Array]" == r.call(t);
        };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = (function() {
        function t(t, e) {
          if (!e) throw Error("Null Argument Exception");
          (this.id = t), (this.assembler = e);
        }
        return (
          Object.defineProperty(t.prototype, "contentType", {
            get: function() {
              return this.assembler.payloadType;
            },
            enumerable: !0,
            configurable: !0
          }),
          Object.defineProperty(t.prototype, "length", {
            get: function() {
              return this.assembler.contentLength;
            },
            enumerable: !0,
            configurable: !0
          }),
          (t.prototype.getStream = function() {
            return (
              this.stream || (this.stream = this.assembler.getPayloadStream()),
              this.stream
            );
          }),
          (t.prototype.cancel = function() {
            this.assembler.close();
          }),
          (t.prototype.readAsString = function() {
            return n(this, void 0, void 0, function() {
              return i(this, function(t) {
                switch (t.label) {
                  case 0:
                    return [4, this.readAll()];
                  case 1:
                    return [
                      2,
                      (t.sent().bufferArray || [])
                        .map(function(t) {
                          return t.toString("utf8");
                        })
                        .join("")
                    ];
                }
              });
            });
          }),
          (t.prototype.readAsJson = function() {
            return n(this, void 0, void 0, function() {
              var t;
              return i(this, function(e) {
                switch (e.label) {
                  case 0:
                    return [4, this.readAsString()];
                  case 1:
                    t = e.sent();
                    try {
                      return [2, JSON.parse(t)];
                    } catch (t) {
                      throw t;
                    }
                    return [2];
                }
              });
            });
          }),
          (t.prototype.readAll = function() {
            return n(this, void 0, void 0, function() {
              var t,
                e,
                r,
                n,
                o = this;
              return i(this, function(i) {
                switch (i.label) {
                  case 0:
                    for (t = [], e = 0, r = this.getStream(); e < r.length; )
                      (n = r.read(r.length)), t.push(n), (e += n.length);
                    return e < this.length
                      ? [
                          4,
                          new Promise(function(n) {
                            var i;
                            r.subscribe(
                              ((i = o),
                              function(r) {
                                t.push(r),
                                  (e += r.length) === i.length && n(!0);
                              })
                            );
                          })
                        ]
                      : [3, 2];
                  case 1:
                    i.sent(), (i.label = 2);
                  case 2:
                    return [2, { bufferArray: t, size: e }];
                }
              });
            });
          }),
          t
        );
      })();
      e.ContentStream = o;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(256);
      e.generateGuid = function() {
        return n();
      };
    },
    function(t, e, r) {
      "use strict";
      (function(e, n) {
        var i = r(42);
        t.exports = m;
        var o,
          s = r(112);
        m.ReadableState = g;
        r(63).EventEmitter;
        var a = function(t, e) {
            return t.listeners(e).length;
          },
          c = r(116),
          u = r(43).Buffer,
          f = e.Uint8Array || function() {};
        var l = r(28);
        l.inherits = r(20);
        var h = r(262),
          p = void 0;
        p = h && h.debuglog ? h.debuglog("stream") : function() {};
        var d,
          y = r(263),
          v = r(117);
        l.inherits(m, c);
        var b = ["error", "close", "destroy", "pause", "resume"];
        function g(t, e) {
          t = t || {};
          var n = e instanceof (o = o || r(15));
          (this.objectMode = !!t.objectMode),
            n && (this.objectMode = this.objectMode || !!t.readableObjectMode);
          var i = t.highWaterMark,
            s = t.readableHighWaterMark,
            a = this.objectMode ? 16 : 16384;
          (this.highWaterMark = i || 0 === i ? i : n && (s || 0 === s) ? s : a),
            (this.highWaterMark = Math.floor(this.highWaterMark)),
            (this.buffer = new y()),
            (this.length = 0),
            (this.pipes = null),
            (this.pipesCount = 0),
            (this.flowing = null),
            (this.ended = !1),
            (this.endEmitted = !1),
            (this.reading = !1),
            (this.sync = !0),
            (this.needReadable = !1),
            (this.emittedReadable = !1),
            (this.readableListening = !1),
            (this.resumeScheduled = !1),
            (this.destroyed = !1),
            (this.defaultEncoding = t.defaultEncoding || "utf8"),
            (this.awaitDrain = 0),
            (this.readingMore = !1),
            (this.decoder = null),
            (this.encoding = null),
            t.encoding &&
              (d || (d = r(118).StringDecoder),
              (this.decoder = new d(t.encoding)),
              (this.encoding = t.encoding));
        }
        function m(t) {
          if (((o = o || r(15)), !(this instanceof m))) return new m(t);
          (this._readableState = new g(t, this)),
            (this.readable = !0),
            t &&
              ("function" == typeof t.read && (this._read = t.read),
              "function" == typeof t.destroy && (this._destroy = t.destroy)),
            c.call(this);
        }
        function w(t, e, r, n, i) {
          var o,
            s = t._readableState;
          null === e
            ? ((s.reading = !1),
              (function(t, e) {
                if (e.ended) return;
                if (e.decoder) {
                  var r = e.decoder.end();
                  r &&
                    r.length &&
                    (e.buffer.push(r),
                    (e.length += e.objectMode ? 1 : r.length));
                }
                (e.ended = !0), x(t);
              })(t, s))
            : (i ||
                (o = (function(t, e) {
                  var r;
                  (n = e),
                    u.isBuffer(n) ||
                      n instanceof f ||
                      "string" == typeof e ||
                      void 0 === e ||
                      t.objectMode ||
                      (r = new TypeError("Invalid non-string/buffer chunk"));
                  var n;
                  return r;
                })(s, e)),
              o
                ? t.emit("error", o)
                : s.objectMode || (e && e.length > 0)
                ? ("string" == typeof e ||
                    s.objectMode ||
                    Object.getPrototypeOf(e) === u.prototype ||
                    (e = (function(t) {
                      return u.from(t);
                    })(e)),
                  n
                    ? s.endEmitted
                      ? t.emit(
                          "error",
                          new Error("stream.unshift() after end event")
                        )
                      : _(t, s, e, !0)
                    : s.ended
                    ? t.emit("error", new Error("stream.push() after EOF"))
                    : ((s.reading = !1),
                      s.decoder && !r
                        ? ((e = s.decoder.write(e)),
                          s.objectMode || 0 !== e.length
                            ? _(t, s, e, !1)
                            : E(t, s))
                        : _(t, s, e, !1)))
                : n || (s.reading = !1));
          return (function(t) {
            return (
              !t.ended &&
              (t.needReadable || t.length < t.highWaterMark || 0 === t.length)
            );
          })(s);
        }
        function _(t, e, r, n) {
          e.flowing && 0 === e.length && !e.sync
            ? (t.emit("data", r), t.read(0))
            : ((e.length += e.objectMode ? 1 : r.length),
              n ? e.buffer.unshift(r) : e.buffer.push(r),
              e.needReadable && x(t)),
            E(t, e);
        }
        Object.defineProperty(m.prototype, "destroyed", {
          get: function() {
            return (
              void 0 !== this._readableState && this._readableState.destroyed
            );
          },
          set: function(t) {
            this._readableState && (this._readableState.destroyed = t);
          }
        }),
          (m.prototype.destroy = v.destroy),
          (m.prototype._undestroy = v.undestroy),
          (m.prototype._destroy = function(t, e) {
            this.push(null), e(t);
          }),
          (m.prototype.push = function(t, e) {
            var r,
              n = this._readableState;
            return (
              n.objectMode
                ? (r = !0)
                : "string" == typeof t &&
                  ((e = e || n.defaultEncoding) !== n.encoding &&
                    ((t = u.from(t, e)), (e = "")),
                  (r = !0)),
              w(this, t, e, !1, r)
            );
          }),
          (m.prototype.unshift = function(t) {
            return w(this, t, null, !0, !1);
          }),
          (m.prototype.isPaused = function() {
            return !1 === this._readableState.flowing;
          }),
          (m.prototype.setEncoding = function(t) {
            return (
              d || (d = r(118).StringDecoder),
              (this._readableState.decoder = new d(t)),
              (this._readableState.encoding = t),
              this
            );
          });
        function S(t, e) {
          return t <= 0 || (0 === e.length && e.ended)
            ? 0
            : e.objectMode
            ? 1
            : t != t
            ? e.flowing && e.length
              ? e.buffer.head.data.length
              : e.length
            : (t > e.highWaterMark &&
                (e.highWaterMark = (function(t) {
                  return (
                    t >= 8388608
                      ? (t = 8388608)
                      : (t--,
                        (t |= t >>> 1),
                        (t |= t >>> 2),
                        (t |= t >>> 4),
                        (t |= t >>> 8),
                        (t |= t >>> 16),
                        t++),
                    t
                  );
                })(t)),
              t <= e.length
                ? t
                : e.ended
                ? e.length
                : ((e.needReadable = !0), 0));
        }
        function x(t) {
          var e = t._readableState;
          (e.needReadable = !1),
            e.emittedReadable ||
              (p("emitReadable", e.flowing),
              (e.emittedReadable = !0),
              e.sync ? i.nextTick(O, t) : O(t));
        }
        function O(t) {
          p("emit readable"), t.emit("readable"), j(t);
        }
        function E(t, e) {
          e.readingMore || ((e.readingMore = !0), i.nextTick(k, t, e));
        }
        function k(t, e) {
          for (
            var r = e.length;
            !e.reading &&
            !e.flowing &&
            !e.ended &&
            e.length < e.highWaterMark &&
            (p("maybeReadMore read 0"), t.read(0), r !== e.length);

          )
            r = e.length;
          e.readingMore = !1;
        }
        function P(t) {
          p("readable nexttick read 0"), t.read(0);
        }
        function T(t, e) {
          e.reading || (p("resume read 0"), t.read(0)),
            (e.resumeScheduled = !1),
            (e.awaitDrain = 0),
            t.emit("resume"),
            j(t),
            e.flowing && !e.reading && t.read(0);
        }
        function j(t) {
          var e = t._readableState;
          for (p("flow", e.flowing); e.flowing && null !== t.read(); );
        }
        function A(t, e) {
          return 0 === e.length
            ? null
            : (e.objectMode
                ? (r = e.buffer.shift())
                : !t || t >= e.length
                ? ((r = e.decoder
                    ? e.buffer.join("")
                    : 1 === e.buffer.length
                    ? e.buffer.head.data
                    : e.buffer.concat(e.length)),
                  e.buffer.clear())
                : (r = (function(t, e, r) {
                    var n;
                    t < e.head.data.length
                      ? ((n = e.head.data.slice(0, t)),
                        (e.head.data = e.head.data.slice(t)))
                      : (n =
                          t === e.head.data.length
                            ? e.shift()
                            : r
                            ? (function(t, e) {
                                var r = e.head,
                                  n = 1,
                                  i = r.data;
                                t -= i.length;
                                for (; (r = r.next); ) {
                                  var o = r.data,
                                    s = t > o.length ? o.length : t;
                                  if (
                                    (s === o.length
                                      ? (i += o)
                                      : (i += o.slice(0, t)),
                                    0 === (t -= s))
                                  ) {
                                    s === o.length
                                      ? (++n,
                                        r.next
                                          ? (e.head = r.next)
                                          : (e.head = e.tail = null))
                                      : ((e.head = r), (r.data = o.slice(s)));
                                    break;
                                  }
                                  ++n;
                                }
                                return (e.length -= n), i;
                              })(t, e)
                            : (function(t, e) {
                                var r = u.allocUnsafe(t),
                                  n = e.head,
                                  i = 1;
                                n.data.copy(r), (t -= n.data.length);
                                for (; (n = n.next); ) {
                                  var o = n.data,
                                    s = t > o.length ? o.length : t;
                                  if (
                                    (o.copy(r, r.length - t, 0, s),
                                    0 === (t -= s))
                                  ) {
                                    s === o.length
                                      ? (++i,
                                        n.next
                                          ? (e.head = n.next)
                                          : (e.head = e.tail = null))
                                      : ((e.head = n), (n.data = o.slice(s)));
                                    break;
                                  }
                                  ++i;
                                }
                                return (e.length -= i), r;
                              })(t, e));
                    return n;
                  })(t, e.buffer, e.decoder)),
              r);
          var r;
        }
        function R(t) {
          var e = t._readableState;
          if (e.length > 0)
            throw new Error('"endReadable()" called on non-empty stream');
          e.endEmitted || ((e.ended = !0), i.nextTick(C, e, t));
        }
        function C(t, e) {
          t.endEmitted ||
            0 !== t.length ||
            ((t.endEmitted = !0), (e.readable = !1), e.emit("end"));
        }
        function M(t, e) {
          for (var r = 0, n = t.length; r < n; r++) if (t[r] === e) return r;
          return -1;
        }
        (m.prototype.read = function(t) {
          p("read", t), (t = parseInt(t, 10));
          var e = this._readableState,
            r = t;
          if (
            (0 !== t && (e.emittedReadable = !1),
            0 === t &&
              e.needReadable &&
              (e.length >= e.highWaterMark || e.ended))
          )
            return (
              p("read: emitReadable", e.length, e.ended),
              0 === e.length && e.ended ? R(this) : x(this),
              null
            );
          if (0 === (t = S(t, e)) && e.ended)
            return 0 === e.length && R(this), null;
          var n,
            i = e.needReadable;
          return (
            p("need readable", i),
            (0 === e.length || e.length - t < e.highWaterMark) &&
              p("length less than watermark", (i = !0)),
            e.ended || e.reading
              ? p("reading or ended", (i = !1))
              : i &&
                (p("do read"),
                (e.reading = !0),
                (e.sync = !0),
                0 === e.length && (e.needReadable = !0),
                this._read(e.highWaterMark),
                (e.sync = !1),
                e.reading || (t = S(r, e))),
            null === (n = t > 0 ? A(t, e) : null)
              ? ((e.needReadable = !0), (t = 0))
              : (e.length -= t),
            0 === e.length &&
              (e.ended || (e.needReadable = !0), r !== t && e.ended && R(this)),
            null !== n && this.emit("data", n),
            n
          );
        }),
          (m.prototype._read = function(t) {
            this.emit("error", new Error("_read() is not implemented"));
          }),
          (m.prototype.pipe = function(t, e) {
            var r = this,
              o = this._readableState;
            switch (o.pipesCount) {
              case 0:
                o.pipes = t;
                break;
              case 1:
                o.pipes = [o.pipes, t];
                break;
              default:
                o.pipes.push(t);
            }
            (o.pipesCount += 1), p("pipe count=%d opts=%j", o.pipesCount, e);
            var c =
              (!e || !1 !== e.end) && t !== n.stdout && t !== n.stderr ? f : m;
            function u(e, n) {
              p("onunpipe"),
                e === r &&
                  n &&
                  !1 === n.hasUnpiped &&
                  ((n.hasUnpiped = !0),
                  p("cleanup"),
                  t.removeListener("close", b),
                  t.removeListener("finish", g),
                  t.removeListener("drain", l),
                  t.removeListener("error", v),
                  t.removeListener("unpipe", u),
                  r.removeListener("end", f),
                  r.removeListener("end", m),
                  r.removeListener("data", y),
                  (h = !0),
                  !o.awaitDrain ||
                    (t._writableState && !t._writableState.needDrain) ||
                    l());
            }
            function f() {
              p("onend"), t.end();
            }
            o.endEmitted ? i.nextTick(c) : r.once("end", c), t.on("unpipe", u);
            var l = (function(t) {
              return function() {
                var e = t._readableState;
                p("pipeOnDrain", e.awaitDrain),
                  e.awaitDrain && e.awaitDrain--,
                  0 === e.awaitDrain &&
                    a(t, "data") &&
                    ((e.flowing = !0), j(t));
              };
            })(r);
            t.on("drain", l);
            var h = !1;
            var d = !1;
            function y(e) {
              p("ondata"),
                (d = !1),
                !1 !== t.write(e) ||
                  d ||
                  (((1 === o.pipesCount && o.pipes === t) ||
                    (o.pipesCount > 1 && -1 !== M(o.pipes, t))) &&
                    !h &&
                    (p(
                      "false write response, pause",
                      r._readableState.awaitDrain
                    ),
                    r._readableState.awaitDrain++,
                    (d = !0)),
                  r.pause());
            }
            function v(e) {
              p("onerror", e),
                m(),
                t.removeListener("error", v),
                0 === a(t, "error") && t.emit("error", e);
            }
            function b() {
              t.removeListener("finish", g), m();
            }
            function g() {
              p("onfinish"), t.removeListener("close", b), m();
            }
            function m() {
              p("unpipe"), r.unpipe(t);
            }
            return (
              r.on("data", y),
              (function(t, e, r) {
                if ("function" == typeof t.prependListener)
                  return t.prependListener(e, r);
                t._events && t._events[e]
                  ? s(t._events[e])
                    ? t._events[e].unshift(r)
                    : (t._events[e] = [r, t._events[e]])
                  : t.on(e, r);
              })(t, "error", v),
              t.once("close", b),
              t.once("finish", g),
              t.emit("pipe", r),
              o.flowing || (p("pipe resume"), r.resume()),
              t
            );
          }),
          (m.prototype.unpipe = function(t) {
            var e = this._readableState,
              r = { hasUnpiped: !1 };
            if (0 === e.pipesCount) return this;
            if (1 === e.pipesCount)
              return t && t !== e.pipes
                ? this
                : (t || (t = e.pipes),
                  (e.pipes = null),
                  (e.pipesCount = 0),
                  (e.flowing = !1),
                  t && t.emit("unpipe", this, r),
                  this);
            if (!t) {
              var n = e.pipes,
                i = e.pipesCount;
              (e.pipes = null), (e.pipesCount = 0), (e.flowing = !1);
              for (var o = 0; o < i; o++) n[o].emit("unpipe", this, r);
              return this;
            }
            var s = M(e.pipes, t);
            return -1 === s
              ? this
              : (e.pipes.splice(s, 1),
                (e.pipesCount -= 1),
                1 === e.pipesCount && (e.pipes = e.pipes[0]),
                t.emit("unpipe", this, r),
                this);
          }),
          (m.prototype.on = function(t, e) {
            var r = c.prototype.on.call(this, t, e);
            if ("data" === t)
              !1 !== this._readableState.flowing && this.resume();
            else if ("readable" === t) {
              var n = this._readableState;
              n.endEmitted ||
                n.readableListening ||
                ((n.readableListening = n.needReadable = !0),
                (n.emittedReadable = !1),
                n.reading ? n.length && x(this) : i.nextTick(P, this));
            }
            return r;
          }),
          (m.prototype.addListener = m.prototype.on),
          (m.prototype.resume = function() {
            var t = this._readableState;
            return (
              t.flowing ||
                (p("resume"),
                (t.flowing = !0),
                (function(t, e) {
                  e.resumeScheduled ||
                    ((e.resumeScheduled = !0), i.nextTick(T, t, e));
                })(this, t)),
              this
            );
          }),
          (m.prototype.pause = function() {
            return (
              p("call pause flowing=%j", this._readableState.flowing),
              !1 !== this._readableState.flowing &&
                (p("pause"),
                (this._readableState.flowing = !1),
                this.emit("pause")),
              this
            );
          }),
          (m.prototype.wrap = function(t) {
            var e = this,
              r = this._readableState,
              n = !1;
            for (var i in (t.on("end", function() {
              if ((p("wrapped end"), r.decoder && !r.ended)) {
                var t = r.decoder.end();
                t && t.length && e.push(t);
              }
              e.push(null);
            }),
            t.on("data", function(i) {
              (p("wrapped data"),
              r.decoder && (i = r.decoder.write(i)),
              r.objectMode && null == i) ||
                ((r.objectMode || (i && i.length)) &&
                  (e.push(i) || ((n = !0), t.pause())));
            }),
            t))
              void 0 === this[i] &&
                "function" == typeof t[i] &&
                (this[i] = (function(e) {
                  return function() {
                    return t[e].apply(t, arguments);
                  };
                })(i));
            for (var o = 0; o < b.length; o++)
              t.on(b[o], this.emit.bind(this, b[o]));
            return (
              (this._read = function(e) {
                p("wrapped _read", e), n && ((n = !1), t.resume());
              }),
              this
            );
          }),
          Object.defineProperty(m.prototype, "readableHighWaterMark", {
            enumerable: !1,
            get: function() {
              return this._readableState.highWaterMark;
            }
          }),
          (m._fromList = A);
      }.call(this, r(4), r(41)));
    },
    function(t, e, r) {
      t.exports = r(63).EventEmitter;
    },
    function(t, e, r) {
      "use strict";
      var n = r(42);
      function i(t, e) {
        t.emit("error", e);
      }
      t.exports = {
        destroy: function(t, e) {
          var r = this,
            o = this._readableState && this._readableState.destroyed,
            s = this._writableState && this._writableState.destroyed;
          return o || s
            ? (e
                ? e(t)
                : !t ||
                  (this._writableState && this._writableState.errorEmitted) ||
                  n.nextTick(i, this, t),
              this)
            : (this._readableState && (this._readableState.destroyed = !0),
              this._writableState && (this._writableState.destroyed = !0),
              this._destroy(t || null, function(t) {
                !e && t
                  ? (n.nextTick(i, r, t),
                    r._writableState && (r._writableState.errorEmitted = !0))
                  : e && e(t);
              }),
              this);
        },
        undestroy: function() {
          this._readableState &&
            ((this._readableState.destroyed = !1),
            (this._readableState.reading = !1),
            (this._readableState.ended = !1),
            (this._readableState.endEmitted = !1)),
            this._writableState &&
              ((this._writableState.destroyed = !1),
              (this._writableState.ended = !1),
              (this._writableState.ending = !1),
              (this._writableState.finished = !1),
              (this._writableState.errorEmitted = !1));
        }
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(43).Buffer,
        i =
          n.isEncoding ||
          function(t) {
            switch ((t = "" + t) && t.toLowerCase()) {
              case "hex":
              case "utf8":
              case "utf-8":
              case "ascii":
              case "binary":
              case "base64":
              case "ucs2":
              case "ucs-2":
              case "utf16le":
              case "utf-16le":
              case "raw":
                return !0;
              default:
                return !1;
            }
          };
      function o(t) {
        var e;
        switch (
          ((this.encoding = (function(t) {
            var e = (function(t) {
              if (!t) return "utf8";
              for (var e; ; )
                switch (t) {
                  case "utf8":
                  case "utf-8":
                    return "utf8";
                  case "ucs2":
                  case "ucs-2":
                  case "utf16le":
                  case "utf-16le":
                    return "utf16le";
                  case "latin1":
                  case "binary":
                    return "latin1";
                  case "base64":
                  case "ascii":
                  case "hex":
                    return t;
                  default:
                    if (e) return;
                    (t = ("" + t).toLowerCase()), (e = !0);
                }
            })(t);
            if ("string" != typeof e && (n.isEncoding === i || !i(t)))
              throw new Error("Unknown encoding: " + t);
            return e || t;
          })(t)),
          this.encoding)
        ) {
          case "utf16le":
            (this.text = c), (this.end = u), (e = 4);
            break;
          case "utf8":
            (this.fillLast = a), (e = 4);
            break;
          case "base64":
            (this.text = f), (this.end = l), (e = 3);
            break;
          default:
            return (this.write = h), void (this.end = p);
        }
        (this.lastNeed = 0),
          (this.lastTotal = 0),
          (this.lastChar = n.allocUnsafe(e));
      }
      function s(t) {
        return t <= 127
          ? 0
          : t >> 5 == 6
          ? 2
          : t >> 4 == 14
          ? 3
          : t >> 3 == 30
          ? 4
          : t >> 6 == 2
          ? -1
          : -2;
      }
      function a(t) {
        var e = this.lastTotal - this.lastNeed,
          r = (function(t, e, r) {
            if (128 != (192 & e[0])) return (t.lastNeed = 0), "�";
            if (t.lastNeed > 1 && e.length > 1) {
              if (128 != (192 & e[1])) return (t.lastNeed = 1), "�";
              if (t.lastNeed > 2 && e.length > 2 && 128 != (192 & e[2]))
                return (t.lastNeed = 2), "�";
            }
          })(this, t);
        return void 0 !== r
          ? r
          : this.lastNeed <= t.length
          ? (t.copy(this.lastChar, e, 0, this.lastNeed),
            this.lastChar.toString(this.encoding, 0, this.lastTotal))
          : (t.copy(this.lastChar, e, 0, t.length),
            void (this.lastNeed -= t.length));
      }
      function c(t, e) {
        if ((t.length - e) % 2 == 0) {
          var r = t.toString("utf16le", e);
          if (r) {
            var n = r.charCodeAt(r.length - 1);
            if (n >= 55296 && n <= 56319)
              return (
                (this.lastNeed = 2),
                (this.lastTotal = 4),
                (this.lastChar[0] = t[t.length - 2]),
                (this.lastChar[1] = t[t.length - 1]),
                r.slice(0, -1)
              );
          }
          return r;
        }
        return (
          (this.lastNeed = 1),
          (this.lastTotal = 2),
          (this.lastChar[0] = t[t.length - 1]),
          t.toString("utf16le", e, t.length - 1)
        );
      }
      function u(t) {
        var e = t && t.length ? this.write(t) : "";
        if (this.lastNeed) {
          var r = this.lastTotal - this.lastNeed;
          return e + this.lastChar.toString("utf16le", 0, r);
        }
        return e;
      }
      function f(t, e) {
        var r = (t.length - e) % 3;
        return 0 === r
          ? t.toString("base64", e)
          : ((this.lastNeed = 3 - r),
            (this.lastTotal = 3),
            1 === r
              ? (this.lastChar[0] = t[t.length - 1])
              : ((this.lastChar[0] = t[t.length - 2]),
                (this.lastChar[1] = t[t.length - 1])),
            t.toString("base64", e, t.length - r));
      }
      function l(t) {
        var e = t && t.length ? this.write(t) : "";
        return this.lastNeed
          ? e + this.lastChar.toString("base64", 0, 3 - this.lastNeed)
          : e;
      }
      function h(t) {
        return t.toString(this.encoding);
      }
      function p(t) {
        return t && t.length ? this.write(t) : "";
      }
      (e.StringDecoder = o),
        (o.prototype.write = function(t) {
          if (0 === t.length) return "";
          var e, r;
          if (this.lastNeed) {
            if (void 0 === (e = this.fillLast(t))) return "";
            (r = this.lastNeed), (this.lastNeed = 0);
          } else r = 0;
          return r < t.length
            ? e
              ? e + this.text(t, r)
              : this.text(t, r)
            : e || "";
        }),
        (o.prototype.end = function(t) {
          var e = t && t.length ? this.write(t) : "";
          return this.lastNeed ? e + "�" : e;
        }),
        (o.prototype.text = function(t, e) {
          var r = (function(t, e, r) {
            var n = e.length - 1;
            if (n < r) return 0;
            var i = s(e[n]);
            if (i >= 0) return i > 0 && (t.lastNeed = i - 1), i;
            if (--n < r || -2 === i) return 0;
            if ((i = s(e[n])) >= 0) return i > 0 && (t.lastNeed = i - 2), i;
            if (--n < r || -2 === i) return 0;
            if ((i = s(e[n])) >= 0)
              return i > 0 && (2 === i ? (i = 0) : (t.lastNeed = i - 3)), i;
            return 0;
          })(this, t, e);
          if (!this.lastNeed) return t.toString("utf8", e);
          this.lastTotal = r;
          var n = t.length - (r - this.lastNeed);
          return t.copy(this.lastChar, 0, n), t.toString("utf8", e, n);
        }),
        (o.prototype.fillLast = function(t) {
          if (this.lastNeed <= t.length)
            return (
              t.copy(
                this.lastChar,
                this.lastTotal - this.lastNeed,
                0,
                this.lastNeed
              ),
              this.lastChar.toString(this.encoding, 0, this.lastTotal)
            );
          t.copy(this.lastChar, this.lastTotal - this.lastNeed, 0, t.length),
            (this.lastNeed -= t.length);
        });
    },
    function(t, e, r) {
      "use strict";
      t.exports = s;
      var n = r(15),
        i = r(28);
      function o(t, e) {
        var r = this._transformState;
        r.transforming = !1;
        var n = r.writecb;
        if (!n)
          return this.emit(
            "error",
            new Error("write callback called multiple times")
          );
        (r.writechunk = null),
          (r.writecb = null),
          null != e && this.push(e),
          n(t);
        var i = this._readableState;
        (i.reading = !1),
          (i.needReadable || i.length < i.highWaterMark) &&
            this._read(i.highWaterMark);
      }
      function s(t) {
        if (!(this instanceof s)) return new s(t);
        n.call(this, t),
          (this._transformState = {
            afterTransform: o.bind(this),
            needTransform: !1,
            transforming: !1,
            writecb: null,
            writechunk: null,
            writeencoding: null
          }),
          (this._readableState.needReadable = !0),
          (this._readableState.sync = !1),
          t &&
            ("function" == typeof t.transform &&
              (this._transform = t.transform),
            "function" == typeof t.flush && (this._flush = t.flush)),
          this.on("prefinish", a);
      }
      function a() {
        var t = this;
        "function" == typeof this._flush
          ? this._flush(function(e, r) {
              c(t, e, r);
            })
          : c(this, null, null);
      }
      function c(t, e, r) {
        if (e) return t.emit("error", e);
        if ((null != r && t.push(r), t._writableState.length))
          throw new Error("Calling transform done when ws.length != 0");
        if (t._transformState.transforming)
          throw new Error("Calling transform done when still transforming");
        return t.push(null);
      }
      (i.inherits = r(20)),
        i.inherits(s, n),
        (s.prototype.push = function(t, e) {
          return (
            (this._transformState.needTransform = !1),
            n.prototype.push.call(this, t, e)
          );
        }),
        (s.prototype._transform = function(t, e, r) {
          throw new Error("_transform() is not implemented");
        }),
        (s.prototype._write = function(t, e, r) {
          var n = this._transformState;
          if (
            ((n.writecb = r),
            (n.writechunk = t),
            (n.writeencoding = e),
            !n.transforming)
          ) {
            var i = this._readableState;
            (n.needTransform || i.needReadable || i.length < i.highWaterMark) &&
              this._read(i.highWaterMark);
          }
        }),
        (s.prototype._read = function(t) {
          var e = this._transformState;
          null !== e.writechunk && e.writecb && !e.transforming
            ? ((e.transforming = !0),
              this._transform(e.writechunk, e.writeencoding, e.afterTransform))
            : (e.needTransform = !0);
        }),
        (s.prototype._destroy = function(t, e) {
          var r = this;
          n.prototype._destroy.call(this, t, function(t) {
            e(t), r.emit("close");
          });
        });
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = (function() {
        function t(t) {
          t && (this.webSocket = t);
        }
        return (
          (t.prototype.connect = function(t) {
            return n(this, void 0, void 0, function() {
              var e, r;
              return i(this, function(n) {
                return (
                  this.webSocket || (this.webSocket = new WebSocket(t)),
                  (this.webSocket.onerror = function(t) {
                    r(t);
                  }),
                  (this.webSocket.onopen = function(t) {
                    e(t);
                  }),
                  [
                    2,
                    new Promise(function(t, n) {
                      (e = t), (r = n);
                    })
                  ]
                );
              });
            });
          }),
          Object.defineProperty(t.prototype, "isConnected", {
            get: function() {
              return 1 === this.webSocket.readyState;
            },
            enumerable: !0,
            configurable: !0
          }),
          (t.prototype.write = function(t) {
            this.webSocket.send(t);
          }),
          (t.prototype.close = function() {
            this.webSocket.close();
          }),
          (t.prototype.setOnMessageHandler = function(t) {
            var e = [];
            this.webSocket.onmessage = function(r) {
              var n = new FileReader(),
                i = { buffer: null };
              e.push(i),
                (n.onload = function(r) {
                  var n = r.target;
                  if (((i.buffer = n.result), e[0] === i))
                    for (; 0 < e.length && e[0].buffer; )
                      t(e[0].buffer), e.splice(0, 1);
                }),
                n.readAsArrayBuffer(r.data);
            };
          }),
          (t.prototype.setOnErrorHandler = function(t) {
            this.webSocket.onerror = function(e) {
              e && t(e);
            };
          }),
          (t.prototype.setOnCloseHandler = function(t) {
            this.webSocket.onclose = t;
          }),
          t
        );
      })();
      e.BrowserWebSocket = o;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(122),
        i = r(16),
        o = (function() {
          function t(t, e, r) {
            (this.activeAssemblers = {}),
              (this.streamManager = t),
              (this.onReceiveRequest = r),
              (this.onReceiveResponse = e);
          }
          return (
            (t.prototype.getPayloadStream = function(t) {
              if (t.payloadType === i.PayloadTypes.stream)
                return this.streamManager.getPayloadStream(t);
              if (!this.activeAssemblers[t.id]) {
                var e = this.createPayloadAssembler(t);
                if (e)
                  return (
                    (this.activeAssemblers[t.id] = e), e.getPayloadStream()
                  );
              }
            }),
            (t.prototype.onReceive = function(t, e, r) {
              if (t.payloadType === i.PayloadTypes.stream)
                this.streamManager.onReceive(t, e, r);
              else {
                if (this.activeAssemblers && this.activeAssemblers[t.id])
                  this.activeAssemblers[t.id].onReceive(t, e, r);
                t.end && delete this.activeAssemblers[t.id];
              }
            }),
            (t.prototype.createPayloadAssembler = function(t) {
              return t.payloadType === i.PayloadTypes.request
                ? new n.PayloadAssembler(this.streamManager, {
                    header: t,
                    onCompleted: this.onReceiveRequest
                  })
                : t.payloadType === i.PayloadTypes.response
                ? new n.PayloadAssembler(this.streamManager, {
                    header: t,
                    onCompleted: this.onReceiveResponse
                  })
                : void 0;
            }),
            t
          );
        })();
      e.PayloadAssemblerManager = o;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = r(27),
        s = r(123),
        a = r(113),
        c = (function() {
          function t(t, e) {
            if (
              ((this._byteOrderMark = 65279),
              (this._utf = "utf8"),
              e.header
                ? ((this.id = e.header.id),
                  (this.payloadType = e.header.payloadType),
                  (this.contentLength = e.header.payloadLength),
                  (this.end = e.header.end))
                : (this.id = e.id),
              !this.id)
            )
              throw Error("An ID must be supplied when creating an assembler.");
            (this._streamManager = t), (this._onCompleted = e.onCompleted);
          }
          return (
            (t.prototype.getPayloadStream = function() {
              return (
                this.stream || (this.stream = this.createPayloadStream()),
                this.stream
              );
            }),
            (t.prototype.onReceive = function(t, e, r) {
              (this.end = t.end),
                t.payloadType === s.PayloadTypes.response ||
                t.payloadType === s.PayloadTypes.request
                  ? this.process(e)
                      .then()
                      .catch()
                  : t.end && e.end();
            }),
            (t.prototype.close = function() {
              this._streamManager.closeStream(this.id);
            }),
            (t.prototype.createPayloadStream = function() {
              return new o.SubscribableStream();
            }),
            (t.prototype.payloadFromJson = function(t) {
              return JSON.parse(
                t.charCodeAt(0) === this._byteOrderMark ? t.slice(1) : t
              );
            }),
            (t.prototype.stripBOM = function(t) {
              return t.charCodeAt(0) === this._byteOrderMark ? t.slice(1) : t;
            }),
            (t.prototype.process = function(t) {
              return n(this, void 0, void 0, function() {
                var e, r;
                return i(this, function(n) {
                  switch (n.label) {
                    case 0:
                      return (e = t.read(t.length))
                        ? ((r = e.toString(this._utf)),
                          this.payloadType !== s.PayloadTypes.request
                            ? [3, 2]
                            : [4, this.processRequest(r)])
                        : [2];
                    case 1:
                      return n.sent(), [3, 4];
                    case 2:
                      return this.payloadType !== s.PayloadTypes.response
                        ? [3, 4]
                        : [4, this.processResponse(r)];
                    case 3:
                      n.sent(), (n.label = 4);
                    case 4:
                      return [2];
                  }
                });
              });
            }),
            (t.prototype.processResponse = function(t) {
              return n(this, void 0, void 0, function() {
                var e, r;
                return i(this, function(n) {
                  switch (n.label) {
                    case 0:
                      return (
                        (e = this.payloadFromJson(this.stripBOM(t))),
                        (r = { streams: [], statusCode: e.statusCode }),
                        [4, this.processStreams(e, r)]
                      );
                    case 1:
                      return n.sent(), [2];
                  }
                });
              });
            }),
            (t.prototype.processRequest = function(t) {
              return n(this, void 0, void 0, function() {
                var e, r;
                return i(this, function(n) {
                  switch (n.label) {
                    case 0:
                      return (
                        (e = this.payloadFromJson(t)),
                        (r = { streams: [], path: e.path, verb: e.verb }),
                        [4, this.processStreams(e, r)]
                      );
                    case 1:
                      return n.sent(), [2];
                  }
                });
              });
            }),
            (t.prototype.processStreams = function(t, e) {
              return n(this, void 0, void 0, function() {
                var r = this;
                return i(this, function(n) {
                  switch (n.label) {
                    case 0:
                      return (
                        t.streams &&
                          t.streams.forEach(function(t) {
                            var n = r._streamManager.getPayloadAssembler(t.id);
                            (n.payloadType = t.contentType),
                              (n.contentLength = t.length),
                              e.streams.push(new a.ContentStream(t.id, n));
                          }),
                        [4, this._onCompleted(this.id, e)]
                      );
                    case 1:
                      return n.sent(), [2];
                  }
                });
              });
            }),
            t
          );
        })();
      e.PayloadAssembler = c;
    },
    function(t, e, r) {
      "use strict";
      function n(t) {
        for (var r in t) e.hasOwnProperty(r) || (e[r] = t[r]);
      }
      Object.defineProperty(e, "__esModule", { value: !0 }),
        n(r(66)),
        n(r(68)),
        n(r(121)),
        n(r(16)),
        n(r(277)),
        n(r(124)),
        n(r(68));
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = r(278),
        s = r(279),
        a = r(280),
        c = r(281),
        u = r(16),
        f = (function() {
          function t(t) {
            this.payloadSender = t;
          }
          return (
            (t.prototype.sendRequest = function(t, e) {
              return n(this, void 0, void 0, function() {
                var r = this;
                return i(this, function(o) {
                  switch (o.label) {
                    case 0:
                      return [
                        4,
                        new a.RequestDisassembler(
                          this.payloadSender,
                          t,
                          e
                        ).disassemble()
                      ];
                    case 1:
                      return (
                        o.sent(),
                        e.streams &&
                          e.streams.forEach(function(t) {
                            return n(r, void 0, void 0, function() {
                              return i(this, function(e) {
                                switch (e.label) {
                                  case 0:
                                    return [
                                      4,
                                      new s.HttpContentStreamDisassembler(
                                        this.payloadSender,
                                        t
                                      ).disassemble()
                                    ];
                                  case 1:
                                    return e.sent(), [2];
                                }
                              });
                            });
                          }),
                        [2]
                      );
                  }
                });
              });
            }),
            (t.prototype.sendResponse = function(t, e) {
              return n(this, void 0, void 0, function() {
                var r = this;
                return i(this, function(o) {
                  switch (o.label) {
                    case 0:
                      return [
                        4,
                        new c.ResponseDisassembler(
                          this.payloadSender,
                          t,
                          e
                        ).disassemble()
                      ];
                    case 1:
                      return (
                        o.sent(),
                        e.streams &&
                          e.streams.forEach(function(t) {
                            return n(r, void 0, void 0, function() {
                              return i(this, function(e) {
                                switch (e.label) {
                                  case 0:
                                    return [
                                      4,
                                      new s.HttpContentStreamDisassembler(
                                        this.payloadSender,
                                        t
                                      ).disassemble()
                                    ];
                                  case 1:
                                    return e.sent(), [2];
                                }
                              });
                            });
                          }),
                        [2]
                      );
                  }
                });
              });
            }),
            (t.prototype.sendCancelStream = function(t) {
              return n(this, void 0, void 0, function() {
                return i(this, function(e) {
                  return (
                    new o.CancelDisassembler(
                      this.payloadSender,
                      t,
                      u.PayloadTypes.cancelStream
                    ).disassemble(),
                    [2]
                  );
                });
              });
            }),
            t
          );
        })();
      e.SendOperations = f;
    },
    function(t, e, r) {
      "use strict";
      (function(t) {
        var r =
            (this && this.__awaiter) ||
            function(t, e, r, n) {
              return new (r || (r = Promise))(function(i, o) {
                function s(t) {
                  try {
                    c(n.next(t));
                  } catch (t) {
                    o(t);
                  }
                }
                function a(t) {
                  try {
                    c(n.throw(t));
                  } catch (t) {
                    o(t);
                  }
                }
                function c(t) {
                  t.done
                    ? i(t.value)
                    : new r(function(e) {
                        e(t.value);
                      }).then(s, a);
                }
                c((n = n.apply(t, e || [])).next());
              });
            },
          n =
            (this && this.__generator) ||
            function(t, e) {
              var r,
                n,
                i,
                o,
                s = {
                  label: 0,
                  sent: function() {
                    if (1 & i[0]) throw i[1];
                    return i[1];
                  },
                  trys: [],
                  ops: []
                };
              return (
                (o = { next: a(0), throw: a(1), return: a(2) }),
                "function" == typeof Symbol &&
                  (o[Symbol.iterator] = function() {
                    return this;
                  }),
                o
              );
              function a(o) {
                return function(a) {
                  return (function(o) {
                    if (r)
                      throw new TypeError("Generator is already executing.");
                    for (; s; )
                      try {
                        if (
                          ((r = 1),
                          n &&
                            (i =
                              2 & o[0]
                                ? n.return
                                : o[0]
                                ? n.throw || ((i = n.return) && i.call(n), 0)
                                : n.next) &&
                            !(i = i.call(n, o[1])).done)
                        )
                          return i;
                        switch (
                          ((n = 0), i && (o = [2 & o[0], i.value]), o[0])
                        ) {
                          case 0:
                          case 1:
                            i = o;
                            break;
                          case 4:
                            return s.label++, { value: o[1], done: !1 };
                          case 5:
                            s.label++, (n = o[1]), (o = [0]);
                            continue;
                          case 7:
                            (o = s.ops.pop()), s.trys.pop();
                            continue;
                          default:
                            if (
                              !(i =
                                (i = s.trys).length > 0 && i[i.length - 1]) &&
                              (6 === o[0] || 2 === o[0])
                            ) {
                              s = 0;
                              continue;
                            }
                            if (
                              3 === o[0] &&
                              (!i || (o[1] > i[0] && o[1] < i[3]))
                            ) {
                              s.label = o[1];
                              break;
                            }
                            if (6 === o[0] && s.label < i[1]) {
                              (s.label = i[1]), (i = o);
                              break;
                            }
                            if (i && s.label < i[2]) {
                              (s.label = i[2]), s.ops.push(o);
                              break;
                            }
                            i[2] && s.ops.pop(), s.trys.pop();
                            continue;
                        }
                        o = e.call(t, s);
                      } catch (t) {
                        (o = [6, t]), (n = 0);
                      } finally {
                        r = i = 0;
                      }
                    if (5 & o[0]) throw o[1];
                    return { value: o[0] ? o[1] : void 0, done: !0 };
                  })([o, a]);
                };
              }
            };
        Object.defineProperty(e, "__esModule", { value: !0 });
        var i = (function() {
          function e(t) {
            var e = this;
            (this._socket = t),
              (this._queue = []),
              (this._activeOffset = 0),
              (this._activeReceiveCount = 0),
              this._socket.setOnMessageHandler(function(t) {
                e.onReceive(t);
              }),
              this._socket.setOnErrorHandler(function(t) {
                e.onError(t);
              }),
              this._socket.setOnCloseHandler(function() {
                e.onClose();
              });
          }
          return (
            (e.prototype.send = function(t) {
              return this._socket && this._socket.isConnected
                ? (this._socket.write(t), t.length)
                : 0;
            }),
            Object.defineProperty(e.prototype, "isConnected", {
              get: function() {
                return this._socket.isConnected;
              },
              enumerable: !0,
              configurable: !0
            }),
            (e.prototype.close = function() {
              this._socket && this._socket.isConnected && this._socket.close();
            }),
            (e.prototype.receive = function(t) {
              return r(this, void 0, void 0, function() {
                var e,
                  r = this;
                return n(this, function(n) {
                  if (this._activeReceiveResolve)
                    throw new Error(
                      "Cannot call receive more than once before it has returned."
                    );
                  return (
                    (this._activeReceiveCount = t),
                    (e = new Promise(function(t, e) {
                      (r._activeReceiveResolve = t),
                        (r._activeReceiveReject = e);
                    })),
                    this.trySignalData(),
                    [2, e]
                  );
                });
              });
            }),
            (e.prototype.onReceive = function(e) {
              this._queue &&
                e &&
                e.byteLength > 0 &&
                (this._queue.push(t.from(e)), this.trySignalData());
            }),
            (e.prototype.onClose = function() {
              this._activeReceiveReject &&
                this._activeReceiveReject(new Error("Socket was closed.")),
                (this._active = null),
                (this._activeOffset = 0),
                (this._activeReceiveResolve = null),
                (this._activeReceiveReject = null),
                (this._activeReceiveCount = 0),
                (this._socket = null);
            }),
            (e.prototype.onError = function(t) {
              this._activeReceiveReject && this._activeReceiveReject(t),
                this.onClose();
            }),
            (e.prototype.trySignalData = function() {
              if (
                this._activeReceiveResolve &&
                (!this._active &&
                  this._queue.length > 0 &&
                  ((this._active = this._queue.shift()),
                  (this._activeOffset = 0)),
                this._active)
              ) {
                if (
                  0 === this._activeOffset &&
                  this._active.length === this._activeReceiveCount
                ) {
                  var e = this._active;
                  (this._active = null), this._activeReceiveResolve(e);
                } else {
                  var r = Math.min(
                    this._activeReceiveCount,
                    this._active.length - this._activeOffset
                  );
                  e = t.alloc(r);
                  this._active.copy(
                    e,
                    0,
                    this._activeOffset,
                    this._activeOffset + r
                  ),
                    (this._activeOffset += r),
                    this._activeOffset >= this._active.length &&
                      ((this._active = null), (this._activeOffset = 0)),
                    this._activeReceiveResolve(e);
                }
                (this._activeReceiveCount = 0),
                  (this._activeReceiveReject = null),
                  (this._activeReceiveResolve = null);
              }
            }),
            e
          );
        })();
        e.WebSocketTransport = i;
      }.call(this, r(19).Buffer));
    },
    function(t, e) {
      t.exports = function(t, e) {
        if (null == t) return {};
        var r,
          n,
          i = {},
          o = Object.keys(t);
        for (n = 0; n < o.length; n++)
          (r = o[n]), e.indexOf(r) >= 0 || (i[r] = t[r]);
        return i;
      };
    },
    function(t, e, r) {
      var n = r(128);
      r(165), r(166), r(167), r(168), (t.exports = n);
    },
    function(t, e, r) {
      r(129), r(133), r(149), r(153), r(98), r(164);
      var n = r(84);
      t.exports = n.Promise;
    },
    function(t, e, r) {
      var n = r(44),
        i = r(17),
        o = r(132);
      n || i(Object.prototype, "toString", o, { unsafe: !0 });
    },
    function(t, e, r) {
      var n = r(81);
      t.exports = n && !Symbol.sham && "symbol" == typeof Symbol.iterator;
    },
    function(t, e, r) {
      var n = r(1),
        i = r(47),
        o = n.WeakMap;
      t.exports = "function" == typeof o && /native code/.test(i(o));
    },
    function(t, e, r) {
      "use strict";
      var n = r(44),
        i = r(82);
      t.exports = n
        ? {}.toString
        : function() {
            return "[object " + i(this) + "]";
          };
    },
    function(t, e, r) {
      "use strict";
      var n = r(134).charAt,
        i = r(22),
        o = r(83),
        s = i.set,
        a = i.getterFor("String Iterator");
      o(
        String,
        "String",
        function(t) {
          s(this, { type: "String Iterator", string: String(t), index: 0 });
        },
        function() {
          var t,
            e = a(this),
            r = e.string,
            i = e.index;
          return i >= r.length
            ? { value: void 0, done: !0 }
            : ((t = n(r, i)), (e.index += t.length), { value: t, done: !1 });
        }
      );
    },
    function(t, e, r) {
      var n = r(50),
        i = r(51),
        o = function(t) {
          return function(e, r) {
            var o,
              s,
              a = String(i(e)),
              c = n(r),
              u = a.length;
            return c < 0 || c >= u
              ? t
                ? ""
                : void 0
              : (o = a.charCodeAt(c)) < 55296 ||
                o > 56319 ||
                c + 1 === u ||
                (s = a.charCodeAt(c + 1)) < 56320 ||
                s > 57343
              ? t
                ? a.charAt(c)
                : o
              : t
              ? a.slice(c, c + 2)
              : s - 56320 + ((o - 55296) << 10) + 65536;
          };
        };
      t.exports = { codeAt: o(!1), charAt: o(!0) };
    },
    function(t, e, r) {
      "use strict";
      var n = {}.propertyIsEnumerable,
        i = Object.getOwnPropertyDescriptor,
        o = i && !n.call({ 1: 2 }, 1);
      e.f = o
        ? function(t) {
            var e = i(this, t);
            return !!e && e.enumerable;
          }
        : n;
    },
    function(t, e, r) {
      var n = r(9),
        i = r(23),
        o = "".split;
      t.exports = n(function() {
        return !Object("z").propertyIsEnumerable(0);
      })
        ? function(t) {
            return "String" == i(t) ? o.call(t, "") : Object(t);
          }
        : Object;
    },
    function(t, e, r) {
      var n = r(6),
        i = r(138),
        o = r(52),
        s = r(11);
      t.exports = function(t, e) {
        for (var r = i(e), a = s.f, c = o.f, u = 0; u < r.length; u++) {
          var f = r[u];
          n(t, f) || a(t, f, c(e, f));
        }
      };
    },
    function(t, e, r) {
      var n = r(14),
        i = r(139),
        o = r(142),
        s = r(5);
      t.exports =
        n("Reflect", "ownKeys") ||
        function(t) {
          var e = i.f(s(t)),
            r = o.f;
          return r ? e.concat(r(t)) : e;
        };
    },
    function(t, e, r) {
      var n = r(85),
        i = r(53).concat("length", "prototype");
      e.f =
        Object.getOwnPropertyNames ||
        function(t) {
          return n(t, i);
        };
    },
    function(t, e, r) {
      var n = r(31),
        i = r(86),
        o = r(141),
        s = function(t) {
          return function(e, r, s) {
            var a,
              c = n(e),
              u = i(c.length),
              f = o(s, u);
            if (t && r != r) {
              for (; u > f; ) if ((a = c[f++]) != a) return !0;
            } else
              for (; u > f; f++)
                if ((t || f in c) && c[f] === r) return t || f || 0;
            return !t && -1;
          };
        };
      t.exports = { includes: s(!0), indexOf: s(!1) };
    },
    function(t, e, r) {
      var n = r(50),
        i = Math.max,
        o = Math.min;
      t.exports = function(t, e) {
        var r = n(t);
        return r < 0 ? i(r + e, 0) : o(r, e);
      };
    },
    function(t, e) {
      e.f = Object.getOwnPropertySymbols;
    },
    function(t, e, r) {
      "use strict";
      var n = r(88).IteratorPrototype,
        i = r(55),
        o = r(30),
        s = r(56),
        a = r(24),
        c = function() {
          return this;
        };
      t.exports = function(t, e, r) {
        var u = e + " Iterator";
        return (
          (t.prototype = i(n, { next: o(1, r) })),
          s(t, u, !1, !0),
          (a[u] = c),
          t
        );
      };
    },
    function(t, e, r) {
      var n = r(51);
      t.exports = function(t) {
        return Object(n(t));
      };
    },
    function(t, e, r) {
      var n = r(9);
      t.exports = !n(function() {
        function t() {}
        return (
          (t.prototype.constructor = null),
          Object.getPrototypeOf(new t()) !== t.prototype
        );
      });
    },
    function(t, e, r) {
      var n = r(10),
        i = r(11),
        o = r(5),
        s = r(147);
      t.exports = n
        ? Object.defineProperties
        : function(t, e) {
            o(t);
            for (var r, n = s(e), a = n.length, c = 0; a > c; )
              i.f(t, (r = n[c++]), e[r]);
            return t;
          };
    },
    function(t, e, r) {
      var n = r(85),
        i = r(53);
      t.exports =
        Object.keys ||
        function(t) {
          return n(t, i);
        };
    },
    function(t, e, r) {
      var n = r(12);
      t.exports = function(t) {
        if (!n(t) && null !== t)
          throw TypeError("Can't set " + String(t) + " as a prototype");
        return t;
      };
    },
    function(t, e, r) {
      var n = r(1),
        i = r(150),
        o = r(151),
        s = r(8),
        a = r(3),
        c = a("iterator"),
        u = a("toStringTag"),
        f = o.values;
      for (var l in i) {
        var h = n[l],
          p = h && h.prototype;
        if (p) {
          if (p[c] !== f)
            try {
              s(p, c, f);
            } catch (t) {
              p[c] = f;
            }
          if ((p[u] || s(p, u, l), i[l]))
            for (var d in o)
              if (p[d] !== o[d])
                try {
                  s(p, d, o[d]);
                } catch (t) {
                  p[d] = o[d];
                }
        }
      }
    },
    function(t, e) {
      t.exports = {
        CSSRuleList: 0,
        CSSStyleDeclaration: 0,
        CSSValueList: 0,
        ClientRectList: 0,
        DOMRectList: 0,
        DOMStringList: 0,
        DOMTokenList: 1,
        DataTransferItemList: 0,
        FileList: 0,
        HTMLAllCollection: 0,
        HTMLCollection: 0,
        HTMLFormElement: 0,
        HTMLSelectElement: 0,
        MediaList: 0,
        MimeTypeArray: 0,
        NamedNodeMap: 0,
        NodeList: 1,
        PaintRequestList: 0,
        Plugin: 0,
        PluginArray: 0,
        SVGLengthList: 0,
        SVGNumberList: 0,
        SVGPathSegList: 0,
        SVGPointList: 0,
        SVGStringList: 0,
        SVGTransformList: 0,
        SourceBufferList: 0,
        StyleSheetList: 0,
        TextTrackCueList: 0,
        TextTrackList: 0,
        TouchList: 0
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(31),
        i = r(152),
        o = r(24),
        s = r(22),
        a = r(83),
        c = s.set,
        u = s.getterFor("Array Iterator");
      (t.exports = a(
        Array,
        "Array",
        function(t, e) {
          c(this, { type: "Array Iterator", target: n(t), index: 0, kind: e });
        },
        function() {
          var t = u(this),
            e = t.target,
            r = t.kind,
            n = t.index++;
          return !e || n >= e.length
            ? ((t.target = void 0), { value: void 0, done: !0 })
            : "keys" == r
            ? { value: n, done: !1 }
            : "values" == r
            ? { value: e[n], done: !1 }
            : { value: [n, e[n]], done: !1 };
        },
        "values"
      )),
        (o.Arguments = o.Array),
        i("keys"),
        i("values"),
        i("entries");
    },
    function(t, e, r) {
      var n = r(3),
        i = r(55),
        o = r(11),
        s = n("unscopables"),
        a = Array.prototype;
      null == a[s] && o.f(a, s, { configurable: !0, value: i(null) }),
        (t.exports = function(t) {
          a[s][t] = !0;
        });
    },
    function(t, e, r) {
      "use strict";
      var n,
        i,
        o,
        s,
        a = r(13),
        c = r(21),
        u = r(1),
        f = r(14),
        l = r(91),
        h = r(17),
        p = r(154),
        d = r(56),
        y = r(155),
        v = r(12),
        b = r(18),
        g = r(156),
        m = r(23),
        w = r(47),
        _ = r(32),
        S = r(160),
        x = r(93),
        O = r(94).set,
        E = r(161),
        k = r(97),
        P = r(162),
        T = r(25),
        j = r(33),
        A = r(22),
        R = r(87),
        C = r(3),
        M = r(163),
        L = C("species"),
        I = "Promise",
        D = A.get,
        U = A.set,
        B = A.getterFor(I),
        N = l,
        q = u.TypeError,
        F = u.document,
        H = u.process,
        W = f("fetch"),
        Y = T.f,
        G = Y,
        $ = "process" == m(H),
        V = !!(F && F.createEvent && u.dispatchEvent),
        z = R(I, function() {
          if (!(w(N) !== String(N))) {
            if (66 === M) return !0;
            if (!$ && "function" != typeof PromiseRejectionEvent) return !0;
          }
          if (c && !N.prototype.finally) return !0;
          if (M >= 51 && /native code/.test(N)) return !1;
          var t = N.resolve(1),
            e = function(t) {
              t(
                function() {},
                function() {}
              );
            };
          return (
            ((t.constructor = {})[L] = e), !(t.then(function() {}) instanceof e)
          );
        }),
        J =
          z ||
          !S(function(t) {
            N.all(t).catch(function() {});
          }),
        X = function(t) {
          var e;
          return !(!v(t) || "function" != typeof (e = t.then)) && e;
        },
        Q = function(t, e, r) {
          if (!e.notified) {
            e.notified = !0;
            var n = e.reactions;
            E(function() {
              for (var i = e.value, o = 1 == e.state, s = 0; n.length > s; ) {
                var a,
                  c,
                  u,
                  f = n[s++],
                  l = o ? f.ok : f.fail,
                  h = f.resolve,
                  p = f.reject,
                  d = f.domain;
                try {
                  l
                    ? (o || (2 === e.rejection && et(t, e), (e.rejection = 1)),
                      !0 === l
                        ? (a = i)
                        : (d && d.enter(),
                          (a = l(i)),
                          d && (d.exit(), (u = !0))),
                      a === f.promise
                        ? p(q("Promise-chain cycle"))
                        : (c = X(a))
                        ? c.call(a, h, p)
                        : h(a))
                    : p(i);
                } catch (t) {
                  d && !u && d.exit(), p(t);
                }
              }
              (e.reactions = []),
                (e.notified = !1),
                r && !e.rejection && Z(t, e);
            });
          }
        },
        K = function(t, e, r) {
          var n, i;
          V
            ? (((n = F.createEvent("Event")).promise = e),
              (n.reason = r),
              n.initEvent(t, !1, !0),
              u.dispatchEvent(n))
            : (n = { promise: e, reason: r }),
            (i = u["on" + t])
              ? i(n)
              : "unhandledrejection" === t &&
                P("Unhandled promise rejection", r);
        },
        Z = function(t, e) {
          O.call(u, function() {
            var r,
              n = e.value;
            if (
              tt(e) &&
              ((r = j(function() {
                $
                  ? H.emit("unhandledRejection", n, t)
                  : K("unhandledrejection", t, n);
              })),
              (e.rejection = $ || tt(e) ? 2 : 1),
              r.error)
            )
              throw r.value;
          });
        },
        tt = function(t) {
          return 1 !== t.rejection && !t.parent;
        },
        et = function(t, e) {
          O.call(u, function() {
            $
              ? H.emit("rejectionHandled", t)
              : K("rejectionhandled", t, e.value);
          });
        },
        rt = function(t, e, r, n) {
          return function(i) {
            t(e, r, i, n);
          };
        },
        nt = function(t, e, r, n) {
          e.done ||
            ((e.done = !0),
            n && (e = n),
            (e.value = r),
            (e.state = 2),
            Q(t, e, !0));
        },
        it = function(t, e, r, n) {
          if (!e.done) {
            (e.done = !0), n && (e = n);
            try {
              if (t === r) throw q("Promise can't be resolved itself");
              var i = X(r);
              i
                ? E(function() {
                    var n = { done: !1 };
                    try {
                      i.call(r, rt(it, t, n, e), rt(nt, t, n, e));
                    } catch (r) {
                      nt(t, n, r, e);
                    }
                  })
                : ((e.value = r), (e.state = 1), Q(t, e, !1));
            } catch (r) {
              nt(t, { done: !1 }, r, e);
            }
          }
        };
      z &&
        ((N = function(t) {
          g(this, N, I), b(t), n.call(this);
          var e = D(this);
          try {
            t(rt(it, this, e), rt(nt, this, e));
          } catch (t) {
            nt(this, e, t);
          }
        }),
        ((n = function(t) {
          U(this, {
            type: I,
            done: !1,
            notified: !1,
            parent: !1,
            reactions: [],
            rejection: !1,
            state: 0,
            value: void 0
          });
        }).prototype = p(N.prototype, {
          then: function(t, e) {
            var r = B(this),
              n = Y(x(this, N));
            return (
              (n.ok = "function" != typeof t || t),
              (n.fail = "function" == typeof e && e),
              (n.domain = $ ? H.domain : void 0),
              (r.parent = !0),
              r.reactions.push(n),
              0 != r.state && Q(this, r, !1),
              n.promise
            );
          },
          catch: function(t) {
            return this.then(void 0, t);
          }
        })),
        (i = function() {
          var t = new n(),
            e = D(t);
          (this.promise = t),
            (this.resolve = rt(it, t, e)),
            (this.reject = rt(nt, t, e));
        }),
        (T.f = Y = function(t) {
          return t === N || t === o ? new i(t) : G(t);
        }),
        c ||
          "function" != typeof l ||
          ((s = l.prototype.then),
          h(
            l.prototype,
            "then",
            function(t, e) {
              var r = this;
              return new N(function(t, e) {
                s.call(r, t, e);
              }).then(t, e);
            },
            { unsafe: !0 }
          ),
          "function" == typeof W &&
            a(
              { global: !0, enumerable: !0, forced: !0 },
              {
                fetch: function(t) {
                  return k(N, W.apply(u, arguments));
                }
              }
            ))),
        a({ global: !0, wrap: !0, forced: z }, { Promise: N }),
        d(N, I, !1, !0),
        y(I),
        (o = f(I)),
        a(
          { target: I, stat: !0, forced: z },
          {
            reject: function(t) {
              var e = Y(this);
              return e.reject.call(void 0, t), e.promise;
            }
          }
        ),
        a(
          { target: I, stat: !0, forced: c || z },
          {
            resolve: function(t) {
              return k(c && this === o ? N : this, t);
            }
          }
        ),
        a(
          { target: I, stat: !0, forced: J },
          {
            all: function(t) {
              var e = this,
                r = Y(e),
                n = r.resolve,
                i = r.reject,
                o = j(function() {
                  var r = b(e.resolve),
                    o = [],
                    s = 0,
                    a = 1;
                  _(t, function(t) {
                    var c = s++,
                      u = !1;
                    o.push(void 0),
                      a++,
                      r.call(e, t).then(function(t) {
                        u || ((u = !0), (o[c] = t), --a || n(o));
                      }, i);
                  }),
                    --a || n(o);
                });
              return o.error && i(o.value), r.promise;
            },
            race: function(t) {
              var e = this,
                r = Y(e),
                n = r.reject,
                i = j(function() {
                  var i = b(e.resolve);
                  _(t, function(t) {
                    i.call(e, t).then(r.resolve, n);
                  });
                });
              return i.error && n(i.value), r.promise;
            }
          }
        );
    },
    function(t, e, r) {
      var n = r(17);
      t.exports = function(t, e, r) {
        for (var i in e) n(t, i, e[i], r);
        return t;
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(14),
        i = r(11),
        o = r(3),
        s = r(10),
        a = o("species");
      t.exports = function(t) {
        var e = n(t),
          r = i.f;
        s &&
          e &&
          !e[a] &&
          r(e, a, {
            configurable: !0,
            get: function() {
              return this;
            }
          });
      };
    },
    function(t, e) {
      t.exports = function(t, e, r) {
        if (!(t instanceof e))
          throw TypeError("Incorrect " + (r ? r + " " : "") + "invocation");
        return t;
      };
    },
    function(t, e, r) {
      var n = r(3),
        i = r(24),
        o = n("iterator"),
        s = Array.prototype;
      t.exports = function(t) {
        return void 0 !== t && (i.Array === t || s[o] === t);
      };
    },
    function(t, e, r) {
      var n = r(82),
        i = r(24),
        o = r(3)("iterator");
      t.exports = function(t) {
        if (null != t) return t[o] || t["@@iterator"] || i[n(t)];
      };
    },
    function(t, e, r) {
      var n = r(5);
      t.exports = function(t, e, r, i) {
        try {
          return i ? e(n(r)[0], r[1]) : e(r);
        } catch (e) {
          var o = t.return;
          throw (void 0 !== o && n(o.call(t)), e);
        }
      };
    },
    function(t, e, r) {
      var n = r(3)("iterator"),
        i = !1;
      try {
        var o = 0,
          s = {
            next: function() {
              return { done: !!o++ };
            },
            return: function() {
              i = !0;
            }
          };
        (s[n] = function() {
          return this;
        }),
          Array.from(s, function() {
            throw 2;
          });
      } catch (t) {}
      t.exports = function(t, e) {
        if (!e && !i) return !1;
        var r = !1;
        try {
          var o = {};
          (o[n] = function() {
            return {
              next: function() {
                return { done: (r = !0) };
              }
            };
          }),
            t(o);
        } catch (t) {}
        return r;
      };
    },
    function(t, e, r) {
      var n,
        i,
        o,
        s,
        a,
        c,
        u,
        f,
        l = r(1),
        h = r(52).f,
        p = r(23),
        d = r(94).set,
        y = r(95),
        v = l.MutationObserver || l.WebKitMutationObserver,
        b = l.process,
        g = l.Promise,
        m = "process" == p(b),
        w = h(l, "queueMicrotask"),
        _ = w && w.value;
      _ ||
        ((n = function() {
          var t, e;
          for (m && (t = b.domain) && t.exit(); i; ) {
            (e = i.fn), (i = i.next);
            try {
              e();
            } catch (t) {
              throw (i ? s() : (o = void 0), t);
            }
          }
          (o = void 0), t && t.enter();
        }),
        m
          ? (s = function() {
              b.nextTick(n);
            })
          : v && !y
          ? ((a = !0),
            (c = document.createTextNode("")),
            new v(n).observe(c, { characterData: !0 }),
            (s = function() {
              c.data = a = !a;
            }))
          : g && g.resolve
          ? ((u = g.resolve(void 0)),
            (f = u.then),
            (s = function() {
              f.call(u, n);
            }))
          : (s = function() {
              d.call(l, n);
            })),
        (t.exports =
          _ ||
          function(t) {
            var e = { fn: t, next: void 0 };
            o && (o.next = e), i || ((i = e), s()), (o = e);
          });
    },
    function(t, e, r) {
      var n = r(1);
      t.exports = function(t, e) {
        var r = n.console;
        r && r.error && (1 === arguments.length ? r.error(t) : r.error(t, e));
      };
    },
    function(t, e, r) {
      var n,
        i,
        o = r(1),
        s = r(96),
        a = o.process,
        c = a && a.versions,
        u = c && c.v8;
      u
        ? (i = (n = u.split("."))[0] + n[1])
        : s &&
          (!(n = s.match(/Edge\/(\d+)/)) || n[1] >= 74) &&
          (n = s.match(/Chrome\/(\d+)/)) &&
          (i = n[1]),
        (t.exports = i && +i);
    },
    function(t, e, r) {
      "use strict";
      var n = r(13),
        i = r(21),
        o = r(91),
        s = r(9),
        a = r(14),
        c = r(93),
        u = r(97),
        f = r(17);
      n(
        {
          target: "Promise",
          proto: !0,
          real: !0,
          forced:
            !!o &&
            s(function() {
              o.prototype.finally.call({ then: function() {} }, function() {});
            })
        },
        {
          finally: function(t) {
            var e = c(this, a("Promise")),
              r = "function" == typeof t;
            return this.then(
              r
                ? function(r) {
                    return u(e, t()).then(function() {
                      return r;
                    });
                  }
                : t,
              r
                ? function(r) {
                    return u(e, t()).then(function() {
                      throw r;
                    });
                  }
                : t
            );
          }
        }
      ),
        i ||
          "function" != typeof o ||
          o.prototype.finally ||
          f(o.prototype, "finally", a("Promise").prototype.finally);
    },
    function(t, e, r) {
      "use strict";
      var n = r(13),
        i = r(10),
        o = r(54),
        s = r(90),
        a = r(55),
        c = r(11),
        u = r(30),
        f = r(32),
        l = r(8),
        h = r(22),
        p = h.set,
        d = h.getterFor("AggregateError"),
        y = function(t, e) {
          var r = this;
          if (!(r instanceof y)) return new y(t, e);
          s && (r = s(new Error(e), o(r)));
          var n = [];
          return (
            f(t, n.push, n),
            i ? p(r, { errors: n, type: "AggregateError" }) : (r.errors = n),
            void 0 !== e && l(r, "message", String(e)),
            r
          );
        };
      (y.prototype = a(Error.prototype, {
        constructor: u(5, y),
        message: u(5, ""),
        name: u(5, "AggregateError")
      })),
        i &&
          c.f(y.prototype, "errors", {
            get: function() {
              return d(this).errors;
            },
            configurable: !0
          }),
        n({ global: !0 }, { AggregateError: y });
    },
    function(t, e, r) {
      r(98);
    },
    function(t, e, r) {
      "use strict";
      var n = r(13),
        i = r(25),
        o = r(33);
      n(
        { target: "Promise", stat: !0 },
        {
          try: function(t) {
            var e = i.f(this),
              r = o(t);
            return (r.error ? e.reject : e.resolve)(r.value), e.promise;
          }
        }
      );
    },
    function(t, e, r) {
      "use strict";
      var n = r(13),
        i = r(18),
        o = r(14),
        s = r(25),
        a = r(33),
        c = r(32);
      n(
        { target: "Promise", stat: !0 },
        {
          any: function(t) {
            var e = this,
              r = s.f(e),
              n = r.resolve,
              u = r.reject,
              f = a(function() {
                var r = i(e.resolve),
                  s = [],
                  a = 0,
                  f = 1,
                  l = !1;
                c(t, function(t) {
                  var i = a++,
                    c = !1;
                  s.push(void 0),
                    f++,
                    r.call(e, t).then(
                      function(t) {
                        c || l || ((l = !0), n(t));
                      },
                      function(t) {
                        c ||
                          l ||
                          ((c = !0),
                          (s[i] = t),
                          --f ||
                            u(
                              new (o("AggregateError"))(
                                s,
                                "No one promise resolved"
                              )
                            ));
                      }
                    );
                }),
                  --f ||
                    u(new (o("AggregateError"))(s, "No one promise resolved"));
              });
            return f.error && u(f.value), r.promise;
          }
        }
      );
    },
    function(t, e, r) {
      (function(t) {
        !(function(t) {
          "use strict";
          var e,
            r =
              t.URLSearchParams && t.URLSearchParams.prototype.get
                ? t.URLSearchParams
                : null,
            n = r && "a=1" === new r({ a: 1 }).toString(),
            i = r && "+" === new r("s=%2B").get("s"),
            o =
              !r ||
              ((e = new r()).append("s", " &"), "s=+%26" === e.toString()),
            s = f.prototype,
            a = !(!t.Symbol || !t.Symbol.iterator);
          if (!(r && n && i && o)) {
            (s.append = function(t, e) {
              y(this.__URLSearchParams__, t, e);
            }),
              (s.delete = function(t) {
                delete this.__URLSearchParams__[t];
              }),
              (s.get = function(t) {
                var e = this.__URLSearchParams__;
                return this.has(t) ? e[t][0] : null;
              }),
              (s.getAll = function(t) {
                var e = this.__URLSearchParams__;
                return this.has(t) ? e[t].slice(0) : [];
              }),
              (s.has = function(t) {
                return b(this.__URLSearchParams__, t);
              }),
              (s.set = function(t, e) {
                this.__URLSearchParams__[t] = ["" + e];
              }),
              (s.toString = function() {
                var t,
                  e,
                  r,
                  n,
                  i = this.__URLSearchParams__,
                  o = [];
                for (e in i)
                  for (r = l(e), t = 0, n = i[e]; t < n.length; t++)
                    o.push(r + "=" + l(n[t]));
                return o.join("&");
              });
            var c = !!i && r && !n && t.Proxy;
            Object.defineProperty(t, "URLSearchParams", {
              value: c
                ? new Proxy(r, {
                    construct: function(t, e) {
                      return new t(new f(e[0]).toString());
                    }
                  })
                : f
            });
            var u = t.URLSearchParams.prototype;
            (u.polyfill = !0),
              (u.forEach =
                u.forEach ||
                function(t, e) {
                  var r = d(this.toString());
                  Object.getOwnPropertyNames(r).forEach(function(n) {
                    r[n].forEach(function(r) {
                      t.call(e, r, n, this);
                    }, this);
                  }, this);
                }),
              (u.sort =
                u.sort ||
                function() {
                  var t,
                    e,
                    r,
                    n = d(this.toString()),
                    i = [];
                  for (t in n) i.push(t);
                  for (i.sort(), e = 0; e < i.length; e++) this.delete(i[e]);
                  for (e = 0; e < i.length; e++) {
                    var o = i[e],
                      s = n[o];
                    for (r = 0; r < s.length; r++) this.append(o, s[r]);
                  }
                }),
              (u.keys =
                u.keys ||
                function() {
                  var t = [];
                  return (
                    this.forEach(function(e, r) {
                      t.push(r);
                    }),
                    p(t)
                  );
                }),
              (u.values =
                u.values ||
                function() {
                  var t = [];
                  return (
                    this.forEach(function(e) {
                      t.push(e);
                    }),
                    p(t)
                  );
                }),
              (u.entries =
                u.entries ||
                function() {
                  var t = [];
                  return (
                    this.forEach(function(e, r) {
                      t.push([r, e]);
                    }),
                    p(t)
                  );
                }),
              a && (u[t.Symbol.iterator] = u[t.Symbol.iterator] || u.entries);
          }
          function f(t) {
            ((t = t || "") instanceof URLSearchParams || t instanceof f) &&
              (t = t.toString()),
              (this.__URLSearchParams__ = d(t));
          }
          function l(t) {
            var e = {
              "!": "%21",
              "'": "%27",
              "(": "%28",
              ")": "%29",
              "~": "%7E",
              "%20": "+",
              "%00": "\0"
            };
            return encodeURIComponent(t).replace(/[!'\(\)~]|%20|%00/g, function(
              t
            ) {
              return e[t];
            });
          }
          function h(t) {
            return t
              .replace(/[ +]/g, "%20")
              .replace(/(%[a-f0-9]{2})+/gi, function(t) {
                return decodeURIComponent(t);
              });
          }
          function p(e) {
            var r = {
              next: function() {
                var t = e.shift();
                return { done: void 0 === t, value: t };
              }
            };
            return (
              a &&
                (r[t.Symbol.iterator] = function() {
                  return r;
                }),
              r
            );
          }
          function d(t) {
            var e = {};
            if ("object" == typeof t)
              if (v(t))
                for (var r = 0; r < t.length; r++) {
                  var n = t[r];
                  if (!v(n) || 2 !== n.length)
                    throw new TypeError(
                      "Failed to construct 'URLSearchParams': Sequence initializer must only contain pair elements"
                    );
                  y(e, n[0], n[1]);
                }
              else for (var i in t) t.hasOwnProperty(i) && y(e, i, t[i]);
            else {
              0 === t.indexOf("?") && (t = t.slice(1));
              for (var o = t.split("&"), s = 0; s < o.length; s++) {
                var a = o[s],
                  c = a.indexOf("=");
                -1 < c
                  ? y(e, h(a.slice(0, c)), h(a.slice(c + 1)))
                  : a && y(e, h(a), "");
              }
            }
            return e;
          }
          function y(t, e, r) {
            var n =
              "string" == typeof r
                ? r
                : null != r && "function" == typeof r.toString
                ? r.toString()
                : JSON.stringify(r);
            b(t, e) ? t[e].push(n) : (t[e] = [n]);
          }
          function v(t) {
            return (
              !!t && "[object Array]" === Object.prototype.toString.call(t)
            );
          }
          function b(t, e) {
            return Object.prototype.hasOwnProperty.call(t, e);
          }
        })(void 0 !== t ? t : "undefined" != typeof window ? window : this);
      }.call(this, r(4)));
    },
    function(t, e, r) {
      "use strict";
      var n = r(2),
        i = r(58),
        o = r(102);
      e.toSubscriber = function(t, e, r) {
        if (t) {
          if (t instanceof n.Subscriber) return t;
          if (t[i.rxSubscriber]) return t[i.rxSubscriber]();
        }
        return t || e || r
          ? new n.Subscriber(t, e, r)
          : new n.Subscriber(o.empty);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e) {
            t.call(this), (this.errors = e);
            var r = Error.call(
              this,
              e
                ? e.length +
                    " errors occurred during unsubscription:\n  " +
                    e
                      .map(function(t, e) {
                        return e + 1 + ") " + t.toString();
                      })
                      .join("\n  ")
                : ""
            );
            (this.name = r.name = "UnsubscriptionError"),
              (this.stack = r.stack),
              (this.message = r.message);
          }
          return n(e, t), e;
        })(Error);
      e.UnsubscriptionError = i;
    },
    function(t, e, r) {
      "use strict";
      var n = r(173);
      function i(t) {
        return t
          ? 1 === t.length
            ? t[0]
            : function(e) {
                return t.reduce(function(t, e) {
                  return e(t);
                }, e);
              }
          : n.noop;
      }
      (e.pipe = function() {
        for (var t = [], e = 0; e < arguments.length; e++)
          t[e - 0] = arguments[e];
        return i(t);
      }),
        (e.pipeFromArray = i);
    },
    function(t, e, r) {
      "use strict";
      e.noop = function() {};
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e, r) {
            t.call(this),
              (this.subject = e),
              (this.subscriber = r),
              (this.closed = !1);
          }
          return (
            n(e, t),
            (e.prototype.unsubscribe = function() {
              if (!this.closed) {
                this.closed = !0;
                var t = this.subject,
                  e = t.observers;
                if (
                  ((this.subject = null),
                  e && 0 !== e.length && !t.isStopped && !t.closed)
                ) {
                  var r = e.indexOf(this.subscriber);
                  -1 !== r && e.splice(r, 1);
                }
              }
            }),
            e
          );
        })(r(26).Subscription);
      e.SubjectSubscription = i;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(7),
        o = (function(t) {
          function e(e, r) {
            t.call(this, e, r),
              (this.scheduler = e),
              (this.work = r),
              (this.pending = !1);
          }
          return (
            n(e, t),
            (e.prototype.schedule = function(t, e) {
              if ((void 0 === e && (e = 0), this.closed)) return this;
              (this.state = t), (this.pending = !0);
              var r = this.id,
                n = this.scheduler;
              return (
                null != r && (this.id = this.recycleAsyncId(n, r, e)),
                (this.delay = e),
                (this.id = this.id || this.requestAsyncId(n, this.id, e)),
                this
              );
            }),
            (e.prototype.requestAsyncId = function(t, e, r) {
              return (
                void 0 === r && (r = 0),
                i.root.setInterval(t.flush.bind(t, this), r)
              );
            }),
            (e.prototype.recycleAsyncId = function(t, e, r) {
              if (
                (void 0 === r && (r = 0),
                null !== r && this.delay === r && !1 === this.pending)
              )
                return e;
              i.root.clearInterval(e);
            }),
            (e.prototype.execute = function(t, e) {
              if (this.closed) return new Error("executing a cancelled action");
              this.pending = !1;
              var r = this._execute(t, e);
              if (r) return r;
              !1 === this.pending &&
                null != this.id &&
                (this.id = this.recycleAsyncId(this.scheduler, this.id, null));
            }),
            (e.prototype._execute = function(t, e) {
              var r = !1,
                n = void 0;
              try {
                this.work(t);
              } catch (t) {
                (r = !0), (n = (!!t && t) || new Error(t));
              }
              if (r) return this.unsubscribe(), n;
            }),
            (e.prototype._unsubscribe = function() {
              var t = this.id,
                e = this.scheduler,
                r = e.actions,
                n = r.indexOf(this);
              (this.work = null),
                (this.state = null),
                (this.pending = !1),
                (this.scheduler = null),
                -1 !== n && r.splice(n, 1),
                null != t && (this.id = this.recycleAsyncId(e, t, null)),
                (this.delay = null);
            }),
            e
          );
        })(r(176).Action);
      e.AsyncAction = o;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e, r) {
            t.call(this);
          }
          return (
            n(e, t),
            (e.prototype.schedule = function(t, e) {
              return void 0 === e && (e = 0), this;
            }),
            e
          );
        })(r(26).Subscription);
      e.Action = i;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e() {
            t.apply(this, arguments),
              (this.actions = []),
              (this.active = !1),
              (this.scheduled = void 0);
          }
          return (
            n(e, t),
            (e.prototype.flush = function(t) {
              var e = this.actions;
              if (this.active) e.push(t);
              else {
                var r;
                this.active = !0;
                do {
                  if ((r = t.execute(t.state, t.delay))) break;
                } while ((t = e.shift()));
                if (((this.active = !1), r)) {
                  for (; (t = e.shift()); ) t.unsubscribe();
                  throw r;
                }
              }
            }),
            e
          );
        })(r(178).Scheduler);
      e.AsyncScheduler = i;
    },
    function(t, e, r) {
      "use strict";
      var n = (function() {
        function t(e, r) {
          void 0 === r && (r = t.now),
            (this.SchedulerAction = e),
            (this.now = r);
        }
        return (
          (t.prototype.schedule = function(t, e, r) {
            return (
              void 0 === e && (e = 0),
              new this.SchedulerAction(this, t).schedule(r, e)
            );
          }),
          (t.now = Date.now
            ? Date.now
            : function() {
                return +new Date();
              }),
          t
        );
      })();
      e.Scheduler = n;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(180);
      (n.Observable.prototype.catch = i._catch),
        (n.Observable.prototype._catch = i._catch);
    },
    function(t, e, r) {
      "use strict";
      var n = r(181);
      e._catch = function(t) {
        return n.catchError(t)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(38),
        o = r(39);
      e.catchError = function(t) {
        return function(e) {
          var r = new s(t),
            n = e.lift(r);
          return (r.caught = n);
        };
      };
      var s = (function() {
          function t(t) {
            this.selector = t;
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(new a(t, this.selector, this.caught));
            }),
            t
          );
        })(),
        a = (function(t) {
          function e(e, r, n) {
            t.call(this, e), (this.selector = r), (this.caught = n);
          }
          return (
            n(e, t),
            (e.prototype.error = function(e) {
              if (!this.isStopped) {
                var r = void 0;
                try {
                  r = this.selector(e, this.caught);
                } catch (e) {
                  return void t.prototype.error.call(this, e);
                }
                this._unsubscribeAndRecycle(),
                  this.add(o.subscribeToResult(this, r));
              }
            }),
            e
          );
        })(i.OuterSubscriber);
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e, r, n) {
            t.call(this),
              (this.parent = e),
              (this.outerValue = r),
              (this.outerIndex = n),
              (this.index = 0);
          }
          return (
            n(e, t),
            (e.prototype._next = function(t) {
              this.parent.notifyNext(
                this.outerValue,
                t,
                this.outerIndex,
                this.index++,
                this
              );
            }),
            (e.prototype._error = function(t) {
              this.parent.notifyError(t, this), this.unsubscribe();
            }),
            (e.prototype._complete = function() {
              this.parent.notifyComplete(this), this.unsubscribe();
            }),
            e
          );
        })(r(2).Subscriber);
      e.InnerSubscriber = i;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(184);
      n.Observable.prototype.combineLatest = i.combineLatest;
    },
    function(t, e, r) {
      "use strict";
      var n = r(185);
      e.combineLatest = function() {
        for (var t = [], e = 0; e < arguments.length; e++)
          t[e - 0] = arguments[e];
        return n.combineLatest.apply(void 0, t)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(61),
        o = r(35),
        s = r(38),
        a = r(39),
        c = {};
      e.combineLatest = function() {
        for (var t = [], e = 0; e < arguments.length; e++)
          t[e - 0] = arguments[e];
        var r = null;
        return (
          "function" == typeof t[t.length - 1] && (r = t.pop()),
          1 === t.length && o.isArray(t[0]) && (t = t[0].slice()),
          function(e) {
            return e.lift.call(new i.ArrayObservable([e].concat(t)), new u(r));
          }
        );
      };
      var u = (function() {
        function t(t) {
          this.project = t;
        }
        return (
          (t.prototype.call = function(t, e) {
            return e.subscribe(new f(t, this.project));
          }),
          t
        );
      })();
      e.CombineLatestOperator = u;
      var f = (function(t) {
        function e(e, r) {
          t.call(this, e),
            (this.project = r),
            (this.active = 0),
            (this.values = []),
            (this.observables = []);
        }
        return (
          n(e, t),
          (e.prototype._next = function(t) {
            this.values.push(c), this.observables.push(t);
          }),
          (e.prototype._complete = function() {
            var t = this.observables,
              e = t.length;
            if (0 === e) this.destination.complete();
            else {
              (this.active = e), (this.toRespond = e);
              for (var r = 0; r < e; r++) {
                var n = t[r];
                this.add(a.subscribeToResult(this, n, n, r));
              }
            }
          }),
          (e.prototype.notifyComplete = function(t) {
            0 == (this.active -= 1) && this.destination.complete();
          }),
          (e.prototype.notifyNext = function(t, e, r, n, i) {
            var o = this.values,
              s = o[r],
              a = this.toRespond
                ? s === c
                  ? --this.toRespond
                  : this.toRespond
                : 0;
            (o[r] = e),
              0 === a &&
                (this.project
                  ? this._tryProject(o)
                  : this.destination.next(o.slice()));
          }),
          (e.prototype._tryProject = function(t) {
            var e;
            try {
              e = this.project.apply(this, t);
            } catch (t) {
              return void this.destination.error(t);
            }
            this.destination.next(e);
          }),
          e
        );
      })(s.OuterSubscriber);
      e.CombineLatestSubscriber = f;
    },
    function(t, e, r) {
      "use strict";
      e.isScheduler = function(t) {
        return t && "function" == typeof t.schedule;
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(188);
      n.Observable.prototype.count = i.count;
    },
    function(t, e, r) {
      "use strict";
      var n = r(189);
      e.count = function(t) {
        return n.count(t)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2);
      e.count = function(t) {
        return function(e) {
          return e.lift(new o(t, e));
        };
      };
      var o = (function() {
          function t(t, e) {
            (this.predicate = t), (this.source = e);
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(new s(t, this.predicate, this.source));
            }),
            t
          );
        })(),
        s = (function(t) {
          function e(e, r, n) {
            t.call(this, e),
              (this.predicate = r),
              (this.source = n),
              (this.count = 0),
              (this.index = 0);
          }
          return (
            n(e, t),
            (e.prototype._next = function(t) {
              this.predicate ? this._tryPredicate(t) : this.count++;
            }),
            (e.prototype._tryPredicate = function(t) {
              var e;
              try {
                e = this.predicate(t, this.index++, this.source);
              } catch (t) {
                return void this.destination.error(t);
              }
              e && this.count++;
            }),
            (e.prototype._complete = function() {
              this.destination.next(this.count), this.destination.complete();
            }),
            e
          );
        })(i.Subscriber);
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(191);
      n.Observable.prototype.delay = i.delay;
    },
    function(t, e, r) {
      "use strict";
      var n = r(37),
        i = r(192);
      e.delay = function(t, e) {
        return void 0 === e && (e = n.async), i.delay(t, e)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(37),
        o = r(193),
        s = r(2),
        a = r(107);
      e.delay = function(t, e) {
        void 0 === e && (e = i.async);
        var r = o.isDate(t) ? +t - e.now() : Math.abs(t);
        return function(t) {
          return t.lift(new c(r, e));
        };
      };
      var c = (function() {
          function t(t, e) {
            (this.delay = t), (this.scheduler = e);
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(new u(t, this.delay, this.scheduler));
            }),
            t
          );
        })(),
        u = (function(t) {
          function e(e, r, n) {
            t.call(this, e),
              (this.delay = r),
              (this.scheduler = n),
              (this.queue = []),
              (this.active = !1),
              (this.errored = !1);
          }
          return (
            n(e, t),
            (e.dispatch = function(t) {
              for (
                var e = t.source,
                  r = e.queue,
                  n = t.scheduler,
                  i = t.destination;
                r.length > 0 && r[0].time - n.now() <= 0;

              )
                r.shift().notification.observe(i);
              if (r.length > 0) {
                var o = Math.max(0, r[0].time - n.now());
                this.schedule(t, o);
              } else e.active = !1;
            }),
            (e.prototype._schedule = function(t) {
              (this.active = !0),
                this.add(
                  t.schedule(e.dispatch, this.delay, {
                    source: this,
                    destination: this.destination,
                    scheduler: t
                  })
                );
            }),
            (e.prototype.scheduleNotification = function(t) {
              if (!0 !== this.errored) {
                var e = this.scheduler,
                  r = new f(e.now() + this.delay, t);
                this.queue.push(r), !1 === this.active && this._schedule(e);
              }
            }),
            (e.prototype._next = function(t) {
              this.scheduleNotification(a.Notification.createNext(t));
            }),
            (e.prototype._error = function(t) {
              (this.errored = !0), (this.queue = []), this.destination.error(t);
            }),
            (e.prototype._complete = function() {
              this.scheduleNotification(a.Notification.createComplete());
            }),
            e
          );
        })(s.Subscriber),
        f = function(t, e) {
          (this.time = t), (this.notification = e);
        };
    },
    function(t, e, r) {
      "use strict";
      e.isDate = function(t) {
        return t instanceof Date && !isNaN(+t);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(195);
      (n.Observable.prototype.do = i._do), (n.Observable.prototype._do = i._do);
    },
    function(t, e, r) {
      "use strict";
      var n = r(196);
      e._do = function(t, e, r) {
        return n.tap(t, e, r)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2);
      e.tap = function(t, e, r) {
        return function(n) {
          return n.lift(new o(t, e, r));
        };
      };
      var o = (function() {
          function t(t, e, r) {
            (this.nextOrObserver = t), (this.error = e), (this.complete = r);
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(
                new s(t, this.nextOrObserver, this.error, this.complete)
              );
            }),
            t
          );
        })(),
        s = (function(t) {
          function e(e, r, n, o) {
            t.call(this, e);
            var s = new i.Subscriber(r, n, o);
            (s.syncErrorThrowable = !0), this.add(s), (this.safeSubscriber = s);
          }
          return (
            n(e, t),
            (e.prototype._next = function(t) {
              var e = this.safeSubscriber;
              e.next(t),
                e.syncErrorThrown
                  ? this.destination.error(e.syncErrorValue)
                  : this.destination.next(t);
            }),
            (e.prototype._error = function(t) {
              var e = this.safeSubscriber;
              e.error(t),
                e.syncErrorThrown
                  ? this.destination.error(e.syncErrorValue)
                  : this.destination.error(t);
            }),
            (e.prototype._complete = function() {
              var t = this.safeSubscriber;
              t.complete(),
                t.syncErrorThrown
                  ? this.destination.error(t.syncErrorValue)
                  : this.destination.complete();
            }),
            e
          );
        })(i.Subscriber);
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(198);
      n.Observable.prototype.filter = i.filter;
    },
    function(t, e, r) {
      "use strict";
      var n = r(199);
      e.filter = function(t, e) {
        return n.filter(t, e)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2);
      e.filter = function(t, e) {
        return function(r) {
          return r.lift(new o(t, e));
        };
      };
      var o = (function() {
          function t(t, e) {
            (this.predicate = t), (this.thisArg = e);
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(new s(t, this.predicate, this.thisArg));
            }),
            t
          );
        })(),
        s = (function(t) {
          function e(e, r, n) {
            t.call(this, e),
              (this.predicate = r),
              (this.thisArg = n),
              (this.count = 0);
          }
          return (
            n(e, t),
            (e.prototype._next = function(t) {
              var e;
              try {
                e = this.predicate.call(this.thisArg, t, this.count++);
              } catch (t) {
                return void this.destination.error(t);
              }
              e && this.destination.next(t);
            }),
            e
          );
        })(i.Subscriber);
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(201);
      n.Observable.prototype.map = i.map;
    },
    function(t, e, r) {
      "use strict";
      var n = r(108);
      e.map = function(t, e) {
        return n.map(t, e)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(203);
      (n.Observable.prototype.mergeMap = i.mergeMap),
        (n.Observable.prototype.flatMap = i.mergeMap);
    },
    function(t, e, r) {
      "use strict";
      var n = r(109);
      e.mergeMap = function(t, e, r) {
        return (
          void 0 === r && (r = Number.POSITIVE_INFINITY),
          n.mergeMap(t, e, r)(this)
        );
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(205);
      n.Observable.prototype.concatMap = i.concatMap;
    },
    function(t, e, r) {
      "use strict";
      var n = r(206);
      e.concatMap = function(t, e) {
        return n.concatMap(t, e)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(109);
      e.concatMap = function(t, e) {
        return n.mergeMap(t, e, 1);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(208);
      n.Observable.prototype.retryWhen = i.retryWhen;
    },
    function(t, e, r) {
      "use strict";
      var n = r(209);
      e.retryWhen = function(t) {
        return n.retryWhen(t)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(34),
        o = r(57),
        s = r(36),
        a = r(38),
        c = r(39);
      e.retryWhen = function(t) {
        return function(e) {
          return e.lift(new u(t, e));
        };
      };
      var u = (function() {
          function t(t, e) {
            (this.notifier = t), (this.source = e);
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(new f(t, this.notifier, this.source));
            }),
            t
          );
        })(),
        f = (function(t) {
          function e(e, r, n) {
            t.call(this, e), (this.notifier = r), (this.source = n);
          }
          return (
            n(e, t),
            (e.prototype.error = function(e) {
              if (!this.isStopped) {
                var r = this.errors,
                  n = this.retries,
                  a = this.retriesSubscription;
                if (n) (this.errors = null), (this.retriesSubscription = null);
                else {
                  if (
                    ((r = new i.Subject()),
                    (n = o.tryCatch(this.notifier)(r)) === s.errorObject)
                  )
                    return t.prototype.error.call(this, s.errorObject.e);
                  a = c.subscribeToResult(this, n);
                }
                this._unsubscribeAndRecycle(),
                  (this.errors = r),
                  (this.retries = n),
                  (this.retriesSubscription = a),
                  r.next(e);
              }
            }),
            (e.prototype._unsubscribe = function() {
              var t = this.errors,
                e = this.retriesSubscription;
              t && (t.unsubscribe(), (this.errors = null)),
                e && (e.unsubscribe(), (this.retriesSubscription = null)),
                (this.retries = null);
            }),
            (e.prototype.notifyNext = function(t, e, r, n, i) {
              var o = this.errors,
                s = this.retries,
                a = this.retriesSubscription;
              (this.errors = null),
                (this.retries = null),
                (this.retriesSubscription = null),
                this._unsubscribeAndRecycle(),
                (this.errors = o),
                (this.retries = s),
                (this.retriesSubscription = a),
                this.source.subscribe(this);
            }),
            e
          );
        })(a.OuterSubscriber);
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(211);
      n.Observable.prototype.share = i.share;
    },
    function(t, e, r) {
      "use strict";
      var n = r(212);
      e.share = function() {
        return n.share()(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(213),
        i = r(110),
        o = r(34);
      function s() {
        return new o.Subject();
      }
      e.share = function() {
        return function(t) {
          return i.refCount()(n.multicast(s)(t));
        };
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(214);
      e.multicast = function(t, e) {
        return function(r) {
          var o;
          if (
            ((o =
              "function" == typeof t
                ? t
                : function() {
                    return t;
                  }),
            "function" == typeof e)
          )
            return r.lift(new i(o, e));
          var s = Object.create(r, n.connectableObservableDescriptor);
          return (s.source = r), (s.subjectFactory = o), s;
        };
      };
      var i = (function() {
        function t(t, e) {
          (this.subjectFactory = t), (this.selector = e);
        }
        return (
          (t.prototype.call = function(t, e) {
            var r = this.selector,
              n = this.subjectFactory(),
              i = r(n).subscribe(t);
            return i.add(e.subscribe(n)), i;
          }),
          t
        );
      })();
      e.MulticastOperator = i;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(34),
        o = r(0),
        s = r(2),
        a = r(26),
        c = r(110),
        u = (function(t) {
          function e(e, r) {
            t.call(this),
              (this.source = e),
              (this.subjectFactory = r),
              (this._refCount = 0),
              (this._isComplete = !1);
          }
          return (
            n(e, t),
            (e.prototype._subscribe = function(t) {
              return this.getSubject().subscribe(t);
            }),
            (e.prototype.getSubject = function() {
              var t = this._subject;
              return (
                (t && !t.isStopped) || (this._subject = this.subjectFactory()),
                this._subject
              );
            }),
            (e.prototype.connect = function() {
              var t = this._connection;
              return (
                t ||
                  ((this._isComplete = !1),
                  (t = this._connection = new a.Subscription()).add(
                    this.source.subscribe(new l(this.getSubject(), this))
                  ),
                  t.closed
                    ? ((this._connection = null), (t = a.Subscription.EMPTY))
                    : (this._connection = t)),
                t
              );
            }),
            (e.prototype.refCount = function() {
              return c.refCount()(this);
            }),
            e
          );
        })(o.Observable);
      e.ConnectableObservable = u;
      var f = u.prototype;
      e.connectableObservableDescriptor = {
        operator: { value: null },
        _refCount: { value: 0, writable: !0 },
        _subject: { value: null, writable: !0 },
        _connection: { value: null, writable: !0 },
        _subscribe: { value: f._subscribe },
        _isComplete: { value: f._isComplete, writable: !0 },
        getSubject: { value: f.getSubject },
        connect: { value: f.connect },
        refCount: { value: f.refCount }
      };
      var l = (function(t) {
          function e(e, r) {
            t.call(this, e), (this.connectable = r);
          }
          return (
            n(e, t),
            (e.prototype._error = function(e) {
              this._unsubscribe(), t.prototype._error.call(this, e);
            }),
            (e.prototype._complete = function() {
              (this.connectable._isComplete = !0),
                this._unsubscribe(),
                t.prototype._complete.call(this);
            }),
            (e.prototype._unsubscribe = function() {
              var t = this.connectable;
              if (t) {
                this.connectable = null;
                var e = t._connection;
                (t._refCount = 0),
                  (t._subject = null),
                  (t._connection = null),
                  e && e.unsubscribe();
              }
            }),
            e
          );
        })(i.SubjectSubscriber),
        h =
          ((function() {
            function t(t) {
              this.connectable = t;
            }
            t.prototype.call = function(t, e) {
              var r = this.connectable;
              r._refCount++;
              var n = new h(t, r),
                i = e.subscribe(n);
              return n.closed || (n.connection = r.connect()), i;
            };
          })(),
          (function(t) {
            function e(e, r) {
              t.call(this, e), (this.connectable = r);
            }
            return (
              n(e, t),
              (e.prototype._unsubscribe = function() {
                var t = this.connectable;
                if (t) {
                  this.connectable = null;
                  var e = t._refCount;
                  if (e <= 0) this.connection = null;
                  else if (((t._refCount = e - 1), e > 1))
                    this.connection = null;
                  else {
                    var r = this.connection,
                      n = t._connection;
                    (this.connection = null),
                      !n || (r && n !== r) || n.unsubscribe();
                  }
                } else this.connection = null;
              }),
              e
            );
          })(s.Subscriber));
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(216);
      n.Observable.prototype.take = i.take;
    },
    function(t, e, r) {
      "use strict";
      var n = r(217);
      e.take = function(t) {
        return n.take(t)(this);
      };
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2),
        o = r(218),
        s = r(40);
      e.take = function(t) {
        return function(e) {
          return 0 === t ? new s.EmptyObservable() : e.lift(new a(t));
        };
      };
      var a = (function() {
          function t(t) {
            if (((this.total = t), this.total < 0))
              throw new o.ArgumentOutOfRangeError();
          }
          return (
            (t.prototype.call = function(t, e) {
              return e.subscribe(new c(t, this.total));
            }),
            t
          );
        })(),
        c = (function(t) {
          function e(e, r) {
            t.call(this, e), (this.total = r), (this.count = 0);
          }
          return (
            n(e, t),
            (e.prototype._next = function(t) {
              var e = this.total,
                r = ++this.count;
              r <= e &&
                (this.destination.next(t),
                r === e && (this.destination.complete(), this.unsubscribe()));
            }),
            e
          );
        })(i.Subscriber);
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e() {
            var e = t.call(this, "argument out of range");
            (this.name = e.name = "ArgumentOutOfRangeError"),
              (this.stack = e.stack),
              (this.message = e.message);
          }
          return n(e, t), e;
        })(Error);
      e.ArgumentOutOfRangeError = i;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(220);
      n.Observable.ajax = i.ajax;
    },
    function(t, e, r) {
      "use strict";
      var n = r(221);
      e.ajax = n.AjaxObservable.create;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(7),
        o = r(57),
        s = r(36),
        a = r(0),
        c = r(2),
        u = r(108);
      function f() {
        if (i.root.XMLHttpRequest) return new i.root.XMLHttpRequest();
        if (i.root.XDomainRequest) return new i.root.XDomainRequest();
        throw new Error("CORS is not supported by your browser");
      }
      function l(t, e) {
        return (
          void 0 === e && (e = null),
          new g({ method: "GET", url: t, headers: e })
        );
      }
      function h(t, e, r) {
        return new g({ method: "POST", url: t, body: e, headers: r });
      }
      function p(t, e) {
        return new g({ method: "DELETE", url: t, headers: e });
      }
      function d(t, e, r) {
        return new g({ method: "PUT", url: t, body: e, headers: r });
      }
      function y(t, e, r) {
        return new g({ method: "PATCH", url: t, body: e, headers: r });
      }
      (e.ajaxGet = l),
        (e.ajaxPost = h),
        (e.ajaxDelete = p),
        (e.ajaxPut = d),
        (e.ajaxPatch = y);
      var v = u.map(function(t, e) {
        return t.response;
      });
      function b(t, e) {
        return v(
          new g({ method: "GET", url: t, responseType: "json", headers: e })
        );
      }
      e.ajaxGetJSON = b;
      var g = (function(t) {
        function e(e) {
          t.call(this);
          var r = {
            async: !0,
            createXHR: function() {
              return this.crossDomain
                ? f.call(this)
                : (function() {
                    if (i.root.XMLHttpRequest)
                      return new i.root.XMLHttpRequest();
                    var t = void 0;
                    try {
                      for (
                        var e = [
                            "Msxml2.XMLHTTP",
                            "Microsoft.XMLHTTP",
                            "Msxml2.XMLHTTP.4.0"
                          ],
                          r = 0;
                        r < 3;
                        r++
                      )
                        try {
                          if (((t = e[r]), new i.root.ActiveXObject(t))) break;
                        } catch (t) {}
                      return new i.root.ActiveXObject(t);
                    } catch (t) {
                      throw new Error(
                        "XMLHttpRequest is not supported by your browser"
                      );
                    }
                  })();
            },
            crossDomain: !1,
            withCredentials: !1,
            headers: {},
            method: "GET",
            responseType: "json",
            timeout: 0
          };
          if ("string" == typeof e) r.url = e;
          else for (var n in e) e.hasOwnProperty(n) && (r[n] = e[n]);
          this.request = r;
        }
        var r;
        return (
          n(e, t),
          (e.prototype._subscribe = function(t) {
            return new m(t, this.request);
          }),
          (e.create =
            (((r = function(t) {
              return new e(t);
            }).get = l),
            (r.post = h),
            (r.delete = p),
            (r.put = d),
            (r.patch = y),
            (r.getJSON = b),
            r)),
          e
        );
      })(a.Observable);
      e.AjaxObservable = g;
      var m = (function(t) {
        function e(e, r) {
          t.call(this, e), (this.request = r), (this.done = !1);
          var n = (r.headers = r.headers || {});
          r.crossDomain ||
            n["X-Requested-With"] ||
            (n["X-Requested-With"] = "XMLHttpRequest"),
            "Content-Type" in n ||
              (i.root.FormData && r.body instanceof i.root.FormData) ||
              void 0 === r.body ||
              (n["Content-Type"] =
                "application/x-www-form-urlencoded; charset=UTF-8"),
            (r.body = this.serializeBody(r.body, r.headers["Content-Type"])),
            this.send();
        }
        return (
          n(e, t),
          (e.prototype.next = function(t) {
            this.done = !0;
            var e = this.xhr,
              r = this.request,
              n = this.destination,
              i = new w(t, e, r);
            n.next(i);
          }),
          (e.prototype.send = function() {
            var t = this.request,
              e = this.request,
              r = e.user,
              n = e.method,
              i = e.url,
              a = e.async,
              c = e.password,
              u = e.headers,
              f = e.body,
              l = t.createXHR,
              h = o.tryCatch(l).call(t);
            if (h === s.errorObject) this.error(s.errorObject.e);
            else {
              (this.xhr = h), this.setupEvents(h, t);
              if (
                (r
                  ? o.tryCatch(h.open).call(h, n, i, a, r, c)
                  : o.tryCatch(h.open).call(h, n, i, a)) === s.errorObject
              )
                return this.error(s.errorObject.e), null;
              if (
                (a &&
                  ((h.timeout = t.timeout), (h.responseType = t.responseType)),
                "withCredentials" in h &&
                  (h.withCredentials = !!t.withCredentials),
                this.setHeaders(h, u),
                (f
                  ? o.tryCatch(h.send).call(h, f)
                  : o.tryCatch(h.send).call(h)) === s.errorObject)
              )
                return this.error(s.errorObject.e), null;
            }
            return h;
          }),
          (e.prototype.serializeBody = function(t, e) {
            if (!t || "string" == typeof t) return t;
            if (i.root.FormData && t instanceof i.root.FormData) return t;
            if (e) {
              var r = e.indexOf(";");
              -1 !== r && (e = e.substring(0, r));
            }
            switch (e) {
              case "application/x-www-form-urlencoded":
                return Object.keys(t)
                  .map(function(e) {
                    return encodeURI(e) + "=" + encodeURI(t[e]);
                  })
                  .join("&");
              case "application/json":
                return JSON.stringify(t);
              default:
                return t;
            }
          }),
          (e.prototype.setHeaders = function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && t.setRequestHeader(r, e[r]);
          }),
          (e.prototype.setupEvents = function(t, e) {
            var r = e.progressSubscriber;
            function n(t) {
              var e = n,
                r = e.subscriber,
                i = e.progressSubscriber,
                o = e.request;
              i && i.error(t), r.error(new x(this, o));
            }
            if (
              ((t.ontimeout = n),
              (n.request = e),
              (n.subscriber = this),
              (n.progressSubscriber = r),
              t.upload && "withCredentials" in t)
            ) {
              var o, s;
              if (r)
                (o = function(t) {
                  o.progressSubscriber.next(t);
                }),
                  i.root.XDomainRequest
                    ? (t.onprogress = o)
                    : (t.upload.onprogress = o),
                  (o.progressSubscriber = r);
              (s = function(t) {
                var e = s,
                  r = e.progressSubscriber,
                  n = e.subscriber,
                  i = e.request;
                r && r.error(t), n.error(new _("ajax error", this, i));
              }),
                (t.onerror = s),
                (s.request = e),
                (s.subscriber = this),
                (s.progressSubscriber = r);
            }
            function a(t) {
              var e = a,
                r = e.subscriber,
                n = e.progressSubscriber,
                i = e.request;
              if (4 === this.readyState) {
                var o = 1223 === this.status ? 204 : this.status,
                  s =
                    "text" === this.responseType
                      ? this.response || this.responseText
                      : this.response;
                0 === o && (o = s ? 200 : 0),
                  200 <= o && o < 300
                    ? (n && n.complete(), r.next(t), r.complete())
                    : (n && n.error(t),
                      r.error(new _("ajax error " + o, this, i)));
              }
            }
            (t.onreadystatechange = a),
              (a.subscriber = this),
              (a.progressSubscriber = r),
              (a.request = e);
          }),
          (e.prototype.unsubscribe = function() {
            var e = this.done,
              r = this.xhr;
            !e &&
              r &&
              4 !== r.readyState &&
              "function" == typeof r.abort &&
              r.abort(),
              t.prototype.unsubscribe.call(this);
          }),
          e
        );
      })(c.Subscriber);
      e.AjaxSubscriber = m;
      var w = function(t, e, r) {
        (this.originalEvent = t),
          (this.xhr = e),
          (this.request = r),
          (this.status = e.status),
          (this.responseType = e.responseType || r.responseType),
          (this.response = S(this.responseType, e));
      };
      e.AjaxResponse = w;
      var _ = (function(t) {
        function e(e, r, n) {
          t.call(this, e),
            (this.message = e),
            (this.xhr = r),
            (this.request = n),
            (this.status = r.status),
            (this.responseType = r.responseType || n.responseType),
            (this.response = S(this.responseType, r));
        }
        return n(e, t), e;
      })(Error);
      function S(t, e) {
        switch (t) {
          case "json":
            return "response" in e
              ? e.responseType
                ? e.response
                : JSON.parse(e.response || e.responseText || "null")
              : JSON.parse(e.responseText || "null");
          case "xml":
            return e.responseXML;
          case "text":
          default:
            return "response" in e ? e.response : e.responseText;
        }
      }
      e.AjaxError = _;
      var x = (function(t) {
        function e(e, r) {
          t.call(this, "ajax timeout", e, r);
        }
        return n(e, t), e;
      })(_);
      e.AjaxTimeoutError = x;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(223);
      n.Observable.empty = i.empty;
    },
    function(t, e, r) {
      "use strict";
      var n = r(40);
      e.empty = n.EmptyObservable.create;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(225);
      n.Observable.from = i.from;
    },
    function(t, e, r) {
      "use strict";
      var n = r(226);
      e.from = n.FromObservable.create;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(35),
        o = r(104),
        s = r(105),
        a = r(227),
        c = r(228),
        u = r(61),
        f = r(229),
        l = r(60),
        h = r(0),
        p = r(230),
        d = r(59),
        y = (function(t) {
          function e(e, r) {
            t.call(this, null), (this.ish = e), (this.scheduler = r);
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              if (null != t) {
                if ("function" == typeof t[d.observable])
                  return t instanceof h.Observable && !r ? t : new e(t, r);
                if (i.isArray(t)) return new u.ArrayObservable(t, r);
                if (s.isPromise(t)) return new a.PromiseObservable(t, r);
                if ("function" == typeof t[l.iterator] || "string" == typeof t)
                  return new c.IteratorObservable(t, r);
                if (o.isArrayLike(t)) return new f.ArrayLikeObservable(t, r);
              }
              throw new TypeError(
                ((null !== t && typeof t) || t) + " is not observable"
              );
            }),
            (e.prototype._subscribe = function(t) {
              var e = this.ish,
                r = this.scheduler;
              return null == r
                ? e[d.observable]().subscribe(t)
                : e[d.observable]().subscribe(
                    new p.ObserveOnSubscriber(t, r, 0)
                  );
            }),
            e
          );
        })(h.Observable);
      e.FromObservable = y;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(7),
        o = (function(t) {
          function e(e, r) {
            t.call(this), (this.promise = e), (this.scheduler = r);
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              return new e(t, r);
            }),
            (e.prototype._subscribe = function(t) {
              var e = this,
                r = this.promise,
                n = this.scheduler;
              if (null == n)
                this._isScalar
                  ? t.closed || (t.next(this.value), t.complete())
                  : r
                      .then(
                        function(r) {
                          (e.value = r),
                            (e._isScalar = !0),
                            t.closed || (t.next(r), t.complete());
                        },
                        function(e) {
                          t.closed || t.error(e);
                        }
                      )
                      .then(null, function(t) {
                        i.root.setTimeout(function() {
                          throw t;
                        });
                      });
              else if (this._isScalar) {
                if (!t.closed)
                  return n.schedule(s, 0, { value: this.value, subscriber: t });
              } else
                r.then(
                  function(r) {
                    (e.value = r),
                      (e._isScalar = !0),
                      t.closed ||
                        t.add(n.schedule(s, 0, { value: r, subscriber: t }));
                  },
                  function(e) {
                    t.closed ||
                      t.add(n.schedule(a, 0, { err: e, subscriber: t }));
                  }
                ).then(null, function(t) {
                  i.root.setTimeout(function() {
                    throw t;
                  });
                });
            }),
            e
          );
        })(r(0).Observable);
      function s(t) {
        var e = t.value,
          r = t.subscriber;
        r.closed || (r.next(e), r.complete());
      }
      function a(t) {
        var e = t.err,
          r = t.subscriber;
        r.closed || r.error(e);
      }
      e.PromiseObservable = o;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(7),
        o = r(0),
        s = r(60),
        a = (function(t) {
          function e(e, r) {
            if ((t.call(this), (this.scheduler = r), null == e))
              throw new Error("iterator cannot be null.");
            this.iterator = (function(t) {
              var e = t[s.iterator];
              if (!e && "string" == typeof t) return new c(t);
              if (!e && void 0 !== t.length) return new u(t);
              if (!e) throw new TypeError("object is not iterable");
              return t[s.iterator]();
            })(e);
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              return new e(t, r);
            }),
            (e.dispatch = function(t) {
              var e = t.index,
                r = t.hasError,
                n = t.iterator,
                i = t.subscriber;
              if (r) i.error(t.error);
              else {
                var o = n.next();
                o.done
                  ? i.complete()
                  : (i.next(o.value),
                    (t.index = e + 1),
                    i.closed
                      ? "function" == typeof n.return && n.return()
                      : this.schedule(t));
              }
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.iterator,
                n = this.scheduler;
              if (n)
                return n.schedule(e.dispatch, 0, {
                  index: 0,
                  iterator: r,
                  subscriber: t
                });
              for (;;) {
                var i = r.next();
                if (i.done) {
                  t.complete();
                  break;
                }
                if ((t.next(i.value), t.closed)) {
                  "function" == typeof r.return && r.return();
                  break;
                }
              }
            }),
            e
          );
        })(o.Observable);
      e.IteratorObservable = a;
      var c = (function() {
          function t(t, e, r) {
            void 0 === e && (e = 0),
              void 0 === r && (r = t.length),
              (this.str = t),
              (this.idx = e),
              (this.len = r);
          }
          return (
            (t.prototype[s.iterator] = function() {
              return this;
            }),
            (t.prototype.next = function() {
              return this.idx < this.len
                ? { done: !1, value: this.str.charAt(this.idx++) }
                : { done: !0, value: void 0 };
            }),
            t
          );
        })(),
        u = (function() {
          function t(t, e, r) {
            void 0 === e && (e = 0),
              void 0 === r &&
                (r = (function(t) {
                  var e = +t.length;
                  if (isNaN(e)) return 0;
                  if (
                    0 === e ||
                    ((r = e), "number" != typeof r || !i.root.isFinite(r))
                  )
                    return e;
                  var r;
                  if (
                    (e =
                      (function(t) {
                        var e = +t;
                        if (0 === e) return e;
                        if (isNaN(e)) return e;
                        return e < 0 ? -1 : 1;
                      })(e) * Math.floor(Math.abs(e))) <= 0
                  )
                    return 0;
                  if (e > f) return f;
                  return e;
                })(t)),
              (this.arr = t),
              (this.idx = e),
              (this.len = r);
          }
          return (
            (t.prototype[s.iterator] = function() {
              return this;
            }),
            (t.prototype.next = function() {
              return this.idx < this.len
                ? { done: !1, value: this.arr[this.idx++] }
                : { done: !0, value: void 0 };
            }),
            t
          );
        })();
      var f = Math.pow(2, 53) - 1;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(0),
        o = r(106),
        s = r(40),
        a = (function(t) {
          function e(e, r) {
            t.call(this),
              (this.arrayLike = e),
              (this.scheduler = r),
              r ||
                1 !== e.length ||
                ((this._isScalar = !0), (this.value = e[0]));
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              var n = t.length;
              return 0 === n
                ? new s.EmptyObservable()
                : 1 === n
                ? new o.ScalarObservable(t[0], r)
                : new e(t, r);
            }),
            (e.dispatch = function(t) {
              var e = t.arrayLike,
                r = t.index,
                n = t.length,
                i = t.subscriber;
              i.closed ||
                (r >= n
                  ? i.complete()
                  : (i.next(e[r]), (t.index = r + 1), this.schedule(t)));
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.arrayLike,
                n = this.scheduler,
                i = r.length;
              if (n)
                return n.schedule(e.dispatch, 0, {
                  arrayLike: r,
                  index: 0,
                  length: i,
                  subscriber: t
                });
              for (var o = 0; o < i && !t.closed; o++) t.next(r[o]);
              t.complete();
            }),
            e
          );
        })(i.Observable);
      e.ArrayLikeObservable = a;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(2),
        o = r(107);
      e.observeOn = function(t, e) {
        return (
          void 0 === e && (e = 0),
          function(r) {
            return r.lift(new s(t, e));
          }
        );
      };
      var s = (function() {
        function t(t, e) {
          void 0 === e && (e = 0), (this.scheduler = t), (this.delay = e);
        }
        return (
          (t.prototype.call = function(t, e) {
            return e.subscribe(new a(t, this.scheduler, this.delay));
          }),
          t
        );
      })();
      e.ObserveOnOperator = s;
      var a = (function(t) {
        function e(e, r, n) {
          void 0 === n && (n = 0),
            t.call(this, e),
            (this.scheduler = r),
            (this.delay = n);
        }
        return (
          n(e, t),
          (e.dispatch = function(t) {
            var e = t.notification,
              r = t.destination;
            e.observe(r), this.unsubscribe();
          }),
          (e.prototype.scheduleMessage = function(t) {
            this.add(
              this.scheduler.schedule(
                e.dispatch,
                this.delay,
                new c(t, this.destination)
              )
            );
          }),
          (e.prototype._next = function(t) {
            this.scheduleMessage(o.Notification.createNext(t));
          }),
          (e.prototype._error = function(t) {
            this.scheduleMessage(o.Notification.createError(t));
          }),
          (e.prototype._complete = function() {
            this.scheduleMessage(o.Notification.createComplete());
          }),
          e
        );
      })(i.Subscriber);
      e.ObserveOnSubscriber = a;
      var c = function(t, e) {
        (this.notification = t), (this.destination = e);
      };
      e.ObserveOnMessage = c;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(232);
      n.Observable.interval = i.interval;
    },
    function(t, e, r) {
      "use strict";
      var n = r(233);
      e.interval = n.IntervalObservable.create;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = r(234),
        o = r(0),
        s = r(37),
        a = (function(t) {
          function e(e, r) {
            void 0 === e && (e = 0),
              void 0 === r && (r = s.async),
              t.call(this),
              (this.period = e),
              (this.scheduler = r),
              (!i.isNumeric(e) || e < 0) && (this.period = 0),
              (r && "function" == typeof r.schedule) ||
                (this.scheduler = s.async);
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              return (
                void 0 === t && (t = 0),
                void 0 === r && (r = s.async),
                new e(t, r)
              );
            }),
            (e.dispatch = function(t) {
              var e = t.index,
                r = t.subscriber,
                n = t.period;
              r.next(e), r.closed || ((t.index += 1), this.schedule(t, n));
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.period,
                n = this.scheduler;
              t.add(
                n.schedule(e.dispatch, r, {
                  index: 0,
                  subscriber: t,
                  period: r
                })
              );
            }),
            e
          );
        })(o.Observable);
      e.IntervalObservable = a;
    },
    function(t, e, r) {
      "use strict";
      var n = r(35);
      e.isNumeric = function(t) {
        return !n.isArray(t) && t - parseFloat(t) + 1 >= 0;
      };
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(236);
      n.Observable.of = i.of;
    },
    function(t, e, r) {
      "use strict";
      var n = r(61);
      e.of = n.ArrayObservable.of;
    },
    function(t, e, r) {
      "use strict";
      var n = r(0),
        i = r(238);
      n.Observable.throw = i._throw;
    },
    function(t, e, r) {
      "use strict";
      var n = r(239);
      e._throw = n.ErrorObservable.create;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__extends) ||
          function(t, e) {
            for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
            function n() {
              this.constructor = t;
            }
            t.prototype =
              null === e
                ? Object.create(e)
                : ((n.prototype = e.prototype), new n());
          },
        i = (function(t) {
          function e(e, r) {
            t.call(this), (this.error = e), (this.scheduler = r);
          }
          return (
            n(e, t),
            (e.create = function(t, r) {
              return new e(t, r);
            }),
            (e.dispatch = function(t) {
              var e = t.error;
              t.subscriber.error(e);
            }),
            (e.prototype._subscribe = function(t) {
              var r = this.error,
                n = this.scheduler;
              if (((t.syncErrorThrowable = !0), n))
                return n.schedule(e.dispatch, 0, { error: r, subscriber: t });
              t.error(r);
            }),
            e
          );
        })(r(0).Observable);
      e.ErrorObservable = i;
    },
    function(t, e, r) {
      "use strict";
      var n = r(29);
      Object.defineProperty(e, "__esModule", { value: !0 }),
        (e.default = function(t) {
          var e = [];
          return (
            t.forEach(function(t) {
              for (
                var r = (0, i.default)(t),
                  n = r.extname,
                  o = r.name,
                  s = 0,
                  a = t;
                e.includes(a);

              )
                a =
                  [o, "(".concat(++s, ")")]
                    .filter(function(t) {
                      return t;
                    })
                    .join(" ") + n;
              e.push(a);
            }),
            e
          );
        });
      var i = n(r(241));
    },
    function(t, e, r) {
      "use strict";
      var n = r(29);
      Object.defineProperty(e, "__esModule", { value: !0 }),
        (e.default = function(t) {
          if (t) {
            if (~t.indexOf(".")) {
              var e = t.split(".").reverse(),
                r = (0, i.default)(e),
                n = r[0],
                o = r.slice(1);
              return { extname: "." + n, name: o.reverse().join(".") };
            }
            return { extname: "", name: t };
          }
          return { extname: "", name: "" };
        });
      var i = n(r(242));
    },
    function(t, e, r) {
      var n = r(243),
        i = r(111),
        o = r(244);
      t.exports = function(t) {
        return n(t) || i(t) || o();
      };
    },
    function(t, e) {
      t.exports = function(t) {
        if (Array.isArray(t)) return t;
      };
    },
    function(t, e) {
      t.exports = function() {
        throw new TypeError(
          "Invalid attempt to destructure non-iterable instance"
        );
      };
    },
    function(t, e, r) {
      "use strict";
      (function(t) {
        var n = r(248),
          i = r(29);
        Object.defineProperty(e, "__esModule", { value: !0 }),
          (e.DirectLineStreaming = void 0);
        var o = i(r(72)),
          s = i(r(249)),
          a = i(r(251)),
          c = i(r(254)),
          u = i(r(73)),
          f = i(r(74)),
          l = i(r(75)),
          h = r(99),
          p = r(0),
          d = n(r(255)),
          y = i(r(285)),
          v = r(71),
          b = (function() {
            function t(e, r, n) {
              (0, u.default)(this, t),
                (0, l.default)(this, "connectionStatus$", void 0),
                (0, l.default)(this, "subscriber", void 0),
                (0, l.default)(this, "shouldQueue", void 0),
                (0, l.default)(this, "activityQueue", []),
                (this.subscriber = e),
                (this.connectionStatus$ = r),
                (this.shouldQueue = n);
            }
            var e;
            return (
              (0, f.default)(t, [
                {
                  key: "setSubscriber",
                  value: function(t) {
                    this.subscriber = t;
                  }
                },
                {
                  key: "processRequest",
                  value:
                    ((e = (0, c.default)(
                      s.default.mark(function t(e, r) {
                        var n, i, o, c, u, f, l, h, p;
                        return s.default.wrap(
                          function(t) {
                            for (;;)
                              switch ((t.prev = t.next)) {
                                case 0:
                                  return (
                                    (n = (0, a.default)(e.streams)),
                                    (i = n.shift()),
                                    (t.next = 4),
                                    i.readAsString()
                                  );
                                case 4:
                                  if (
                                    ((o = t.sent),
                                    1 === (c = JSON.parse(o)).activities.length)
                                  ) {
                                    t.next = 9;
                                    break;
                                  }
                                  return (
                                    this.subscriber.error(
                                      new Error(
                                        "there should be exactly one activity"
                                      )
                                    ),
                                    t.abrupt(
                                      "return",
                                      d.StreamingResponse.create(500)
                                    )
                                  );
                                case 9:
                                  if (
                                    ((u = c.activities[0]), !(n.length > 0))
                                  ) {
                                    t.next = 21;
                                    break;
                                  }
                                  f = (0, a.default)(u.attachments);
                                case 12:
                                  if (!(l = n.shift())) {
                                    t.next = 20;
                                    break;
                                  }
                                  return (t.next = 15), l.readAsString();
                                case 15:
                                  (h = t.sent),
                                    (p = "data:text/plain;base64," + h),
                                    f.push({
                                      contentType: l.contentType,
                                      contentUrl: p
                                    }),
                                    (t.next = 12);
                                  break;
                                case 20:
                                  u.attachments = f;
                                case 21:
                                  return (
                                    this.shouldQueue()
                                      ? this.activityQueue.push(u)
                                      : this.subscriber.next(u),
                                    t.abrupt(
                                      "return",
                                      d.StreamingResponse.create(200)
                                    )
                                  );
                                case 23:
                                case "end":
                                  return t.stop();
                              }
                          },
                          t,
                          this
                        );
                      })
                    )),
                    function(t, r) {
                      return e.apply(this, arguments);
                    })
                },
                {
                  key: "flush",
                  value: function() {
                    var t = this;
                    this.connectionStatus$.subscribe(function(t) {}),
                      this.activityQueue.forEach(function(e) {
                        return t.subscriber.next(e);
                      }),
                      (this.activityQueue = []);
                  }
                }
              ]),
              t
            );
          })(),
          g = (function() {
            function e(t) {
              var r = this;
              (0, u.default)(this, e),
                (0, l.default)(
                  this,
                  "connectionStatus$",
                  new h.BehaviorSubject(v.ConnectionStatus.Uninitialized)
                ),
                (0, l.default)(this, "activity$", void 0),
                (0, l.default)(this, "activitySubscriber", void 0),
                (0, l.default)(this, "theStreamHandler", void 0),
                (0, l.default)(this, "domain", void 0),
                (0, l.default)(this, "conversationId", void 0),
                (0, l.default)(this, "token", void 0),
                (0, l.default)(this, "streamConnection", void 0),
                (0, l.default)(this, "queueActivities", void 0),
                (0, l.default)(this, "_botAgent", ""),
                (this.token = t.token),
                this.refreshToken(),
                (this.domain = t.domain),
                t.conversationId && (this.conversationId = t.conversationId),
                (this._botAgent = this.getBotAgent(t.botAgent)),
                (this.queueActivities = !0),
                (this.activity$ = p.Observable.create(
                  (function() {
                    var t = (0, c.default)(
                      s.default.mark(function t(e) {
                        return s.default.wrap(function(t) {
                          for (;;)
                            switch ((t.prev = t.next)) {
                              case 0:
                                (r.activitySubscriber = e),
                                  (r.theStreamHandler = new b(
                                    e,
                                    r.connectionStatus$,
                                    function() {
                                      return r.queueActivities;
                                    }
                                  )),
                                  r.connectWithRetryAsync();
                              case 3:
                              case "end":
                                return t.stop();
                            }
                        }, t);
                      })
                    );
                    return function(e) {
                      return t.apply(this, arguments);
                    };
                  })()
                ).share());
            }
            var r, n, i, a;
            return (
              (0, f.default)(e, [
                {
                  key: "reconnect",
                  value: function(t) {
                    var e = t.conversationId,
                      r = t.token;
                    (this.conversationId = e),
                      (this.token = r),
                      this.connectAsync();
                  }
                },
                {
                  key: "end",
                  value: function() {
                    this.connectionStatus$.next(v.ConnectionStatus.Ended),
                      this.streamConnection.disconnect();
                  }
                },
                {
                  key: "commonHeaders",
                  value: function() {
                    return {
                      Authorization: "Bearer ".concat(this.token),
                      "x-ms-bot-agent": this._botAgent
                    };
                  }
                },
                {
                  key: "getBotAgent",
                  value: function() {
                    var t =
                        arguments.length > 0 && void 0 !== arguments[0]
                          ? arguments[0]
                          : "",
                      e = "directlineStreaming";
                    return (
                      t && (e += "; ".concat(t)),
                      "".concat("DirectLine/3.0", " (").concat(e, ")")
                    );
                  }
                },
                {
                  key: "refreshToken",
                  value:
                    ((a = (0, c.default)(
                      s.default.mark(function t() {
                        var e,
                          r,
                          n,
                          i,
                          o = arguments;
                        return s.default.wrap(
                          function(t) {
                            for (;;)
                              switch ((t.prev = t.next)) {
                                case 0:
                                  return (
                                    !(o.length > 0 && void 0 !== o[0]) || o[0],
                                    o.length > 1 && void 0 !== o[1] && o[1],
                                    (t.next = 4),
                                    this.waitUntilOnline()
                                  );
                                case 4:
                                  e = 0;
                                case 5:
                                  if (!(e < 3)) {
                                    t.next = 30;
                                    break;
                                  }
                                  return (
                                    e++,
                                    (t.next = 9),
                                    new Promise(function(t) {
                                      return setTimeout(t, 9e5);
                                    })
                                  );
                                case 9:
                                  return (
                                    (t.prev = 9),
                                    (t.next = 12),
                                    (0, y.default)(
                                      "".concat(this.domain, "/tokens/refresh"),
                                      {
                                        method: "POST",
                                        headers: this.commonHeaders()
                                      }
                                    )
                                  );
                                case 12:
                                  if (!(r = t.sent).ok) {
                                    t.next = 22;
                                    break;
                                  }
                                  return (e = 0), (t.next = 17), r.json();
                                case 17:
                                  (n = t.sent),
                                    (i = n.token),
                                    (this.token = i),
                                    (t.next = 23);
                                  break;
                                case 22:
                                  403 === r.status || 403 === r.status
                                    ? (console.error(
                                        "Fatal error while refreshing the token: "
                                          .concat(r.status, " ")
                                          .concat(r.statusText)
                                      ),
                                      this.streamConnection.disconnect())
                                    : console.warn(
                                        "Refresh attempt #"
                                          .concat(e, " failed: ")
                                          .concat(r.status, " ")
                                          .concat(r.statusText)
                                      );
                                case 23:
                                  t.next = 28;
                                  break;
                                case 25:
                                  (t.prev = 25),
                                    (t.t0 = t.catch(9)),
                                    console.warn(
                                      "Refresh attempt #"
                                        .concat(e, " threw an exception: ")
                                        .concat(t.t0)
                                    );
                                case 28:
                                  t.next = 5;
                                  break;
                                case 30:
                                  console.error("Retries exhausted"),
                                    this.streamConnection.disconnect();
                                case 32:
                                case "end":
                                  return t.stop();
                              }
                          },
                          t,
                          this,
                          [[9, 25]]
                        );
                      })
                    )),
                    function() {
                      return a.apply(this, arguments);
                    })
                },
                {
                  key: "postActivity",
                  value: function(t) {
                    var e = this;
                    return "message" === t.type &&
                      t.attachments &&
                      t.attachments.length > 0
                      ? this.postMessageWithAttachments(t)
                      : p.Observable.create(
                          (function() {
                            var r = (0, c.default)(
                              s.default.mark(function r(n) {
                                var i, o, a, c, u, f;
                                return s.default.wrap(
                                  function(r) {
                                    for (;;)
                                      switch ((r.prev = r.next)) {
                                        case 0:
                                          return (
                                            (i = d.StreamingRequest.create(
                                              "POST",
                                              "/v3/directline/conversations/" +
                                                e.conversationId +
                                                "/activities"
                                            )).setBody(JSON.stringify(t)),
                                            (r.next = 4),
                                            e.streamConnection.send(i)
                                          );
                                        case 4:
                                          if (
                                            ((o = r.sent),
                                            (r.prev = 5),
                                            200 === o.statusCode)
                                          ) {
                                            r.next = 8;
                                            break;
                                          }
                                          throw new Error(
                                            "PostActivity returned " +
                                              o.statusCode
                                          );
                                        case 8:
                                          if (1 === (a = o.streams.length)) {
                                            r.next = 11;
                                            break;
                                          }
                                          throw new Error(
                                            "Expected one stream but got " + a
                                          );
                                        case 11:
                                          return (
                                            (r.next = 13),
                                            o.streams[0].readAsString()
                                          );
                                        case 13:
                                          return (
                                            (c = r.sent),
                                            (u = JSON.parse(c)),
                                            (f = u.Id),
                                            r.abrupt("return", n.next(f))
                                          );
                                        case 18:
                                          return (
                                            (r.prev = 18),
                                            (r.t0 = r.catch(5)),
                                            console.warn(r.t0),
                                            e.streamConnection.disconnect(),
                                            r.abrupt("return", n.error(r.t0))
                                          );
                                        case 23:
                                        case "end":
                                          return r.stop();
                                      }
                                  },
                                  r,
                                  null,
                                  [[5, 18]]
                                );
                              })
                            );
                            return function(t) {
                              return r.apply(this, arguments);
                            };
                          })()
                        );
                  }
                },
                {
                  key: "postMessageWithAttachments",
                  value: function(e) {
                    var r = this,
                      n = e.attachments,
                      i = (0, o.default)(e, ["attachments"]);
                    return p.Observable.create(function(e) {
                      var o = [];
                      (0, c.default)(
                        s.default.mark(function a() {
                          var u, f, l, h, p, v;
                          return s.default.wrap(
                            function(a) {
                              for (;;)
                                switch ((a.prev = a.next)) {
                                  case 0:
                                    return (
                                      (a.prev = 0),
                                      (a.next = 3),
                                      Promise.all(
                                        n.map(
                                          (function() {
                                            var t = (0, c.default)(
                                              s.default.mark(function t(e) {
                                                var r, n;
                                                return s.default.wrap(function(
                                                  t
                                                ) {
                                                  for (;;)
                                                    switch ((t.prev = t.next)) {
                                                      case 0:
                                                        return (
                                                          (r = e),
                                                          (t.next = 3),
                                                          (0, y.default)(
                                                            r.contentUrl
                                                          )
                                                        );
                                                      case 3:
                                                        if (!(n = t.sent).ok) {
                                                          t.next = 12;
                                                          break;
                                                        }
                                                        return (
                                                          (t.next = 7),
                                                          n.arrayBuffer()
                                                        );
                                                      case 7:
                                                        return (
                                                          (t.t0 = t.sent),
                                                          (t.t1 = r),
                                                          t.abrupt("return", {
                                                            arrayBuffer: t.t0,
                                                            media: t.t1
                                                          })
                                                        );
                                                      case 12:
                                                        throw new Error("...");
                                                      case 13:
                                                      case "end":
                                                        return t.stop();
                                                    }
                                                },
                                                t);
                                              })
                                            );
                                            return function(e) {
                                              return t.apply(this, arguments);
                                            };
                                          })()
                                        )
                                      )
                                    );
                                  case 3:
                                    return (
                                      a.sent.forEach(function(e) {
                                        var r = e.arrayBuffer,
                                          n = e.media,
                                          i = new t(r);
                                        console.log(i);
                                        var s = new d.SubscribableStream();
                                        s.write(i);
                                        var a = new d.HttpContent(
                                          {
                                            type: n.contentType,
                                            contentLength: i.length
                                          },
                                          s
                                        );
                                        o.push(a);
                                      }),
                                      (u = "/v3/directline/conversations/"
                                        .concat(r.conversationId, "/users/")
                                        .concat(i.from.id, "/upload")),
                                      (f = d.StreamingRequest.create("PUT", u)),
                                      (l = new d.SubscribableStream()).write(
                                        JSON.stringify(i),
                                        "utf-8"
                                      ),
                                      f.addStream(
                                        new d.HttpContent(
                                          {
                                            type:
                                              "application/vnd.microsoft.activity",
                                            contentLength: l.length
                                          },
                                          l
                                        )
                                      ),
                                      o.forEach(function(t) {
                                        return f.addStream(t);
                                      }),
                                      (a.next = 13),
                                      r.streamConnection.send(f)
                                    );
                                  case 13:
                                    if (
                                      !(h = a.sent).streams ||
                                      1 === h.streams.length
                                    ) {
                                      a.next = 18;
                                      break;
                                    }
                                    e.error(
                                      new Error(
                                        "Invalid stream count ".concat(
                                          h.streams.length
                                        )
                                      )
                                    ),
                                      (a.next = 23);
                                    break;
                                  case 18:
                                    return (
                                      (a.next = 20), h.streams[0].readAsJson()
                                    );
                                  case 20:
                                    (p = a.sent), (v = p.Id), e.next(v);
                                  case 23:
                                    a.next = 28;
                                    break;
                                  case 25:
                                    (a.prev = 25),
                                      (a.t0 = a.catch(0)),
                                      e.error(a.t0);
                                  case 28:
                                  case "end":
                                    return a.stop();
                                }
                            },
                            a,
                            null,
                            [[0, 25]]
                          );
                        })
                      )();
                    });
                  }
                },
                {
                  key: "waitUntilOnline",
                  value:
                    ((i = (0, c.default)(
                      s.default.mark(function t() {
                        var e = this;
                        return s.default.wrap(function(t) {
                          for (;;)
                            switch ((t.prev = t.next)) {
                              case 0:
                                return t.abrupt(
                                  "return",
                                  new Promise(function(t, r) {
                                    e.connectionStatus$.subscribe(
                                      function(e) {
                                        if (e === v.ConnectionStatus.Online)
                                          return t();
                                      },
                                      function(t) {
                                        return r(t);
                                      }
                                    );
                                  })
                                );
                              case 1:
                              case "end":
                                return t.stop();
                            }
                        }, t);
                      })
                    )),
                    function() {
                      return i.apply(this, arguments);
                    })
                },
                {
                  key: "connectAsync",
                  value:
                    ((n = (0, c.default)(
                      s.default.mark(function t() {
                        var e,
                          r,
                          n,
                          i,
                          o = this;
                        return s.default.wrap(
                          function(t) {
                            for (;;)
                              switch ((t.prev = t.next)) {
                                case 0:
                                  if (
                                    (e = new RegExp("^http(s?)")).test(
                                      this.domain
                                    )
                                  ) {
                                    t.next = 3;
                                    break;
                                  }
                                  throw "Domain must begin with http or https";
                                case 3:
                                  return (
                                    (r = { token: this.token }),
                                    this.conversationId &&
                                      (r.conversationId = this.conversationId),
                                    (n = new URLSearchParams(r).toString()),
                                    (i = ""
                                      .concat(
                                        this.domain.replace(e, "ws$1"),
                                        "/conversations/connect?"
                                      )
                                      .concat(n)),
                                    t.abrupt(
                                      "return",
                                      new Promise(
                                        (function() {
                                          var t = (0, c.default)(
                                            s.default.mark(function t(e, r) {
                                              var n, a, c, u;
                                              return s.default.wrap(
                                                function(t) {
                                                  for (;;)
                                                    switch ((t.prev = t.next)) {
                                                      case 0:
                                                        return (
                                                          (t.prev = 0),
                                                          (o.streamConnection = new d.WebSocketClient(
                                                            {
                                                              url: i,
                                                              requestHandler:
                                                                o.theStreamHandler,
                                                              disconnectionHandler: function(
                                                                t
                                                              ) {
                                                                return e(t);
                                                              }
                                                            }
                                                          )),
                                                          (o.queueActivities = !0),
                                                          (t.next = 5),
                                                          o.streamConnection.connect()
                                                        );
                                                      case 5:
                                                        return (
                                                          (n = d.StreamingRequest.create(
                                                            "POST",
                                                            "/v3/directline/conversations"
                                                          )),
                                                          (t.next = 8),
                                                          o.streamConnection.send(
                                                            n
                                                          )
                                                        );
                                                      case 8:
                                                        if (
                                                          200 ===
                                                          (a = t.sent)
                                                            .statusCode
                                                        ) {
                                                          t.next = 11;
                                                          break;
                                                        }
                                                        throw new Error(
                                                          "Connection response code " +
                                                            a.statusCode
                                                        );
                                                      case 11:
                                                        if (
                                                          1 === a.streams.length
                                                        ) {
                                                          t.next = 13;
                                                          break;
                                                        }
                                                        throw new Error(
                                                          "Expected 1 stream but got " +
                                                            a.streams.length
                                                        );
                                                      case 13:
                                                        return (
                                                          (t.next = 15),
                                                          a.streams[0].readAsString()
                                                        );
                                                      case 15:
                                                        return (
                                                          (c = t.sent),
                                                          (u = JSON.parse(c)),
                                                          (o.conversationId =
                                                            u.conversationId),
                                                          o.connectionStatus$.next(
                                                            v.ConnectionStatus
                                                              .Online
                                                          ),
                                                          (t.next = 21),
                                                          o.waitUntilOnline()
                                                        );
                                                      case 21:
                                                        o.theStreamHandler.flush(),
                                                          (o.queueActivities = !1),
                                                          (t.next = 28);
                                                        break;
                                                      case 25:
                                                        (t.prev = 25),
                                                          (t.t0 = t.catch(0)),
                                                          r(t.t0);
                                                      case 28:
                                                      case "end":
                                                        return t.stop();
                                                    }
                                                },
                                                t,
                                                null,
                                                [[0, 25]]
                                              );
                                            })
                                          );
                                          return function(e, r) {
                                            return t.apply(this, arguments);
                                          };
                                        })()
                                      )
                                    )
                                  );
                                case 8:
                                case "end":
                                  return t.stop();
                              }
                          },
                          t,
                          this
                        );
                      })
                    )),
                    function() {
                      return n.apply(this, arguments);
                    })
                },
                {
                  key: "connectWithRetryAsync",
                  value:
                    ((r = (0, c.default)(
                      s.default.mark(function t() {
                        var e,
                          r,
                          n,
                          i = this;
                        return s.default.wrap(
                          function(t) {
                            for (;;)
                              switch ((t.prev = t.next)) {
                                case 0:
                                  e = 3;
                                case 1:
                                  if (!(e > 0)) {
                                    t.next = 23;
                                    break;
                                  }
                                  return (
                                    e--,
                                    (r = Date.now()),
                                    (t.prev = 4),
                                    this.connectionStatus$.next(
                                      v.ConnectionStatus.Connecting
                                    ),
                                    (t.next = 8),
                                    this.connectAsync()
                                  );
                                case 8:
                                  if (
                                    ((n = t.sent),
                                    console.warn(
                                      "Retrying connection ".concat(n)
                                    ),
                                    !(6e4 < Date.now() - r))
                                  ) {
                                    t.next = 13;
                                    break;
                                  }
                                  return (e = 3), t.abrupt("continue", 1);
                                case 13:
                                  t.next = 19;
                                  break;
                                case 15:
                                  throw ((t.prev = 15),
                                  (t.t0 = t.catch(4)),
                                  console.error(
                                    "Failed to connect ".concat(t.t0)
                                  ),
                                  t.t0);
                                case 19:
                                  return (
                                    (t.next = 21),
                                    new Promise(function(t) {
                                      return setTimeout(t, i.getRetryDelay());
                                    })
                                  );
                                case 21:
                                  t.next = 1;
                                  break;
                                case 23:
                                case "end":
                                  return t.stop();
                              }
                          },
                          t,
                          this,
                          [[4, 15]]
                        );
                      })
                    )),
                    function() {
                      return r.apply(this, arguments);
                    })
                },
                {
                  key: "getRetryDelay",
                  value: function() {
                    return Math.floor(3e3 + 12e3 * Math.random());
                  }
                }
              ]),
              e
            );
          })();
        e.DirectLineStreaming = g;
      }.call(this, r(19).Buffer));
    },
    function(t, e, r) {
      "use strict";
      (e.byteLength = function(t) {
        var e = u(t),
          r = e[0],
          n = e[1];
        return (3 * (r + n)) / 4 - n;
      }),
        (e.toByteArray = function(t) {
          var e,
            r,
            n = u(t),
            s = n[0],
            a = n[1],
            c = new o(
              (function(t, e, r) {
                return (3 * (e + r)) / 4 - r;
              })(0, s, a)
            ),
            f = 0,
            l = a > 0 ? s - 4 : s;
          for (r = 0; r < l; r += 4)
            (e =
              (i[t.charCodeAt(r)] << 18) |
              (i[t.charCodeAt(r + 1)] << 12) |
              (i[t.charCodeAt(r + 2)] << 6) |
              i[t.charCodeAt(r + 3)]),
              (c[f++] = (e >> 16) & 255),
              (c[f++] = (e >> 8) & 255),
              (c[f++] = 255 & e);
          2 === a &&
            ((e = (i[t.charCodeAt(r)] << 2) | (i[t.charCodeAt(r + 1)] >> 4)),
            (c[f++] = 255 & e));
          1 === a &&
            ((e =
              (i[t.charCodeAt(r)] << 10) |
              (i[t.charCodeAt(r + 1)] << 4) |
              (i[t.charCodeAt(r + 2)] >> 2)),
            (c[f++] = (e >> 8) & 255),
            (c[f++] = 255 & e));
          return c;
        }),
        (e.fromByteArray = function(t) {
          for (
            var e, r = t.length, i = r % 3, o = [], s = 0, a = r - i;
            s < a;
            s += 16383
          )
            o.push(f(t, s, s + 16383 > a ? a : s + 16383));
          1 === i
            ? ((e = t[r - 1]), o.push(n[e >> 2] + n[(e << 4) & 63] + "=="))
            : 2 === i &&
              ((e = (t[r - 2] << 8) + t[r - 1]),
              o.push(n[e >> 10] + n[(e >> 4) & 63] + n[(e << 2) & 63] + "="));
          return o.join("");
        });
      for (
        var n = [],
          i = [],
          o = "undefined" != typeof Uint8Array ? Uint8Array : Array,
          s =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
          a = 0,
          c = s.length;
        a < c;
        ++a
      )
        (n[a] = s[a]), (i[s.charCodeAt(a)] = a);
      function u(t) {
        var e = t.length;
        if (e % 4 > 0)
          throw new Error("Invalid string. Length must be a multiple of 4");
        var r = t.indexOf("=");
        return -1 === r && (r = e), [r, r === e ? 0 : 4 - (r % 4)];
      }
      function f(t, e, r) {
        for (var i, o, s = [], a = e; a < r; a += 3)
          (i =
            ((t[a] << 16) & 16711680) +
            ((t[a + 1] << 8) & 65280) +
            (255 & t[a + 2])),
            s.push(
              n[((o = i) >> 18) & 63] +
                n[(o >> 12) & 63] +
                n[(o >> 6) & 63] +
                n[63 & o]
            );
        return s.join("");
      }
      (i["-".charCodeAt(0)] = 62), (i["_".charCodeAt(0)] = 63);
    },
    function(t, e) {
      (e.read = function(t, e, r, n, i) {
        var o,
          s,
          a = 8 * i - n - 1,
          c = (1 << a) - 1,
          u = c >> 1,
          f = -7,
          l = r ? i - 1 : 0,
          h = r ? -1 : 1,
          p = t[e + l];
        for (
          l += h, o = p & ((1 << -f) - 1), p >>= -f, f += a;
          f > 0;
          o = 256 * o + t[e + l], l += h, f -= 8
        );
        for (
          s = o & ((1 << -f) - 1), o >>= -f, f += n;
          f > 0;
          s = 256 * s + t[e + l], l += h, f -= 8
        );
        if (0 === o) o = 1 - u;
        else {
          if (o === c) return s ? NaN : (1 / 0) * (p ? -1 : 1);
          (s += Math.pow(2, n)), (o -= u);
        }
        return (p ? -1 : 1) * s * Math.pow(2, o - n);
      }),
        (e.write = function(t, e, r, n, i, o) {
          var s,
            a,
            c,
            u = 8 * o - i - 1,
            f = (1 << u) - 1,
            l = f >> 1,
            h = 23 === i ? Math.pow(2, -24) - Math.pow(2, -77) : 0,
            p = n ? 0 : o - 1,
            d = n ? 1 : -1,
            y = e < 0 || (0 === e && 1 / e < 0) ? 1 : 0;
          for (
            e = Math.abs(e),
              isNaN(e) || e === 1 / 0
                ? ((a = isNaN(e) ? 1 : 0), (s = f))
                : ((s = Math.floor(Math.log(e) / Math.LN2)),
                  e * (c = Math.pow(2, -s)) < 1 && (s--, (c *= 2)),
                  (e += s + l >= 1 ? h / c : h * Math.pow(2, 1 - l)) * c >= 2 &&
                    (s++, (c /= 2)),
                  s + l >= f
                    ? ((a = 0), (s = f))
                    : s + l >= 1
                    ? ((a = (e * c - 1) * Math.pow(2, i)), (s += l))
                    : ((a = e * Math.pow(2, l - 1) * Math.pow(2, i)), (s = 0)));
            i >= 8;
            t[r + p] = 255 & a, p += d, a /= 256, i -= 8
          );
          for (
            s = (s << i) | a, u += i;
            u > 0;
            t[r + p] = 255 & s, p += d, s /= 256, u -= 8
          );
          t[r + p - d] |= 128 * y;
        });
    },
    function(t, e) {
      function r() {
        if ("function" != typeof WeakMap) return null;
        var t = new WeakMap();
        return (
          (r = function() {
            return t;
          }),
          t
        );
      }
      t.exports = function(t) {
        if (t && t.__esModule) return t;
        var e = r();
        if (e && e.has(t)) return e.get(t);
        var n = {};
        if (null != t) {
          var i = Object.defineProperty && Object.getOwnPropertyDescriptor;
          for (var o in t)
            if (Object.prototype.hasOwnProperty.call(t, o)) {
              var s = i ? Object.getOwnPropertyDescriptor(t, o) : null;
              s && (s.get || s.set)
                ? Object.defineProperty(n, o, s)
                : (n[o] = t[o]);
            }
        }
        return (n.default = t), e && e.set(t, n), n;
      };
    },
    function(t, e, r) {
      t.exports = r(250);
    },
    function(t, e, r) {
      var n = (function(t) {
        "use strict";
        var e = Object.prototype,
          r = e.hasOwnProperty,
          n = "function" == typeof Symbol ? Symbol : {},
          i = n.iterator || "@@iterator",
          o = n.asyncIterator || "@@asyncIterator",
          s = n.toStringTag || "@@toStringTag";
        function a(t, e, r, n) {
          var i = e && e.prototype instanceof f ? e : f,
            o = Object.create(i.prototype),
            s = new S(n || []);
          return (
            (o._invoke = (function(t, e, r) {
              var n = "suspendedStart";
              return function(i, o) {
                if ("executing" === n)
                  throw new Error("Generator is already running");
                if ("completed" === n) {
                  if ("throw" === i) throw o;
                  return O();
                }
                for (r.method = i, r.arg = o; ; ) {
                  var s = r.delegate;
                  if (s) {
                    var a = m(s, r);
                    if (a) {
                      if (a === u) continue;
                      return a;
                    }
                  }
                  if ("next" === r.method) r.sent = r._sent = r.arg;
                  else if ("throw" === r.method) {
                    if ("suspendedStart" === n)
                      throw ((n = "completed"), r.arg);
                    r.dispatchException(r.arg);
                  } else "return" === r.method && r.abrupt("return", r.arg);
                  n = "executing";
                  var f = c(t, e, r);
                  if ("normal" === f.type) {
                    if (
                      ((n = r.done ? "completed" : "suspendedYield"),
                      f.arg === u)
                    )
                      continue;
                    return { value: f.arg, done: r.done };
                  }
                  "throw" === f.type &&
                    ((n = "completed"), (r.method = "throw"), (r.arg = f.arg));
                }
              };
            })(t, r, s)),
            o
          );
        }
        function c(t, e, r) {
          try {
            return { type: "normal", arg: t.call(e, r) };
          } catch (t) {
            return { type: "throw", arg: t };
          }
        }
        t.wrap = a;
        var u = {};
        function f() {}
        function l() {}
        function h() {}
        var p = {};
        p[i] = function() {
          return this;
        };
        var d = Object.getPrototypeOf,
          y = d && d(d(x([])));
        y && y !== e && r.call(y, i) && (p = y);
        var v = (h.prototype = f.prototype = Object.create(p));
        function b(t) {
          ["next", "throw", "return"].forEach(function(e) {
            t[e] = function(t) {
              return this._invoke(e, t);
            };
          });
        }
        function g(t, e) {
          var n;
          this._invoke = function(i, o) {
            function s() {
              return new e(function(n, s) {
                !(function n(i, o, s, a) {
                  var u = c(t[i], t, o);
                  if ("throw" !== u.type) {
                    var f = u.arg,
                      l = f.value;
                    return l && "object" == typeof l && r.call(l, "__await")
                      ? e.resolve(l.__await).then(
                          function(t) {
                            n("next", t, s, a);
                          },
                          function(t) {
                            n("throw", t, s, a);
                          }
                        )
                      : e.resolve(l).then(
                          function(t) {
                            (f.value = t), s(f);
                          },
                          function(t) {
                            return n("throw", t, s, a);
                          }
                        );
                  }
                  a(u.arg);
                })(i, o, n, s);
              });
            }
            return (n = n ? n.then(s, s) : s());
          };
        }
        function m(t, e) {
          var r = t.iterator[e.method];
          if (void 0 === r) {
            if (((e.delegate = null), "throw" === e.method)) {
              if (
                t.iterator.return &&
                ((e.method = "return"),
                (e.arg = void 0),
                m(t, e),
                "throw" === e.method)
              )
                return u;
              (e.method = "throw"),
                (e.arg = new TypeError(
                  "The iterator does not provide a 'throw' method"
                ));
            }
            return u;
          }
          var n = c(r, t.iterator, e.arg);
          if ("throw" === n.type)
            return (
              (e.method = "throw"), (e.arg = n.arg), (e.delegate = null), u
            );
          var i = n.arg;
          return i
            ? i.done
              ? ((e[t.resultName] = i.value),
                (e.next = t.nextLoc),
                "return" !== e.method &&
                  ((e.method = "next"), (e.arg = void 0)),
                (e.delegate = null),
                u)
              : i
            : ((e.method = "throw"),
              (e.arg = new TypeError("iterator result is not an object")),
              (e.delegate = null),
              u);
        }
        function w(t) {
          var e = { tryLoc: t[0] };
          1 in t && (e.catchLoc = t[1]),
            2 in t && ((e.finallyLoc = t[2]), (e.afterLoc = t[3])),
            this.tryEntries.push(e);
        }
        function _(t) {
          var e = t.completion || {};
          (e.type = "normal"), delete e.arg, (t.completion = e);
        }
        function S(t) {
          (this.tryEntries = [{ tryLoc: "root" }]),
            t.forEach(w, this),
            this.reset(!0);
        }
        function x(t) {
          if (t) {
            var e = t[i];
            if (e) return e.call(t);
            if ("function" == typeof t.next) return t;
            if (!isNaN(t.length)) {
              var n = -1,
                o = function e() {
                  for (; ++n < t.length; )
                    if (r.call(t, n)) return (e.value = t[n]), (e.done = !1), e;
                  return (e.value = void 0), (e.done = !0), e;
                };
              return (o.next = o);
            }
          }
          return { next: O };
        }
        function O() {
          return { value: void 0, done: !0 };
        }
        return (
          (l.prototype = v.constructor = h),
          (h.constructor = l),
          (h[s] = l.displayName = "GeneratorFunction"),
          (t.isGeneratorFunction = function(t) {
            var e = "function" == typeof t && t.constructor;
            return (
              !!e &&
              (e === l || "GeneratorFunction" === (e.displayName || e.name))
            );
          }),
          (t.mark = function(t) {
            return (
              Object.setPrototypeOf
                ? Object.setPrototypeOf(t, h)
                : ((t.__proto__ = h), s in t || (t[s] = "GeneratorFunction")),
              (t.prototype = Object.create(v)),
              t
            );
          }),
          (t.awrap = function(t) {
            return { __await: t };
          }),
          b(g.prototype),
          (g.prototype[o] = function() {
            return this;
          }),
          (t.AsyncIterator = g),
          (t.async = function(e, r, n, i, o) {
            void 0 === o && (o = Promise);
            var s = new g(a(e, r, n, i), o);
            return t.isGeneratorFunction(r)
              ? s
              : s.next().then(function(t) {
                  return t.done ? t.value : s.next();
                });
          }),
          b(v),
          (v[s] = "Generator"),
          (v[i] = function() {
            return this;
          }),
          (v.toString = function() {
            return "[object Generator]";
          }),
          (t.keys = function(t) {
            var e = [];
            for (var r in t) e.push(r);
            return (
              e.reverse(),
              function r() {
                for (; e.length; ) {
                  var n = e.pop();
                  if (n in t) return (r.value = n), (r.done = !1), r;
                }
                return (r.done = !0), r;
              }
            );
          }),
          (t.values = x),
          (S.prototype = {
            constructor: S,
            reset: function(t) {
              if (
                ((this.prev = 0),
                (this.next = 0),
                (this.sent = this._sent = void 0),
                (this.done = !1),
                (this.delegate = null),
                (this.method = "next"),
                (this.arg = void 0),
                this.tryEntries.forEach(_),
                !t)
              )
                for (var e in this)
                  "t" === e.charAt(0) &&
                    r.call(this, e) &&
                    !isNaN(+e.slice(1)) &&
                    (this[e] = void 0);
            },
            stop: function() {
              this.done = !0;
              var t = this.tryEntries[0].completion;
              if ("throw" === t.type) throw t.arg;
              return this.rval;
            },
            dispatchException: function(t) {
              if (this.done) throw t;
              var e = this;
              function n(r, n) {
                return (
                  (s.type = "throw"),
                  (s.arg = t),
                  (e.next = r),
                  n && ((e.method = "next"), (e.arg = void 0)),
                  !!n
                );
              }
              for (var i = this.tryEntries.length - 1; i >= 0; --i) {
                var o = this.tryEntries[i],
                  s = o.completion;
                if ("root" === o.tryLoc) return n("end");
                if (o.tryLoc <= this.prev) {
                  var a = r.call(o, "catchLoc"),
                    c = r.call(o, "finallyLoc");
                  if (a && c) {
                    if (this.prev < o.catchLoc) return n(o.catchLoc, !0);
                    if (this.prev < o.finallyLoc) return n(o.finallyLoc);
                  } else if (a) {
                    if (this.prev < o.catchLoc) return n(o.catchLoc, !0);
                  } else {
                    if (!c)
                      throw new Error("try statement without catch or finally");
                    if (this.prev < o.finallyLoc) return n(o.finallyLoc);
                  }
                }
              }
            },
            abrupt: function(t, e) {
              for (var n = this.tryEntries.length - 1; n >= 0; --n) {
                var i = this.tryEntries[n];
                if (
                  i.tryLoc <= this.prev &&
                  r.call(i, "finallyLoc") &&
                  this.prev < i.finallyLoc
                ) {
                  var o = i;
                  break;
                }
              }
              o &&
                ("break" === t || "continue" === t) &&
                o.tryLoc <= e &&
                e <= o.finallyLoc &&
                (o = null);
              var s = o ? o.completion : {};
              return (
                (s.type = t),
                (s.arg = e),
                o
                  ? ((this.method = "next"), (this.next = o.finallyLoc), u)
                  : this.complete(s)
              );
            },
            complete: function(t, e) {
              if ("throw" === t.type) throw t.arg;
              return (
                "break" === t.type || "continue" === t.type
                  ? (this.next = t.arg)
                  : "return" === t.type
                  ? ((this.rval = this.arg = t.arg),
                    (this.method = "return"),
                    (this.next = "end"))
                  : "normal" === t.type && e && (this.next = e),
                u
              );
            },
            finish: function(t) {
              for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                var r = this.tryEntries[e];
                if (r.finallyLoc === t)
                  return this.complete(r.completion, r.afterLoc), _(r), u;
              }
            },
            catch: function(t) {
              for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                var r = this.tryEntries[e];
                if (r.tryLoc === t) {
                  var n = r.completion;
                  if ("throw" === n.type) {
                    var i = n.arg;
                    _(r);
                  }
                  return i;
                }
              }
              throw new Error("illegal catch attempt");
            },
            delegateYield: function(t, e, r) {
              return (
                (this.delegate = { iterator: x(t), resultName: e, nextLoc: r }),
                "next" === this.method && (this.arg = void 0),
                u
              );
            }
          }),
          t
        );
      })(t.exports);
      try {
        regeneratorRuntime = n;
      } catch (t) {
        Function("r", "regeneratorRuntime = r")(n);
      }
    },
    function(t, e, r) {
      var n = r(252),
        i = r(111),
        o = r(253);
      t.exports = function(t) {
        return n(t) || i(t) || o();
      };
    },
    function(t, e) {
      t.exports = function(t) {
        if (Array.isArray(t)) {
          for (var e = 0, r = new Array(t.length); e < t.length; e++)
            r[e] = t[e];
          return r;
        }
      };
    },
    function(t, e) {
      t.exports = function() {
        throw new TypeError("Invalid attempt to spread non-iterable instance");
      };
    },
    function(t, e) {
      function r(t, e, r, n, i, o, s) {
        try {
          var a = t[o](s),
            c = a.value;
        } catch (t) {
          return void r(t);
        }
        a.done ? e(c) : Promise.resolve(c).then(n, i);
      }
      t.exports = function(t) {
        return function() {
          var e = this,
            n = arguments;
          return new Promise(function(i, o) {
            var s = t.apply(e, n);
            function a(t) {
              r(s, i, o, a, c, "next", t);
            }
            function c(t) {
              r(s, i, o, a, c, "throw", t);
            }
            a(void 0);
          });
        };
      };
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(113);
      e.ContentStream = n.ContentStream;
      var i = r(62);
      e.HttpContent = i.HttpContent;
      var o = r(259);
      e.RequestHandler = o.RequestHandler;
      var s = r(260);
      e.StreamingRequest = s.StreamingRequest;
      var a = r(273);
      e.StreamingResponse = a.StreamingResponse;
      var c = r(27);
      e.SubscribableStream = c.SubscribableStream;
      var u = r(274);
      (e.BrowserWebSocket = u.BrowserWebSocket),
        (e.WebSocketClient = u.WebSocketClient);
    },
    function(t, e, r) {
      var n = r(257),
        i = r(258);
      t.exports = function(t, e, r) {
        var o = (e && r) || 0;
        "string" == typeof t &&
          ((e = "binary" === t ? new Array(16) : null), (t = null));
        var s = (t = t || {}).random || (t.rng || n)();
        if (((s[6] = (15 & s[6]) | 64), (s[8] = (63 & s[8]) | 128), e))
          for (var a = 0; a < 16; ++a) e[o + a] = s[a];
        return e || i(s);
      };
    },
    function(t, e) {
      var r =
        ("undefined" != typeof crypto &&
          crypto.getRandomValues &&
          crypto.getRandomValues.bind(crypto)) ||
        ("undefined" != typeof msCrypto &&
          "function" == typeof window.msCrypto.getRandomValues &&
          msCrypto.getRandomValues.bind(msCrypto));
      if (r) {
        var n = new Uint8Array(16);
        t.exports = function() {
          return r(n), n;
        };
      } else {
        var i = new Array(16);
        t.exports = function() {
          for (var t, e = 0; e < 16; e++)
            0 == (3 & e) && (t = 4294967296 * Math.random()),
              (i[e] = (t >>> ((3 & e) << 3)) & 255);
          return i;
        };
      }
    },
    function(t, e) {
      for (var r = [], n = 0; n < 256; ++n)
        r[n] = (n + 256).toString(16).substr(1);
      t.exports = function(t, e) {
        var n = e || 0,
          i = r;
        return [
          i[t[n++]],
          i[t[n++]],
          i[t[n++]],
          i[t[n++]],
          "-",
          i[t[n++]],
          i[t[n++]],
          "-",
          i[t[n++]],
          i[t[n++]],
          "-",
          i[t[n++]],
          i[t[n++]],
          "-",
          i[t[n++]],
          i[t[n++]],
          i[t[n++]],
          i[t[n++]],
          i[t[n++]],
          i[t[n++]]
        ].join("");
      };
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = function() {};
      e.RequestHandler = n;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(62),
        i = r(27),
        o = (function() {
          function t() {
            this.streams = [];
          }
          return (
            (t.create = function(e, r, n) {
              var i = new t();
              return (i.verb = e), (i.path = r), n && i.setBody(n), i;
            }),
            (t.prototype.addStream = function(t) {
              if (!t)
                throw new Error(
                  "Argument Undefined Exception: content undefined."
                );
              this.streams.push(new n.HttpContentStream(t));
            }),
            (t.prototype.setBody = function(t) {
              if ("string" == typeof t) {
                var e = new i.SubscribableStream();
                e.write(t, "utf8"),
                  this.addStream(
                    new n.HttpContent(
                      {
                        type: "application/json; charset=utf-8",
                        contentLength: e.length
                      },
                      e
                    )
                  );
              } else "object" == typeof t && this.addStream(t);
            }),
            t
          );
        })();
      e.StreamingRequest = o;
    },
    function(t, e, r) {
      t.exports = i;
      var n = r(63).EventEmitter;
      function i() {
        n.call(this);
      }
      r(20)(i, n),
        (i.Readable = r(64)),
        (i.Writable = r(269)),
        (i.Duplex = r(270)),
        (i.Transform = r(271)),
        (i.PassThrough = r(272)),
        (i.Stream = i),
        (i.prototype.pipe = function(t, e) {
          var r = this;
          function i(e) {
            t.writable && !1 === t.write(e) && r.pause && r.pause();
          }
          function o() {
            r.readable && r.resume && r.resume();
          }
          r.on("data", i),
            t.on("drain", o),
            t._isStdio ||
              (e && !1 === e.end) ||
              (r.on("end", a), r.on("close", c));
          var s = !1;
          function a() {
            s || ((s = !0), t.end());
          }
          function c() {
            s || ((s = !0), "function" == typeof t.destroy && t.destroy());
          }
          function u(t) {
            if ((f(), 0 === n.listenerCount(this, "error"))) throw t;
          }
          function f() {
            r.removeListener("data", i),
              t.removeListener("drain", o),
              r.removeListener("end", a),
              r.removeListener("close", c),
              r.removeListener("error", u),
              t.removeListener("error", u),
              r.removeListener("end", f),
              r.removeListener("close", f),
              t.removeListener("close", f);
          }
          return (
            r.on("error", u),
            t.on("error", u),
            r.on("end", f),
            r.on("close", f),
            t.on("close", f),
            t.emit("pipe", r),
            t
          );
        });
    },
    function(t, e) {},
    function(t, e, r) {
      "use strict";
      var n = r(43).Buffer,
        i = r(264);
      (t.exports = (function() {
        function t() {
          !(function(t, e) {
            if (!(t instanceof e))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
            (this.head = null),
            (this.tail = null),
            (this.length = 0);
        }
        return (
          (t.prototype.push = function(t) {
            var e = { data: t, next: null };
            this.length > 0 ? (this.tail.next = e) : (this.head = e),
              (this.tail = e),
              ++this.length;
          }),
          (t.prototype.unshift = function(t) {
            var e = { data: t, next: this.head };
            0 === this.length && (this.tail = e),
              (this.head = e),
              ++this.length;
          }),
          (t.prototype.shift = function() {
            if (0 !== this.length) {
              var t = this.head.data;
              return (
                1 === this.length
                  ? (this.head = this.tail = null)
                  : (this.head = this.head.next),
                --this.length,
                t
              );
            }
          }),
          (t.prototype.clear = function() {
            (this.head = this.tail = null), (this.length = 0);
          }),
          (t.prototype.join = function(t) {
            if (0 === this.length) return "";
            for (var e = this.head, r = "" + e.data; (e = e.next); )
              r += t + e.data;
            return r;
          }),
          (t.prototype.concat = function(t) {
            if (0 === this.length) return n.alloc(0);
            if (1 === this.length) return this.head.data;
            for (
              var e, r, i, o = n.allocUnsafe(t >>> 0), s = this.head, a = 0;
              s;

            )
              (e = s.data),
                (r = o),
                (i = a),
                e.copy(r, i),
                (a += s.data.length),
                (s = s.next);
            return o;
          }),
          t
        );
      })()),
        i &&
          i.inspect &&
          i.inspect.custom &&
          (t.exports.prototype[i.inspect.custom] = function() {
            var t = i.inspect({ length: this.length });
            return this.constructor.name + " " + t;
          });
    },
    function(t, e) {},
    function(t, e, r) {
      (function(t) {
        var n =
            (void 0 !== t && t) ||
            ("undefined" != typeof self && self) ||
            window,
          i = Function.prototype.apply;
        function o(t, e) {
          (this._id = t), (this._clearFn = e);
        }
        (e.setTimeout = function() {
          return new o(i.call(setTimeout, n, arguments), clearTimeout);
        }),
          (e.setInterval = function() {
            return new o(i.call(setInterval, n, arguments), clearInterval);
          }),
          (e.clearTimeout = e.clearInterval = function(t) {
            t && t.close();
          }),
          (o.prototype.unref = o.prototype.ref = function() {}),
          (o.prototype.close = function() {
            this._clearFn.call(n, this._id);
          }),
          (e.enroll = function(t, e) {
            clearTimeout(t._idleTimeoutId), (t._idleTimeout = e);
          }),
          (e.unenroll = function(t) {
            clearTimeout(t._idleTimeoutId), (t._idleTimeout = -1);
          }),
          (e._unrefActive = e.active = function(t) {
            clearTimeout(t._idleTimeoutId);
            var e = t._idleTimeout;
            e >= 0 &&
              (t._idleTimeoutId = setTimeout(function() {
                t._onTimeout && t._onTimeout();
              }, e));
          }),
          r(266),
          (e.setImmediate =
            ("undefined" != typeof self && self.setImmediate) ||
            (void 0 !== t && t.setImmediate) ||
            (this && this.setImmediate)),
          (e.clearImmediate =
            ("undefined" != typeof self && self.clearImmediate) ||
            (void 0 !== t && t.clearImmediate) ||
            (this && this.clearImmediate));
      }.call(this, r(4)));
    },
    function(t, e, r) {
      (function(t, e) {
        !(function(t, r) {
          "use strict";
          if (!t.setImmediate) {
            var n,
              i,
              o,
              s,
              a,
              c = 1,
              u = {},
              f = !1,
              l = t.document,
              h = Object.getPrototypeOf && Object.getPrototypeOf(t);
            (h = h && h.setTimeout ? h : t),
              "[object process]" === {}.toString.call(t.process)
                ? (n = function(t) {
                    e.nextTick(function() {
                      d(t);
                    });
                  })
                : !(function() {
                    if (t.postMessage && !t.importScripts) {
                      var e = !0,
                        r = t.onmessage;
                      return (
                        (t.onmessage = function() {
                          e = !1;
                        }),
                        t.postMessage("", "*"),
                        (t.onmessage = r),
                        e
                      );
                    }
                  })()
                ? t.MessageChannel
                  ? (((o = new MessageChannel()).port1.onmessage = function(t) {
                      d(t.data);
                    }),
                    (n = function(t) {
                      o.port2.postMessage(t);
                    }))
                  : l && "onreadystatechange" in l.createElement("script")
                  ? ((i = l.documentElement),
                    (n = function(t) {
                      var e = l.createElement("script");
                      (e.onreadystatechange = function() {
                        d(t),
                          (e.onreadystatechange = null),
                          i.removeChild(e),
                          (e = null);
                      }),
                        i.appendChild(e);
                    }))
                  : (n = function(t) {
                      setTimeout(d, 0, t);
                    })
                : ((s = "setImmediate$" + Math.random() + "$"),
                  (a = function(e) {
                    e.source === t &&
                      "string" == typeof e.data &&
                      0 === e.data.indexOf(s) &&
                      d(+e.data.slice(s.length));
                  }),
                  t.addEventListener
                    ? t.addEventListener("message", a, !1)
                    : t.attachEvent("onmessage", a),
                  (n = function(e) {
                    t.postMessage(s + e, "*");
                  })),
              (h.setImmediate = function(t) {
                "function" != typeof t && (t = new Function("" + t));
                for (
                  var e = new Array(arguments.length - 1), r = 0;
                  r < e.length;
                  r++
                )
                  e[r] = arguments[r + 1];
                var i = { callback: t, args: e };
                return (u[c] = i), n(c), c++;
              }),
              (h.clearImmediate = p);
          }
          function p(t) {
            delete u[t];
          }
          function d(t) {
            if (f) setTimeout(d, 0, t);
            else {
              var e = u[t];
              if (e) {
                f = !0;
                try {
                  !(function(t) {
                    var e = t.callback,
                      r = t.args;
                    switch (r.length) {
                      case 0:
                        e();
                        break;
                      case 1:
                        e(r[0]);
                        break;
                      case 2:
                        e(r[0], r[1]);
                        break;
                      case 3:
                        e(r[0], r[1], r[2]);
                        break;
                      default:
                        e.apply(void 0, r);
                    }
                  })(e);
                } finally {
                  p(t), (f = !1);
                }
              }
            }
          }
        })("undefined" == typeof self ? (void 0 === t ? this : t) : self);
      }.call(this, r(4), r(41)));
    },
    function(t, e, r) {
      (function(e) {
        function r(t) {
          try {
            if (!e.localStorage) return !1;
          } catch (t) {
            return !1;
          }
          var r = e.localStorage[t];
          return null != r && "true" === String(r).toLowerCase();
        }
        t.exports = function(t, e) {
          if (r("noDeprecation")) return t;
          var n = !1;
          return function() {
            if (!n) {
              if (r("throwDeprecation")) throw new Error(e);
              r("traceDeprecation") ? console.trace(e) : console.warn(e),
                (n = !0);
            }
            return t.apply(this, arguments);
          };
        };
      }.call(this, r(4)));
    },
    function(t, e, r) {
      "use strict";
      t.exports = o;
      var n = r(119),
        i = r(28);
      function o(t) {
        if (!(this instanceof o)) return new o(t);
        n.call(this, t);
      }
      (i.inherits = r(20)),
        i.inherits(o, n),
        (o.prototype._transform = function(t, e, r) {
          r(null, t);
        });
    },
    function(t, e, r) {
      t.exports = r(65);
    },
    function(t, e, r) {
      t.exports = r(15);
    },
    function(t, e, r) {
      t.exports = r(64).Transform;
    },
    function(t, e, r) {
      t.exports = r(64).PassThrough;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(62),
        i = r(27),
        o = (function() {
          function t() {
            this.streams = [];
          }
          return (
            (t.create = function(e, r) {
              var n = new t();
              return (n.statusCode = e), r && n.addStream(r), n;
            }),
            (t.prototype.addStream = function(t) {
              this.streams.push(new n.HttpContentStream(t));
            }),
            (t.prototype.setBody = function(t) {
              var e = new i.SubscribableStream();
              e.write(JSON.stringify(t), "utf8"),
                this.addStream(
                  new n.HttpContent(
                    {
                      type: "application/json; charset=utf-8",
                      contentLength: e.length
                    },
                    e
                  )
                );
            }),
            t
          );
        })();
      e.StreamingResponse = o;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = r(120);
      e.BrowserWebSocket = n.BrowserWebSocket;
      var i = r(275);
      e.WebSocketClient = i.WebSocketClient;
      var o = r(125);
      e.WebSocketTransport = o.WebSocketTransport;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = r(276),
        s = r(123),
        a = r(282),
        c = r(120),
        u = r(125),
        f = (function() {
          function t(t) {
            var e = t.url,
              r = t.requestHandler,
              n = t.disconnectionHandler,
              i = void 0 === n ? null : n;
            (this._url = e),
              (this._requestHandler = r),
              (this._disconnectionHandler = i),
              (this._requestManager = new s.RequestManager()),
              (this._sender = new a.PayloadSender()),
              (this._sender.disconnected = this.onConnectionDisconnected.bind(
                this
              )),
              (this._receiver = new a.PayloadReceiver()),
              (this._receiver.disconnected = this.onConnectionDisconnected.bind(
                this
              )),
              (this._protocolAdapter = new o.ProtocolAdapter(
                this._requestHandler,
                this._requestManager,
                this._sender,
                this._receiver
              ));
          }
          return (
            (t.prototype.connect = function() {
              return n(this, void 0, void 0, function() {
                var t, e;
                return i(this, function(r) {
                  switch (r.label) {
                    case 0:
                      return [
                        4,
                        (t = new c.BrowserWebSocket()).connect(this._url)
                      ];
                    case 1:
                      return (
                        r.sent(),
                        (e = new u.WebSocketTransport(t)),
                        this._sender.connect(e),
                        this._receiver.connect(e),
                        [2]
                      );
                  }
                });
              });
            }),
            (t.prototype.disconnect = function() {
              this._sender.disconnect(
                new a.TransportDisconnectedEvent("Disconnect was called.")
              ),
                this._receiver.disconnect(
                  new a.TransportDisconnectedEvent("Disconnect was called.")
                );
            }),
            (t.prototype.send = function(t) {
              return n(this, void 0, void 0, function() {
                return i(this, function(e) {
                  return [2, this._protocolAdapter.sendRequest(t)];
                });
              });
            }),
            (t.prototype.onConnectionDisconnected = function(t, e) {
              if (null == this._disconnectionHandler)
                throw new Error(
                  "Unable to re-connect client to transport for url " +
                    this._url +
                    ". Sender: '" +
                    JSON.stringify(t) +
                    "'. Args:' " +
                    JSON.stringify(e)
                );
              this._disconnectionHandler("Disconnected");
            }),
            t
          );
        })();
      e.WebSocketClient = f;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = r(121),
        s = r(124),
        a = r(68),
        c = r(114),
        u = (function() {
          function t(t, e, r, n) {
            var i = this;
            (this.requestHandler = t),
              (this.requestManager = e),
              (this.payloadSender = r),
              (this.payloadReceiver = n),
              (this.sendOperations = new s.SendOperations(this.payloadSender)),
              (this.streamManager = new a.StreamManager(this.onCancelStream)),
              (this.assemblerManager = new o.PayloadAssemblerManager(
                this.streamManager,
                function(t, e) {
                  return i.onReceiveResponse(t, e);
                },
                function(t, e) {
                  return i.onReceiveRequest(t, e);
                }
              )),
              this.payloadReceiver.subscribe(
                function(t) {
                  return i.assemblerManager.getPayloadStream(t);
                },
                function(t, e, r) {
                  return i.assemblerManager.onReceive(t, e, r);
                }
              );
          }
          return (
            (t.prototype.sendRequest = function(t) {
              return n(this, void 0, void 0, function() {
                var e;
                return i(this, function(r) {
                  switch (r.label) {
                    case 0:
                      return (
                        (e = c.generateGuid()),
                        [4, this.sendOperations.sendRequest(e, t)]
                      );
                    case 1:
                      return r.sent(), [2, this.requestManager.getResponse(e)];
                  }
                });
              });
            }),
            (t.prototype.onReceiveRequest = function(t, e) {
              return n(this, void 0, void 0, function() {
                var r;
                return i(this, function(n) {
                  switch (n.label) {
                    case 0:
                      return this.requestHandler
                        ? [4, this.requestHandler.processRequest(e)]
                        : [3, 3];
                    case 1:
                      return (r = n.sent())
                        ? [4, this.sendOperations.sendResponse(t, r)]
                        : [3, 3];
                    case 2:
                      n.sent(), (n.label = 3);
                    case 3:
                      return [2];
                  }
                });
              });
            }),
            (t.prototype.onReceiveResponse = function(t, e) {
              return n(this, void 0, void 0, function() {
                return i(this, function(r) {
                  switch (r.label) {
                    case 0:
                      return [4, this.requestManager.signalResponse(t, e)];
                    case 1:
                      return r.sent(), [2];
                  }
                });
              });
            }),
            (t.prototype.onCancelStream = function(t) {
              this.sendOperations.sendCancelStream(t.id).catch();
            }),
            t
          );
        })();
      e.ProtocolAdapter = u;
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = function() {},
        s = (function() {
          function t() {
            this._pendingRequests = {};
          }
          return (
            (t.prototype.pendingRequestCount = function() {
              return Object.keys(this._pendingRequests).length;
            }),
            (t.prototype.signalResponse = function(t, e) {
              return n(this, void 0, void 0, function() {
                var r;
                return i(this, function(n) {
                  return (r = this._pendingRequests[t])
                    ? (r.resolve(e), delete this._pendingRequests[t], [2, !0])
                    : [2, !1];
                });
              });
            }),
            (t.prototype.getResponse = function(t) {
              var e = this._pendingRequests[t];
              if (e)
                return Promise.reject(
                  "requestId '" + t + "' already exists in RequestManager"
                );
              (e = new o()).requestId = t;
              var r = new Promise(function(t, r) {
                (e.resolve = t), (e.reject = r);
              });
              return (this._pendingRequests[t] = e), r;
            }),
            t
          );
        })();
      e.RequestManager = s;
    },
    function(t, e, r) {
      "use strict";
      Object.defineProperty(e, "__esModule", { value: !0 });
      var n = (function() {
        function t(t, e, r) {
          (this.sender = t), (this.id = e), (this.payloadType = r);
        }
        return (
          (t.prototype.disassemble = function() {
            var t = {
              payloadType: this.payloadType,
              payloadLength: 0,
              id: this.id,
              end: !0
            };
            this.sender.sendPayload(t);
          }),
          t
        );
      })();
      e.CancelDisassembler = n;
    },
    function(t, e, r) {
      "use strict";
      var n,
        i =
          (this && this.__extends) ||
          ((n = function(t, e) {
            return (n =
              Object.setPrototypeOf ||
              ({ __proto__: [] } instanceof Array &&
                function(t, e) {
                  t.__proto__ = e;
                }) ||
              function(t, e) {
                for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
              })(t, e);
          }),
          function(t, e) {
            function r() {
              this.constructor = t;
            }
            n(t, e),
              (t.prototype =
                null === e
                  ? Object.create(e)
                  : ((r.prototype = e.prototype), new r()));
          }),
        o =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        s =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var a = r(16),
        c = (function(t) {
          function e(e, r) {
            var n = t.call(this, e, r.id) || this;
            return (
              (n.payloadType = a.PayloadTypes.stream), (n.contentStream = r), n
            );
          }
          return (
            i(e, t),
            (e.prototype.getStream = function() {
              return o(this, void 0, void 0, function() {
                var t;
                return s(this, function(e) {
                  return [
                    2,
                    {
                      stream: (t = this.contentStream.content.getStream()),
                      streamLength: t.length
                    }
                  ];
                });
              });
            }),
            e
          );
        })(r(69).PayloadDisassembler);
      e.HttpContentStreamDisassembler = c;
    },
    function(t, e, r) {
      "use strict";
      var n,
        i =
          (this && this.__extends) ||
          ((n = function(t, e) {
            return (n =
              Object.setPrototypeOf ||
              ({ __proto__: [] } instanceof Array &&
                function(t, e) {
                  t.__proto__ = e;
                }) ||
              function(t, e) {
                for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
              })(t, e);
          }),
          function(t, e) {
            function r() {
              this.constructor = t;
            }
            n(t, e),
              (t.prototype =
                null === e
                  ? Object.create(e)
                  : ((r.prototype = e.prototype), new r()));
          }),
        o =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        s =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var a = r(16),
        c = r(69),
        u = (function(t) {
          function e(e, r, n) {
            var i = t.call(this, e, r) || this;
            return (i.payloadType = a.PayloadTypes.request), (i.request = n), i;
          }
          return (
            i(e, t),
            (e.prototype.getStream = function() {
              return o(this, void 0, void 0, function() {
                var t;
                return s(this, function(e) {
                  return (
                    (t = {
                      verb: this.request.verb,
                      path: this.request.path,
                      streams: []
                    }),
                    this.request.streams &&
                      this.request.streams.forEach(function(e) {
                        t.streams.push(e.description);
                      }),
                    [2, c.PayloadDisassembler.serialize(t)]
                  );
                });
              });
            }),
            e
          );
        })(c.PayloadDisassembler);
      e.RequestDisassembler = u;
    },
    function(t, e, r) {
      "use strict";
      var n,
        i =
          (this && this.__extends) ||
          ((n = function(t, e) {
            return (n =
              Object.setPrototypeOf ||
              ({ __proto__: [] } instanceof Array &&
                function(t, e) {
                  t.__proto__ = e;
                }) ||
              function(t, e) {
                for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
              })(t, e);
          }),
          function(t, e) {
            function r() {
              this.constructor = t;
            }
            n(t, e),
              (t.prototype =
                null === e
                  ? Object.create(e)
                  : ((r.prototype = e.prototype), new r()));
          }),
        o =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        s =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var a = r(16),
        c = r(69),
        u = (function(t) {
          function e(e, r, n) {
            var i = t.call(this, e, r) || this;
            return (
              (i.payloadType = a.PayloadTypes.response), (i.response = n), i
            );
          }
          return (
            i(e, t),
            (e.prototype.getStream = function() {
              return o(this, void 0, void 0, function() {
                var t;
                return s(this, function(e) {
                  return (
                    (t = { statusCode: this.response.statusCode, streams: [] }),
                    this.response.streams &&
                      this.response.streams.forEach(function(e) {
                        t.streams.push(e.description);
                      }),
                    [2, c.PayloadDisassembler.serialize(t)]
                  );
                });
              });
            }),
            e
          );
        })(c.PayloadDisassembler);
      e.ResponseDisassembler = u;
    },
    function(t, e, r) {
      "use strict";
      function n(t) {
        for (var r in t) e.hasOwnProperty(r) || (e[r] = t[r]);
      }
      Object.defineProperty(e, "__esModule", { value: !0 }),
        n(r(283)),
        n(r(284)),
        n(r(70));
    },
    function(t, e, r) {
      "use strict";
      var n =
          (this && this.__awaiter) ||
          function(t, e, r, n) {
            return new (r || (r = Promise))(function(i, o) {
              function s(t) {
                try {
                  c(n.next(t));
                } catch (t) {
                  o(t);
                }
              }
              function a(t) {
                try {
                  c(n.throw(t));
                } catch (t) {
                  o(t);
                }
              }
              function c(t) {
                t.done
                  ? i(t.value)
                  : new r(function(e) {
                      e(t.value);
                    }).then(s, a);
              }
              c((n = n.apply(t, e || [])).next());
            });
          },
        i =
          (this && this.__generator) ||
          function(t, e) {
            var r,
              n,
              i,
              o,
              s = {
                label: 0,
                sent: function() {
                  if (1 & i[0]) throw i[1];
                  return i[1];
                },
                trys: [],
                ops: []
              };
            return (
              (o = { next: a(0), throw: a(1), return: a(2) }),
              "function" == typeof Symbol &&
                (o[Symbol.iterator] = function() {
                  return this;
                }),
              o
            );
            function a(o) {
              return function(a) {
                return (function(o) {
                  if (r) throw new TypeError("Generator is already executing.");
                  for (; s; )
                    try {
                      if (
                        ((r = 1),
                        n &&
                          (i =
                            2 & o[0]
                              ? n.return
                              : o[0]
                              ? n.throw || ((i = n.return) && i.call(n), 0)
                              : n.next) &&
                          !(i = i.call(n, o[1])).done)
                      )
                        return i;
                      switch (((n = 0), i && (o = [2 & o[0], i.value]), o[0])) {
                        case 0:
                        case 1:
                          i = o;
                          break;
                        case 4:
                          return s.label++, { value: o[1], done: !1 };
                        case 5:
                          s.label++, (n = o[1]), (o = [0]);
                          continue;
                        case 7:
                          (o = s.ops.pop()), s.trys.pop();
                          continue;
                        default:
                          if (
                            !(i = (i = s.trys).length > 0 && i[i.length - 1]) &&
                            (6 === o[0] || 2 === o[0])
                          ) {
                            s = 0;
                            continue;
                          }
                          if (
                            3 === o[0] &&
                            (!i || (o[1] > i[0] && o[1] < i[3]))
                          ) {
                            s.label = o[1];
                            break;
                          }
                          if (6 === o[0] && s.label < i[1]) {
                            (s.label = i[1]), (i = o);
                            break;
                          }
                          if (i && s.label < i[2]) {
                            (s.label = i[2]), s.ops.push(o);
                            break;
                          }
                          i[2] && s.ops.pop(), s.trys.pop();
                          continue;
                      }
                      o = e.call(t, s);
                    } catch (t) {
                      (o = [6, t]), (n = 0);
                    } finally {
                      r = i = 0;
                    }
                  if (5 & o[0]) throw o[1];
                  return { value: o[0] ? o[1] : void 0, done: !0 };
                })([o, a]);
              };
            }
          };
      Object.defineProperty(e, "__esModule", { value: !0 });
      var o = r(16),
        s = r(66),
        a = r(67),
        c = r(70),
        u = (function() {
          function t() {
            this.disconnected = function(t, e) {};
          }
          return (
            (t.prototype.connect = function(t) {
              if (this.isConnected) throw new Error("Already connected.");
              (this._receiver = t), (this.isConnected = !0), this.runReceive();
            }),
            (t.prototype.subscribe = function(t, e) {
              (this._getStream = t), (this._receiveAction = e);
            }),
            (t.prototype.disconnect = function(t) {
              var e;
              try {
                this.isConnected &&
                  (this._receiver.close(), (e = !0), (this.isConnected = !1));
              } catch (t) {
                (this.isConnected = !1),
                  this.disconnected(
                    this,
                    new c.TransportDisconnectedEvent(t.message)
                  );
              }
              (this._receiver = null),
                (this.isConnected = !1),
                e &&
                  this.disconnected(
                    this,
                    t || c.TransportDisconnectedEvent.Empty
                  );
            }),
            (t.prototype.runReceive = function() {
              this.receivePackets().catch();
            }),
            (t.prototype.receivePackets = function() {
              return n(this, void 0, void 0, function() {
                var t, e, r, n, u, f, l, h, p, d;
                return i(this, function(i) {
                  switch (i.label) {
                    case 0:
                      if (!this.isConnected || t) return [3, 11];
                      i.label = 1;
                    case 1:
                      i.trys.push([1, 9, , 10]), (e = 0), (i.label = 2);
                    case 2:
                      return e < a.PayloadConstants.MaxHeaderLength
                        ? ((r = this),
                          [
                            4,
                            this._receiver.receive(
                              a.PayloadConstants.MaxHeaderLength - e
                            )
                          ])
                        : [3, 4];
                    case 3:
                      return (
                        (r._receiveHeaderBuffer = i.sent()),
                        this._receiveHeaderBuffer &&
                          (e += this._receiveHeaderBuffer.length),
                        [3, 2]
                      );
                    case 4:
                      if (
                        ((n = s.HeaderSerializer.deserialize(
                          this._receiveHeaderBuffer
                        )),
                        (u = n.payloadType === o.PayloadTypes.stream),
                        !(n.payloadLength > 0))
                      )
                        return [3, 8];
                      (f = 0), (l = this._getStream(n)), (i.label = 5);
                    case 5:
                      return f < n.payloadLength &&
                        f < a.PayloadConstants.MaxPayloadLength
                        ? ((h = Math.min(
                            n.payloadLength - f,
                            a.PayloadConstants.MaxPayloadLength
                          )),
                          (p = this),
                          [4, this._receiver.receive(h)])
                        : [3, 7];
                    case 6:
                      return (
                        (p._receivePayloadBuffer = i.sent()),
                        (f += this._receivePayloadBuffer.byteLength),
                        l.write(this._receivePayloadBuffer),
                        u && this._receiveAction(n, l, f),
                        [3, 5]
                      );
                    case 7:
                      u || this._receiveAction(n, l, f), (i.label = 8);
                    case 8:
                      return [3, 10];
                    case 9:
                      return (
                        (d = i.sent()),
                        (t = !0),
                        this.disconnect(
                          new c.TransportDisconnectedEvent(d.message)
                        ),
                        [3, 10]
                      );
                    case 10:
                      return [3, 0];
                    case 11:
                      return [2];
                  }
                });
              });
            }),
            t
          );
        })();
      e.PayloadReceiver = u;
    },
    function(t, e, r) {
      "use strict";
      (function(t) {
        Object.defineProperty(e, "__esModule", { value: !0 });
        var n = r(66),
          i = r(67),
          o = r(70),
          s = (function() {
            function e() {}
            return (
              Object.defineProperty(e.prototype, "isConnected", {
                get: function() {
                  return !!this.sender;
                },
                enumerable: !0,
                configurable: !0
              }),
              (e.prototype.connect = function(t) {
                this.sender = t;
              }),
              (e.prototype.sendPayload = function(t, e, r) {
                var n = { header: t, payload: e, sentCallback: r };
                this.writePacket(n);
              }),
              (e.prototype.disconnect = function(t) {
                this.isConnected &&
                  (this.sender.close(),
                  (this.sender = null),
                  this.disconnected &&
                    this.disconnected(
                      this,
                      t || o.TransportDisconnectedEvent.Empty
                    ));
              }),
              (e.prototype.writePacket = function(e) {
                try {
                  if (e.header.payloadLength > 0 && e.payload) {
                    for (var r = e.header.payloadLength; r > 0; ) {
                      var s =
                          r <= i.PayloadConstants.MaxPayloadLength
                            ? r
                            : i.PayloadConstants.MaxPayloadLength,
                        a = e.payload.read(s),
                        c = e.header;
                      (c.payloadLength = s),
                        (c.end = r <= i.PayloadConstants.MaxPayloadLength);
                      var u = t.alloc(i.PayloadConstants.MaxHeaderLength);
                      n.HeaderSerializer.serialize(c, u),
                        this.sender.send(u),
                        this.sender.send(a),
                        (r -= a.length);
                    }
                    e.sentCallback && e.sentCallback();
                  }
                } catch (t) {
                  this.disconnect(new o.TransportDisconnectedEvent(t.message));
                }
              }),
              e
            );
          })();
        e.PayloadSender = s;
      }.call(this, r(19).Buffer));
    },
    function(t, e) {
      var r = (function(t) {
        function e() {
          (this.fetch = !1), (this.DOMException = t.DOMException);
        }
        return (e.prototype = t), new e();
      })("undefined" != typeof self ? self : this);
      !(function(t) {
        !(function(e) {
          var r = "URLSearchParams" in t,
            n = "Symbol" in t && "iterator" in Symbol,
            i =
              "FileReader" in t &&
              "Blob" in t &&
              (function() {
                try {
                  return new Blob(), !0;
                } catch (t) {
                  return !1;
                }
              })(),
            o = "FormData" in t,
            s = "ArrayBuffer" in t;
          if (s)
            var a = [
                "[object Int8Array]",
                "[object Uint8Array]",
                "[object Uint8ClampedArray]",
                "[object Int16Array]",
                "[object Uint16Array]",
                "[object Int32Array]",
                "[object Uint32Array]",
                "[object Float32Array]",
                "[object Float64Array]"
              ],
              c =
                ArrayBuffer.isView ||
                function(t) {
                  return t && a.indexOf(Object.prototype.toString.call(t)) > -1;
                };
          function u(t) {
            if (
              ("string" != typeof t && (t = String(t)),
              /[^a-z0-9\-#$%&'*+.^_`|~]/i.test(t))
            )
              throw new TypeError("Invalid character in header field name");
            return t.toLowerCase();
          }
          function f(t) {
            return "string" != typeof t && (t = String(t)), t;
          }
          function l(t) {
            var e = {
              next: function() {
                var e = t.shift();
                return { done: void 0 === e, value: e };
              }
            };
            return (
              n &&
                (e[Symbol.iterator] = function() {
                  return e;
                }),
              e
            );
          }
          function h(t) {
            (this.map = {}),
              t instanceof h
                ? t.forEach(function(t, e) {
                    this.append(e, t);
                  }, this)
                : Array.isArray(t)
                ? t.forEach(function(t) {
                    this.append(t[0], t[1]);
                  }, this)
                : t &&
                  Object.getOwnPropertyNames(t).forEach(function(e) {
                    this.append(e, t[e]);
                  }, this);
          }
          function p(t) {
            if (t.bodyUsed)
              return Promise.reject(new TypeError("Already read"));
            t.bodyUsed = !0;
          }
          function d(t) {
            return new Promise(function(e, r) {
              (t.onload = function() {
                e(t.result);
              }),
                (t.onerror = function() {
                  r(t.error);
                });
            });
          }
          function y(t) {
            var e = new FileReader(),
              r = d(e);
            return e.readAsArrayBuffer(t), r;
          }
          function v(t) {
            if (t.slice) return t.slice(0);
            var e = new Uint8Array(t.byteLength);
            return e.set(new Uint8Array(t)), e.buffer;
          }
          function b() {
            return (
              (this.bodyUsed = !1),
              (this._initBody = function(t) {
                var e;
                (this._bodyInit = t),
                  t
                    ? "string" == typeof t
                      ? (this._bodyText = t)
                      : i && Blob.prototype.isPrototypeOf(t)
                      ? (this._bodyBlob = t)
                      : o && FormData.prototype.isPrototypeOf(t)
                      ? (this._bodyFormData = t)
                      : r && URLSearchParams.prototype.isPrototypeOf(t)
                      ? (this._bodyText = t.toString())
                      : s && i && (e = t) && DataView.prototype.isPrototypeOf(e)
                      ? ((this._bodyArrayBuffer = v(t.buffer)),
                        (this._bodyInit = new Blob([this._bodyArrayBuffer])))
                      : s && (ArrayBuffer.prototype.isPrototypeOf(t) || c(t))
                      ? (this._bodyArrayBuffer = v(t))
                      : (this._bodyText = t = Object.prototype.toString.call(t))
                    : (this._bodyText = ""),
                  this.headers.get("content-type") ||
                    ("string" == typeof t
                      ? this.headers.set(
                          "content-type",
                          "text/plain;charset=UTF-8"
                        )
                      : this._bodyBlob && this._bodyBlob.type
                      ? this.headers.set("content-type", this._bodyBlob.type)
                      : r &&
                        URLSearchParams.prototype.isPrototypeOf(t) &&
                        this.headers.set(
                          "content-type",
                          "application/x-www-form-urlencoded;charset=UTF-8"
                        ));
              }),
              i &&
                ((this.blob = function() {
                  var t = p(this);
                  if (t) return t;
                  if (this._bodyBlob) return Promise.resolve(this._bodyBlob);
                  if (this._bodyArrayBuffer)
                    return Promise.resolve(new Blob([this._bodyArrayBuffer]));
                  if (this._bodyFormData)
                    throw new Error("could not read FormData body as blob");
                  return Promise.resolve(new Blob([this._bodyText]));
                }),
                (this.arrayBuffer = function() {
                  return this._bodyArrayBuffer
                    ? p(this) || Promise.resolve(this._bodyArrayBuffer)
                    : this.blob().then(y);
                })),
              (this.text = function() {
                var t,
                  e,
                  r,
                  n = p(this);
                if (n) return n;
                if (this._bodyBlob)
                  return (
                    (t = this._bodyBlob),
                    (e = new FileReader()),
                    (r = d(e)),
                    e.readAsText(t),
                    r
                  );
                if (this._bodyArrayBuffer)
                  return Promise.resolve(
                    (function(t) {
                      for (
                        var e = new Uint8Array(t),
                          r = new Array(e.length),
                          n = 0;
                        n < e.length;
                        n++
                      )
                        r[n] = String.fromCharCode(e[n]);
                      return r.join("");
                    })(this._bodyArrayBuffer)
                  );
                if (this._bodyFormData)
                  throw new Error("could not read FormData body as text");
                return Promise.resolve(this._bodyText);
              }),
              o &&
                (this.formData = function() {
                  return this.text().then(w);
                }),
              (this.json = function() {
                return this.text().then(JSON.parse);
              }),
              this
            );
          }
          (h.prototype.append = function(t, e) {
            (t = u(t)), (e = f(e));
            var r = this.map[t];
            this.map[t] = r ? r + ", " + e : e;
          }),
            (h.prototype.delete = function(t) {
              delete this.map[u(t)];
            }),
            (h.prototype.get = function(t) {
              return (t = u(t)), this.has(t) ? this.map[t] : null;
            }),
            (h.prototype.has = function(t) {
              return this.map.hasOwnProperty(u(t));
            }),
            (h.prototype.set = function(t, e) {
              this.map[u(t)] = f(e);
            }),
            (h.prototype.forEach = function(t, e) {
              for (var r in this.map)
                this.map.hasOwnProperty(r) && t.call(e, this.map[r], r, this);
            }),
            (h.prototype.keys = function() {
              var t = [];
              return (
                this.forEach(function(e, r) {
                  t.push(r);
                }),
                l(t)
              );
            }),
            (h.prototype.values = function() {
              var t = [];
              return (
                this.forEach(function(e) {
                  t.push(e);
                }),
                l(t)
              );
            }),
            (h.prototype.entries = function() {
              var t = [];
              return (
                this.forEach(function(e, r) {
                  t.push([r, e]);
                }),
                l(t)
              );
            }),
            n && (h.prototype[Symbol.iterator] = h.prototype.entries);
          var g = ["DELETE", "GET", "HEAD", "OPTIONS", "POST", "PUT"];
          function m(t, e) {
            var r,
              n,
              i = (e = e || {}).body;
            if (t instanceof m) {
              if (t.bodyUsed) throw new TypeError("Already read");
              (this.url = t.url),
                (this.credentials = t.credentials),
                e.headers || (this.headers = new h(t.headers)),
                (this.method = t.method),
                (this.mode = t.mode),
                (this.signal = t.signal),
                i ||
                  null == t._bodyInit ||
                  ((i = t._bodyInit), (t.bodyUsed = !0));
            } else this.url = String(t);
            if (
              ((this.credentials =
                e.credentials || this.credentials || "same-origin"),
              (!e.headers && this.headers) || (this.headers = new h(e.headers)),
              (this.method =
                ((r = e.method || this.method || "GET"),
                (n = r.toUpperCase()),
                g.indexOf(n) > -1 ? n : r)),
              (this.mode = e.mode || this.mode || null),
              (this.signal = e.signal || this.signal),
              (this.referrer = null),
              ("GET" === this.method || "HEAD" === this.method) && i)
            )
              throw new TypeError("Body not allowed for GET or HEAD requests");
            this._initBody(i);
          }
          function w(t) {
            var e = new FormData();
            return (
              t
                .trim()
                .split("&")
                .forEach(function(t) {
                  if (t) {
                    var r = t.split("="),
                      n = r.shift().replace(/\+/g, " "),
                      i = r.join("=").replace(/\+/g, " ");
                    e.append(decodeURIComponent(n), decodeURIComponent(i));
                  }
                }),
              e
            );
          }
          function _(t, e) {
            e || (e = {}),
              (this.type = "default"),
              (this.status = void 0 === e.status ? 200 : e.status),
              (this.ok = this.status >= 200 && this.status < 300),
              (this.statusText = "statusText" in e ? e.statusText : "OK"),
              (this.headers = new h(e.headers)),
              (this.url = e.url || ""),
              this._initBody(t);
          }
          (m.prototype.clone = function() {
            return new m(this, { body: this._bodyInit });
          }),
            b.call(m.prototype),
            b.call(_.prototype),
            (_.prototype.clone = function() {
              return new _(this._bodyInit, {
                status: this.status,
                statusText: this.statusText,
                headers: new h(this.headers),
                url: this.url
              });
            }),
            (_.error = function() {
              var t = new _(null, { status: 0, statusText: "" });
              return (t.type = "error"), t;
            });
          var S = [301, 302, 303, 307, 308];
          (_.redirect = function(t, e) {
            if (-1 === S.indexOf(e))
              throw new RangeError("Invalid status code");
            return new _(null, { status: e, headers: { location: t } });
          }),
            (e.DOMException = t.DOMException);
          try {
            new e.DOMException();
          } catch (t) {
            (e.DOMException = function(t, e) {
              (this.message = t), (this.name = e);
              var r = Error(t);
              this.stack = r.stack;
            }),
              (e.DOMException.prototype = Object.create(Error.prototype)),
              (e.DOMException.prototype.constructor = e.DOMException);
          }
          function x(t, r) {
            return new Promise(function(n, o) {
              var s = new m(t, r);
              if (s.signal && s.signal.aborted)
                return o(new e.DOMException("Aborted", "AbortError"));
              var a = new XMLHttpRequest();
              function c() {
                a.abort();
              }
              (a.onload = function() {
                var t,
                  e,
                  r = {
                    status: a.status,
                    statusText: a.statusText,
                    headers:
                      ((t = a.getAllResponseHeaders() || ""),
                      (e = new h()),
                      t
                        .replace(/\r?\n[\t ]+/g, " ")
                        .split(/\r?\n/)
                        .forEach(function(t) {
                          var r = t.split(":"),
                            n = r.shift().trim();
                          if (n) {
                            var i = r.join(":").trim();
                            e.append(n, i);
                          }
                        }),
                      e)
                  };
                r.url =
                  "responseURL" in a
                    ? a.responseURL
                    : r.headers.get("X-Request-URL");
                var i = "response" in a ? a.response : a.responseText;
                n(new _(i, r));
              }),
                (a.onerror = function() {
                  o(new TypeError("Network request failed"));
                }),
                (a.ontimeout = function() {
                  o(new TypeError("Network request failed"));
                }),
                (a.onabort = function() {
                  o(new e.DOMException("Aborted", "AbortError"));
                }),
                a.open(s.method, s.url, !0),
                "include" === s.credentials
                  ? (a.withCredentials = !0)
                  : "omit" === s.credentials && (a.withCredentials = !1),
                "responseType" in a && i && (a.responseType = "blob"),
                s.headers.forEach(function(t, e) {
                  a.setRequestHeader(e, t);
                }),
                s.signal &&
                  (s.signal.addEventListener("abort", c),
                  (a.onreadystatechange = function() {
                    4 === a.readyState &&
                      s.signal.removeEventListener("abort", c);
                  })),
                a.send(void 0 === s._bodyInit ? null : s._bodyInit);
            });
          }
          (x.polyfill = !0),
            t.fetch ||
              ((t.fetch = x),
              (t.Headers = h),
              (t.Request = m),
              (t.Response = _)),
            (e.Headers = h),
            (e.Request = m),
            (e.Response = _),
            (e.fetch = x);
        })({});
      })(r),
        delete r.fetch.polyfill,
        ((e = r.fetch).default = r.fetch),
        (e.fetch = r.fetch),
        (e.Headers = r.Headers),
        (e.Request = r.Request),
        (e.Response = r.Response),
        (t.exports = e);
    }
  ]);
});
